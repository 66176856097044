import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Manage.module.css';
import { IconButton, Dropzone } from 'components';
import { toDataUrl, missingValue, makeAssetURL } from 'utility';
import Edit from 'components/UserSelfManager/Edit';
import memoizeOne from 'memoize-one';

class Manage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropZone: {
        previewImage: null,
        previewText: null,
        allowedTypes: [
          'image/png',
          'image/jpeg',
          'image/gif',
          'video/mp4',
          'audio/mp3',
          'audio/wav'
        ],
        maxAllowedFileSize: 99999999999999
      },
      isEditVisible: false,
      isChangepassVisible: false
    };

    this.memoizeGetUserImage = memoizeOne((url) => {
      if (url !== null) {
        toDataUrl(url, (base64) => {
          this.updatePreviewImage(base64); // myBase64 is the base64 string
        });
      }
    });
  }
  updatePreviewImage = (base64) => {
    this.setState({
      dropZone: { ...this.state.dropZone, previewImage: base64 }
    });
  };

  render() {
    this.memoizeGetUserImage(makeAssetURL(this.props.userInfo.claims.picture));

    let shouldHide =
      !missingValue(this.props.userInfo.claims.picture) &&
      missingValue(this.state.dropZone.previewImage);

    return (
      <div className={styles.container}>
        {(this.state.isEditVisible || this.state.isChangepassVisible) && (
          <Edit
            changePass={this.state.isChangepassVisible}
            userInfo={this.props.userInfo.claims}
            onCancel={() =>
              this.setState({
                isEditVisible: false,
                isChangepassVisible: false
              })
            }
            onChangePassword={this.props.onChangePassword}
            onUpdateUser={this.props.onUpdateUser}
          />
        )}
        <form onSubmit={this.onSubmit}>
          {!shouldHide && (
            <Dropzone
              allowClickToZoom={true}
              styles={styles}
              previewImage={this.state.dropZone.previewImage}
            />
          )}
          <div className={styles.email}>{this.props.userInfo.claims.email}</div>
          <div className={styles.email}>
            <IconButton
              tooltip="Log Out"
              onClick={this.props.onLogout}
              isBorderless={true}
              icon="sign-out-alt"
            />
            <IconButton
              tooltip="Change Password"
              isBorderless={true}
              icon="key"
              onClick={() => {
                this.setState({
                  isEditVisible: false,
                  isChangepassVisible: true
                });
              }}
            />
            <IconButton
              tooltip="Edit Account Information"
              isBorderless={true}
              icon="edit"
              onClick={() => {
                this.setState({
                  isChangepassVisible: false,
                  isEditVisible: true
                });
              }}
            />
          </div>
        </form>
      </div>
    );
  }
}
export default Manage;

Manage.propTypes = {
  onChangePassword: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  userInfo: PropTypes.object,
  onLogout: PropTypes.func
};
