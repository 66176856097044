import React, { useState } from 'react';
import PropTypes from 'prop-types';
import defaultStyle from './defaultStyle.js';
import OptionButtons from './OptionButtons';
import { useEffect } from 'react';

const Text = (props) => {
  const [fieldValue, setFieldValue] = useState(
    !props.value || props.value === null ? '' : props.value
  );

  useEffect(() => {
    setFieldValue(props.value);
  }, [props.value]);

  let placeholder = '';
  if (props.options?.validation) {
    placeholder = `${props.options.validation.min} ${
      props.options.validation.max ? ` - ${props.options.validation.max}` : ''
    }`;
  }
  const validate = (val) => {
    let newVal = val;
    switch (props.options.validation.type) {
      case 'integer': {
        if (val && val !== '-') {
          newVal = parseInt(val, 10);
          newVal = isNaN(newVal) ? 0 : newVal;

          if (typeof props.options.validation.min !== 'undefined')
            newVal =
              parseInt(newVal) <= props.options.validation.min
                ? fieldValue === '-'
                  ? props.options.validation.min
                  : fieldValue
                : newVal;

          if (typeof props.options.validation.max !== 'undefined')
            newVal =
              parseInt(newVal) >= props.options.validation.max
                ? fieldValue
                : newVal;
        }
        setFieldValue(newVal);
        break;
      }
      case 'float': {
        let endsInPeriod = val.charAt(val.length - 1) === '.';
        let newVal = val;
        if (val && val !== '-') {
          newVal = parseFloat(val);
          newVal = isNaN(newVal) ? 0.0 : `${newVal}${endsInPeriod ? '.' : ''}`;
        }
        setFieldValue(newVal);
        break;
      }
      default:
        break;
    }
    return isNaN(newVal) ? 0.0 : newVal;
  };
  const onChange = (e) => {
    if (!props.options?.validation) setFieldValue(e.target.value);
    props.onChange({
      id: e.target.dataset.id,
      value: props.options?.validation
        ? validate(e.target.value)
        : e.target.value,
      data: props.options?.data
    });
  };
  const styles = {
    ...defaultStyle,
    input: { ...defaultStyle.input, ...props.style?.input },
    label: { ...defaultStyle.label, ...props.style?.label }
  };
  let inputProps = {
    placeholder,
    style: styles.input,
    type: 'text',
    autoComplete: 'off',
    disabled: props.isDisabled || props.isLocked,
    'data-id': props.id,
    value: fieldValue,
    onChange
  };
  if (props.options?.data)
    Object.keys(props.options.data).forEach((item) => {
      inputProps[`data-${item.toLowerCase()}`] = props.options.data[item];
    });
  inputProps.value = inputProps.value ? inputProps.value : '';
  return (
    <label style={styles.label}>
      <div
        title={props.tooltip}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div style={{ flexGrow: 1 }}>
          {props.label === null ? '' : props.label}
        </div>
        <div>
          <OptionButtons {...props} />
        </div>
      </div>
      <div style={props.isPublished ? null : styles.private}>
        <input {...inputProps} />
      </div>
    </label>
  );
};

Text.defaultProps = {
  isPublished: true,
  onChange: () => {}
};

Text.propTypes = {
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isPublished: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.shape({
    input: PropTypes.any,
    label: PropTypes.any
  }),
  tooltip: PropTypes.any,
  value: PropTypes.any
};

export default Text;
