import React from 'react';
import PropTypes from 'prop-types';
import defaultStyle from './defaultStyle.js';

const Select = (props) => {
  const isDisabled = props.isDisabled || props.isLocked;
  const styles = {
    ...defaultStyle,
    input: { ...defaultStyle.input, ...props.style?.input },
    label: { ...defaultStyle.label, ...props.style?.label }
  };
  if (props.options?.selectionOptions?.length === 0) {
    return <span>-</span>;
  } else {
    return (
      <div title={props.tooltip}>
        <div style={styles.label}>
          {props.label === null ? '' : props.label}
        </div>
        <div style={props.isPublished ? null : styles.private}>
          <select
            style={
              isDisabled ? styles.selectInput_disabled : styles.selectInput
            }
            value={props.value === null ? '' : props.value}
            onChange={(e) => {
              let item = props.options?.selectionOptions.find(
                (item) => item.id === parseInt(e.target.value, 10)
              );

              const value =
                e.target.value !== ''
                  ? parseInt(e.target.value, 10).toString() === e.target.value
                    ? parseInt(e.target.value, 10)
                    : e.target.value
                  : null;
              props.onChange({
                id: props.id,
                value,
                data: {
                  ...props.options?.data,
                  item
                }
              });
            }}
            autoComplete={props.id}
            disabled={isDisabled}
            data-id={props.id}
          >
            {props.options?.selectionOptions?.map((item, idx) => {
              return (
                <option
                  key={`select_${props.id}_${idx}`}
                  value={item.id === null ? '' : item.id}
                >
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  }
};

Select.defaultProps = {
  isPublished: true,
  onChange: () => {}
};

Select.propTypes = {
  label: PropTypes.string,
  id: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.object,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool
};

export default Select;
