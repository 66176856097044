import PropTypes from 'prop-types';
import React from 'react';
import { Button, IconButton } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'state/actions';
import CONST from 'constants.js';

const Layer = ({
  map,
  layer,
  isVisible,
  onRemove,
  forceUpdate,
  onEdit,
  onFitToIdeal
}) => {
  const dispatch = useDispatch();
  const mapId = useSelector((redux) => redux.id);
  const uiMode = useSelector((redux) => redux.app.uiMode);
  const onPromote = () => {
    if (
      window.confirm(
        'Do you want to promote this to the ideal path? This will replace any existing path.'
      )
    ) {
      dispatch(
        actions.updateAudiotourConfig({
          mapId,
          e: { id: 'ideal_path', value: layer.data }
        })
      );
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', width: '28rem' }}>
      <div>
        <IconButton
          icon={isVisible ? 'eye' : 'eye-slash'}
          onClick={() => {
            map.setLayoutProperty(
              layer.id,
              'visibility',
              isVisible ? 'none' : 'visible'
            );
            forceUpdate();
          }}
        />
      </div>
      <div
        style={{
          width: '10rem',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          fontSize: '1rem',
          textAlign: 'left'
        }}
      >
        {layer.displayName}
      </div>
      <div style={{ flexGrow: 1, textAlign: 'right' }}>
        {layer.isRemovable && <IconButton icon="trash" onClick={onRemove} />}
        {layer.isRemovable && <IconButton icon="road" onClick={onFitToIdeal} />}
        {layer.isRemovable && <Button onClick={onPromote}>Promote</Button>}
        {uiMode !== CONST.UI_MODE.MAP_DRAWING && (
          <IconButton icon="pen" onClick={onEdit} />
        )}
      </div>
    </div>
  );
};

Layer.propTypes = {
  map: PropTypes.object,
  data: PropTypes.any,
  displayName: PropTypes.any,
  forceUpdate: PropTypes.func,
  id: PropTypes.any,
  isVisible: PropTypes.any,
  layer: PropTypes.object,
  onEdit: PropTypes.func,
  onRemove: PropTypes.func,
  onFitToIdeal: PropTypes.func
};

export default React.memo(Layer);
