import React from 'react';
import { Route, Router } from 'react-router-dom';
import App from 'components/App';
import history from './history.js';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch'; // or any other pipeline
import store from 'store';

export const makeMainRoutes = () => {
  return (
    <Provider store={store}>
      <DndProvider options={HTML5toTouch}>
        <Router history={history}>
          <div>
            <Route path="/" render={(props) => <App {...props} />} />
          </div>
        </Router>
      </DndProvider>
    </Provider>
  );
};
