import initialState from './init/server';
import * as actions from 'state/actions';

export default function api(state = initialState, action) {
  switch (action.type) {
    case actions.SERVER_SET_INPROGRESS:
      return {
        ...state,
        inProgress: action.payload,
        progressStartedAt:
          action.payload.beganAt !== null ? action.payload.beganAt : Date.now()
      };
    case actions.SERVER_GET_MANIFEST_RESPONSE:
      return {
        ...state,
        isInitialized: true,
        manifest: {
          ...action.payload.json,
          packageurl: action.payload.packageurl,
          status: action.payload.response.status
        }
      };

    case actions.SERVER_STATUS_RESPONSE:
      if (action.payload.response.ok) {
        return {
          ...state,
          currentStatus: action.payload.response_json
        };
      } else {
        return { ...state };
      }

    default:
      return state;
  }
}
