import React from 'react';
import PropTypes from 'prop-types';

const Hidden = (props) => {
  return (
    <input
      type={'hidden'}
      autoComplete="off"
      disabled={props.isDisabled || props.isLocked}
      data-id={props.id}
      value={props.value === null ? '' : props.value}
      onChange={props.onChange}
    />
  );
};

Hidden.defaultProps = {
  onChange: () => {}
};

Hidden.propTypes = {
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  id: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.object
};

export default Hidden;
