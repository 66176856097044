import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ContextMenu.module.css';
import WatchForOutsideClick from '../WatchForOutsideClick';

class ContextMenu extends Component {
  render() {
    return (
      <WatchForOutsideClick onOutsideClick={this.props.onClose}>
        <div
          style={{ top: this.props.top - 2, left: this.props.left - 2 }}
          className={styles.contextMenu}
        >
          <div className={['contextMenu_poi'].join(' ')}>
            {this.props.menu.map((item, idx) => {
              return (
                <div
                  key={`context_${item.label}_${idx}`}
                  onClick={() => {
                    if (!item.disabled) {
                      this.props.onClose();
                      item.action(this.props.id);
                    }
                  }}
                  className={
                    item.disabled
                      ? styles.contextMenu_item_disabled
                      : styles.contextMenu_item
                  }
                >
                  {item.label}
                </div>
              );
            })}
          </div>
        </div>
      </WatchForOutsideClick>
    );
  }
}
export default ContextMenu;

ContextMenu.defaultProps = {
  top: 0,
  left: 0,
  disabled: false
};

ContextMenu.propTypes = {
  id: PropTypes.number,
  onClose: PropTypes.func,
  top: PropTypes.number,
  left: PropTypes.number,
  menu: PropTypes.array.isRequired,
  isLocked: PropTypes.bool
};
