import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import defaultStyle from './defaultStyle.js';
import OptionButtons from './OptionButtons';
import Markdown from 'react-universal-markdown/dom';

const isJSON = (str) => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

const Textarea = (props) => {
  const styles = { ...defaultStyle, ...props.style };
  const isDisabled = props.isDisabled || props.isLocked;
  const content = props.value === null ? '' : props.value;
  let hasValidation = props.options?.validation && props;
  const [isValid, setIsValid] = useState(hasValidation ? true : null);
  const [fieldValue, setFieldValue] = useState(props.value);
  const onChange = (e) => {
    let value = e.target.value;
    setFieldValue(value);
    setIsValid(!hasValidation);
    if (hasValidation) {
      if (!isJSON(value)) {
        return null;
      } else {
        setIsValid(true);
        value = JSON.parse(value);
      }
    }
    props.onChange({
      id: e.target.dataset.id,
      value,
      data: props.options?.data
    });
  };

  useEffect(() => {
    setFieldValue(props.value);
  }, [props.value]);

  const onBlur = () => {
    if (hasValidation && !isValid) setFieldValue(props.value);
  };

  const icon = isValid ? (
    <div className="fas fa-check" style={{ color: '#bada55' }} />
  ) : (
    <div className="fas fa-times" style={{ color: '#ff0000' }} />
  );
  return (
    <label style={styles.label}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div style={{ flexGrow: 1 }}>
          {props.label === null ? '' : props.label}
        </div>
        <div>
          <OptionButtons {...props} supportsMarkdown={true} />
        </div>
      </div>
      <div style={props.isPublished ? null : styles.private}>
        {(isDisabled && (
          <div style={styles.textarea_disabled}>
            {(props.isMarkdown && <Markdown>{content}</Markdown>) || content}
          </div>
        )) || (
          <div>
            <textarea
              style={styles.textarea}
              type={'textarea'}
              autoComplete="off"
              disabled={isDisabled}
              data-id={props.id}
              value={fieldValue}
              onBlur={onBlur}
              onChange={onChange}
            />
            <div style={{ textAlign: 'center' }}>
              {isValid !== null && <div style={{ width: '1rem' }}>{icon}</div>}
            </div>
          </div>
        )}
      </div>
    </label>
  );
};

Textarea.defaultProps = {
  onChange: () => {},
  isPublished: true
};

Textarea.propTypes = {
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isMarkdown: PropTypes.bool,
  isPublished: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.any,
  value: PropTypes.any
};

export default Textarea;
