import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeAssetURL } from 'utility';
import { Dropzone } from 'components';
import FormInput, { FIELDTYPE } from 'components/FormInput';
import MediaPlayer from 'components/MediaPlayer';

const MediaPreview = (props) => {
  if (!props.media.type) return null;
  const [validPreviewImage, setValidPreviewImage] = useState(false);
  const hasPreview =
    props.media.type.includes('video') ||
    props.media.type.includes('audio') ||
    props.media.type.includes('custom');
  return (
    <div style={styles.container}>
      <div style={styles.editor}>
        <FormInput
          metaData={props.media}
          id="name"
          styles={styles}
          label="Name"
          isPublished={true}
          isDisabled={props.isDisabled}
          type={FIELDTYPE.TEXT}
          value={props.media.name}
          onChange={props.onChange}
        />
        {props.media.type.includes('custom') && (
          <React.Fragment>
            <FormInput
              metaData={props.media}
              id="url"
              styles={styles}
              label="URL"
              isPublished={true}
              isDisabled={props.isDisabled}
              type={FIELDTYPE.TEXT}
              value={props.media.url}
              onChange={props.onChange}
            />
            <FormInput
              metaData={props.media}
              id="is360"
              styles={styles}
              label={'360?'}
              isPublished={true}
              isDisabled={props.isDisabled}
              type={FIELDTYPE.CHECKBOX}
              value={props.media.is360}
              onChange={props.onChange}
            />
          </React.Fragment>
        )}
      </div>
      <div style={styles.player}>
        <MediaPlayer media={props.media} />
      </div>
      {hasPreview && (
        <div style={styles.preview}>
          <Dropzone
            isDisabled={props.isDisabled}
            id={props.media.id}
            allowClickToZoom={true}
            styles={styles}
            previewImage={
              validPreviewImage ? makeAssetURL(props.media.preview) : null
            }
            onRemovePreview={props.onRemovePreview}
            onDrop={props.onFileDrop}
          />
          <img
            style={{ width: 0, height: 0, position: 'fixed' }}
            src={makeAssetURL(props.media.preview)}
            onLoad={() => setValidPreviewImage(true)}
            onError={() => setValidPreviewImage(false)}
          />
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: '5rem',
    height: '100%',
    lineHeight: '1rem'
  },
  editor: {
    flexGrow: 1,
    textAlign: 'left',
    minWidth: '10rem',
    maxWidth: '10rem',
    padding: '.5rem',
    marginRight: '1rem',
    overflow: 'hidden'
  },
  player: {
    flexGrow: 1,
    overflow: 'hidden',
    textAlign: 'center',
    minWidth: '10rem',
    padding: '.2rem',
    maxHeight: '11rem'
  },
  preview: {
    flexGrow: 1,
    textAlign: 'center',
    minWidth: '11rem',
    maxWidth: '11rem'
  }
};

MediaPreview.defaultProps = {
  allowedTypes: [
    'audio/wav',
    'audio/mp3',
    'audio/x-m4a',
    'image/png',
    'image/jpeg',
    'image/gif',
    'video/mp4'
  ],
  previewTypes: ['image/png', 'image/jpeg', 'image/gif']
};

MediaPreview.propTypes = {
  isDisabled: PropTypes.bool,
  onRemovePreview: PropTypes.func,
  media: PropTypes.object.isRequired,
  items: PropTypes.array,
  onChange: PropTypes.func,
  onFileDrop: PropTypes.func
};

export default MediaPreview;
