import React from 'react';
import PropTypes from 'prop-types';
import sun from 'utility/sun';
import moment from 'moment';
const TOD = sun.PERCEIVED_TIME_OF_DAY;

const TimeRow = ({ id, info }) => {
  const section = info.perceived[id];
  const display = displaySettings[id];
  return (
    <div
      style={{
        ...styles.section,
        ...display.style,
        height: `${section.duration}rem`
      }}
    >
      <div style={{ width: '10rem' }}>{display.name}</div>
      <div>
        {section.begins.format('hh:mm A')}-{section.ends.format('hh:mm A')}
      </div>
      <div
        style={{
          textAlign: 'right',
          flexGrow: '1'
        }}
      >
        {section.duration.toFixed(1)} hours
      </div>
    </div>
  );
};

TimeRow.propTypes = {
  id: PropTypes.any,
  info: PropTypes.object
};

const TimeOfDay = ({ date, coordinate }) => {
  if (!date || !coordinate) return <div>NO</div>;
  let info = sun.info({ date, coordinate });
  if (!info) return null;
  return (
    <div
      style={{
        width: '30rem'
      }}
    >
      <div>
        {info.hoursOfDaylight} hours of daylight{' '}
        {moment(date).isSame(moment(), 'day')
          ? 'today'
          : moment(date).fromNow()}{' '}
        ({info.timezone})
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <TimeRow id={TOD.EARLY_MORNING} info={info} />
        <div style={styles.divider}>
          <div style={{ width: '10rem' }}>Sunrise</div>
          <div> {info.sunrise.format('hh:mm A')}</div>
        </div>
        <TimeRow id={TOD.MORNING} info={info} />
        <TimeRow id={TOD.DAY} info={info} />
        <TimeRow id={TOD.EVENING} info={info} />
        <div style={styles.divider}>
          <div style={{ width: '10rem' }}>Sunset</div>
          <div> {info.sunset.format('hh:mm A')}</div>
        </div>
        <TimeRow id={TOD.NIGHT} info={info} />
      </div>
    </div>
  );
};

const styles = {
  section: {
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  divider: {
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    background: 'grey'
  }
};

const displaySettings = {
  [TOD.EARLY_MORNING]: {
    style: {
      background: 'linear-gradient(#444469, #9198e5)',
      color: 'white'
    },
    name: 'Early Morning'
  },
  [TOD.MORNING]: {
    style: {
      background: 'linear-gradient(#9198e5, #f5ffa6)',
      color: 'black'
    },
    name: 'Morning'
  },
  [TOD.DAY]: {
    style: { backgroundColor: '#f5ffa6', color: 'black' },
    name: 'Day'
  },
  [TOD.EVENING]: {
    style: {
      background: 'linear-gradient(#f5ffa6,#9198e5)',
      color: 'black'
    },
    name: 'Evening'
  },
  [TOD.NIGHT]: {
    style: {
      background: 'linear-gradient(#9198e5,#444469)',
      color: 'white'
    },
    name: 'Night'
  }
};

TimeOfDay.defaultProps = {
  onChange: () => {}
};

TimeOfDay.propTypes = {
  coordinate: PropTypes.any,
  date: PropTypes.any,
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.object,
  value: PropTypes.any
};

export default TimeOfDay;
