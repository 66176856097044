import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './ErrorPage.module.css';
import { onReload } from 'utility';

export default class ErrorPage extends Component {
  render() {
    if (this.props.error) console.error(this.props.error);
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>{this.props.title}</div>
          <div className={styles.message}>{this.props.line1}</div>
          <div className={styles.message}>{this.props.line2}</div>
          <div>
            <span className={`${styles.icon} fas fa-dragon`} />
          </div>
          <div className={styles.errorText}>{this.props.children}</div>
          {this.props.includeReportLink && (
            <div className={styles.message}>
              Usually this is temporary and{' '}
              <span className={styles.link} onClick={onReload}>
                reloading
              </span>{' '}
              will fix it.
            </div>
          )}
          {this.props.includeReportLink && (
            <div className={styles.message}>
              An automatic error report has already been filed, but if you see
              this message again please{' '}
              <span className={styles.link} onClick={this.bugReport}>
                open a ticket.
              </span>{' '}
            </div>
          )}
        </div>
      </div>
    );
  }
  bugReport() {
    window.location.href = window._env_.REACT_APP_BUG_REPORT_URL;
  }
}

ErrorPage.defaultProps = {
  includeReportLink: true,
  includeRestartLink: true,
  title: 'HC SVNT BASILISKSES',
  line1: 'An error has occurred.',
  line2: 'More information may be available on the console.',
  children: null
};

ErrorPage.propTypes = {
  error: PropTypes.object,
  includeReportLink: PropTypes.bool,
  includeRestartLink: PropTypes.bool,
  title: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  children: PropTypes.node
};
