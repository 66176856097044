import React, { Component } from 'react';
import styles from './Login.module.css';
import Button from '../../Button';
import PropTypes from 'prop-types';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { username: '', password: '', attemptMade: false };
  }

  render() {
    return (
      <div className={styles.container}>
        <form onSubmit={this.onSubmit}>
          <input type="submit" style={{ display: 'none' }} />
          <div>
            <input
              autoComplete="current-username"
              type="text"
              onChange={this.onChange}
              name="username"
            />
          </div>
          <div>
            <input
              autoComplete="current-password"
              type="password"
              onChange={this.onChange}
              name="password"
            />
          </div>
          <div style={{ marginTop: '.5rem' }}>
            <Button id="confirm" onClick={this.onSubmit}>
              Login
            </Button>
            {this.state.attemptMade && this.state.username.length > 0 && (
              <Button
                id="forgot"
                onClick={() => this.props.onForgotPassword(this.state.username)}
              >
                Forgot Password
              </Button>
            )}
          </div>
        </form>
      </div>
    );
  }
  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ attemptMade: true, username: this.state.username });
    if (this.state.username.length > 0 && this.state.password.length > 0) {
      this.props.onLogin(this.state.username, this.state.password);
    }
  };
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
}
export default Login;

Login.propTypes = {
  onForgotPassword: PropTypes.func,
  onLogin: PropTypes.func.isRequired
};
