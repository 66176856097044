import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './MapView.module.css';
import { ContextMenu, Tooltip } from 'components';
import memoizeOne from 'memoize-one';
import mapHelper from './mapHelper.js';
import { connect } from 'react-redux';
import mapboxgl from 'mapbox-gl';
import MapboxglSpiderifier from 'mapboxgl-spiderifier';
import moment from 'moment';
import * as actions from 'state/actions';
import CONST from 'constants.js';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { filterPoiByTags } from 'utility';
export { mapHelper };

class MapView extends Component {
  constructor(props) {
    super(props);
    this.requestedImages = [];
    this.requestImage = (id, opt) => {
      this.requestedImages[id] = opt;
    };
    this.mapReadyForMarkers = false;
    this.draggableRendered = false;
    this.previousPoi = [];
    this.currentTileserverId = this.props.reduxState.app.prefs.map_tileserverId.value;
    this.currentMapId = this.props.reduxState.app.id;
    this.state = {
      trackLocation: false,
      tooltip: {},
      contextMenu: {
        onClose: () => {
          this.setState({
            contextMenu: { ...this.state.contextMenu, isVisible: false }
          });
        }
      }
    };
  }

  onTooltip = (tooltip) => {
    this.setState({
      tooltip: {
        ...this.state.tooltip,
        ...tooltip
      }
    });
  };

  onRightClickPoi = (e, id, rep_id) => {
    this.props.dispatch(actions.setCurrentPoi(id));
    const _event = e.originalEvent ? e.originalEvent : e;
    _event.stopPropagation();
    _event.preventDefault();
    let menu = [
      {
        label: `Inspect`,
        action: () => {
          this.props.dispatch(actions.setCurrentPoi(id));
          this.props.dispatch(actions.setAllVisibilityOff());
          this.props.dispatch(actions.uiShow(CONST.UI.POI_INSPECTOR));
        }
      },
      {
        label: 'Delete',
        action: () => {
          mapHelper.onDelete(id);
        }
      },
      {
        label: 'Use Style As Default',
        representation_id: 2,
        action: () => {
          mapHelper.onSetDefault();
        }
      },
      {
        label: 'Media Inspect',
        action: () => {
          this.props.dispatch(actions.setCurrentPoi(id));
          this.props.dispatch(actions.setAllVisibilityOff());
          this.props.dispatch(actions.uiShow(CONST.UI.MEDIA_INSPECTOR));
        }
      }
      /*
      {
        label: 'Get Link',
        action: id => {
          mapHelper.onLink(id);
        },
      },*/
    ].filter((menuItem) => {
      return (
        !menuItem.representation_id || menuItem.representation_id === rep_id
      );
    });
    this.setState({
      contextMenu: {
        ...this.state.contextMenu,
        id: id,
        isVisible: true,
        top: _event.clientY,
        left: _event.clientX,
        menu: menu
      },
      tooltip: {
        ...this.state.tooltip,
        isVisible: false
      }
    });
  };

  onMapDidLoad = (map) => {
    this.props.onMapDidLoad(map);
    this.mapReadyForMarkers = true;
    this.forceUpdate();
  };

  initMap = () => {
    if (this.mapObj) this.mapObj.remove();

    let tileServer = this.props.reduxState.app.available_tileservers.find(
      (server) =>
        server.id === this.props.reduxState.app.prefs.map_tileserverId.value
    );
    if (!tileServer?.url || !this.mapContainer) return;

    let appState = this.props.reduxState.app;
    mapboxgl.accessToken = window._env_.REACT_APP_MAPBOX_API_KEY;

    this.mapContainer.style.background = appState.prefs.map_bg_color.value
      ? appState.prefs.map_bg_color.value
      : '#FFFFFF';

    this.mapContainer.style.height = '100vh';

    let minZoom =
      parseInt(appState.prefs.map_zoom_min.value) < 0
        ? 0
        : parseInt(appState.prefs.map_zoom_min.value);

    // The -0.1 is a workaround for this weird bug: https://github.com/mapbox/mapbox-gl-js/issues/6454
    let maxZoom = parseFloat(appState.prefs.map_zoom_max.value) - 0.1;
    let bounds =
      appState.prefs.restrictToBounds.value &&
      appState.prefs.map_initial_bounds.value
        ? [
            [
              appState.prefs.map_initial_bounds.value._sw
                ? appState.prefs.map_initial_bounds.value._sw.lng
                : appState.prefs.map_initial_bounds.value._southWest.lng,
              appState.prefs.map_initial_bounds.value._sw
                ? appState.prefs.map_initial_bounds.value._sw.lat
                : appState.prefs.map_initial_bounds.value._southWest.lat
            ],
            [
              appState.prefs.map_initial_bounds.value._ne
                ? appState.prefs.map_initial_bounds.value._ne.lng
                : appState.prefs.map_initial_bounds.value._northEast.lng,
              appState.prefs.map_initial_bounds.value._ne
                ? appState.prefs.map_initial_bounds.value._ne.lat
                : appState.prefs.map_initial_bounds.value._northEast.lat
            ]
          ]
        : null;
    let center = [
      appState.prefs.map_initial_position.value.lng,
      appState.prefs.map_initial_position.value.lat
    ];
    if (this.mapObj) {
      try {
        this.mapObj.remove();
      } catch (e) {
        console.warn(e);
      }
    }

    let map = new mapboxgl.Map({
      container: this.mapContainer,
      style: {
        version: 8,
        sources: {
          'raster-tiles': {
            type: 'raster',
            tiles: [tileServer.url],
            tileSize: 256
          }
        },
        layers: [
          {
            id: 'simple-tiles',
            type: 'raster',
            source: 'raster-tiles'
          }
        ]
      },
      center,
      attributionControl: false,
      failIfMajorPerformanceCaveat: true,
      antialias: true,
      zoom: parseInt(appState.prefs.map_zoom_initial.value, 10),
      bearing: 0,
      pitch: 0,
      maxBounds: bounds,
      maxZoom,
      minZoom,
      renderWorldCopies: false
    });

    if (appState.prefs.hasMapControls.value) {
      map.addControl(
        new mapboxgl.NavigationControl({
          showZoom: true,
          showCompass: appState.prefs.allowRotation.value
        }),
        'bottom-right'
      );
    }

    if (!appState.prefs.allowRotation.value) {
      map.dragRotate.disable();
      map.touchZoomRotate.disableRotation();
    }

    map.on('load', () => this.onMapDidLoad(map));

    map.on('click', (e) => {
      if (this.props.disableClickableMarkers) return;
      e.originalEvent.stopPropagation();
      window.lastMapEvent = e;
      let features = map.queryRenderedFeatures(e.point);

      if (features.length === 0) {
        this.props.dispatch(actions.setCurrentPoi(null));
        this.props.dispatch(actions.setAllVisibilityOff());
      } else {
        this.props.dispatch(
          actions.setCurrentPoi(parseInt(features[0].properties.id))
        );
        this.props.dispatch(actions.setAllVisibilityOff());
        this.props.dispatch(actions.uiShow(CONST.UI.POI_PRESENTATION));
      }
    });

    map.on('contextmenu', (e) => {
      if (this.props.disableClickableMarkers) return;
      e.originalEvent.stopPropagation();
      window.lastMapEvent = e;
      let features = map.queryRenderedFeatures(e.point);
      if (features.length === 0) {
        // Map
        this.setState({
          contextMenu: {
            ...this.state.contextMenu,
            isVisible: true,
            top: e.originalEvent.clientY,
            left: e.originalEvent.clientX,
            menu: [
              {
                label: 'Create Pin',
                action: () => {
                  mapHelper.onCreate({
                    coordinate: window.lastMapEvent.lngLat,
                    representation_id: 1
                  });
                }
              },
              {
                label: 'Create Circle',
                action: () => {
                  mapHelper.onCreate({
                    coordinate: window.lastMapEvent.lngLat,
                    representation_id: 2
                  });
                }
              },
              {
                label: this.props.reduxState.app.isLocked
                  ? 'Unlock Map'
                  : 'Lock Map',
                action: mapHelper.onToggleLock
              }
            ]
          },
          tooltip: {
            ...this.state.tooltip,
            isVisible: false
          }
        });
      } else if (features.length !== 0) {
        // POI
        this.onRightClickPoi(
          e,
          parseInt(features[0].properties.id),
          features[0].properties.representation_id
        );
      }
    });

    map.on('styleimagemissing', (e) => {
      const missingImage = this.requestedImages[e.id];
      map.loadImage(missingImage?.iconUrl, (error, image) => {
        if (error) return console.error(error);
        if (!map.hasImage(e.id)) map.addImage(e.id, image);
      });
    });

    map.spiderifier = new MapboxglSpiderifier(map, {
      customPin: false,
      initializeLeg: (leg) => {
        leg.elements.pin.onclick = (e) => {
          e.stopPropagation();
          this.props.dispatch(
            actions.setCurrentPoi(parseInt(leg.feature.id, 10))
          );
          this.props.dispatch(actions.setAllVisibilityOff());
          this.props.dispatch(actions.uiShow(CONST.UI.POI_PRESENTATION));
        };
        leg.elements.pin.oncontextmenu = (e) => {
          this.onRightClickPoi(this, e, parseInt(leg.feature.id, 10));
        };
        leg.elements.pin.onmouseover = (e) => {
          let date = leg.feature.date
            ? moment(leg.feature.date).isValid()
              ? `${moment(leg.feature.date).format('MM/DD/YYYY')}`
              : `${leg.feature.date}`
            : '';
          this.setState({
            tooltip: {
              ...this.state.tooltip,
              isVisible: true,
              contentTitle: date,
              content: leg.feature.name,
              top: e.clientY,
              left: e.clientX
            }
          });
        };
        leg.elements.pin.onmouseout = () => {
          this.setState({
            tooltip: {
              ...this.state.tooltip,
              isVisible: false
            }
          });
        };
      }
    });
    map.spiderifier.spidered = false;
    this.mapObj = map;
  };

  componentDidUpdate = () => {
    if (
      this.currentMapId !== this.props.reduxState.app.id ||
      this.currentTileserverId !==
        this.props.reduxState.app.prefs.map_tileserverId.value
    ) {
      this.currentTileserverId = this.props.reduxState.app.prefs.map_tileserverId.value;
      this.currentMapId = this.props.reduxState.app.id;
      this.initMap();
    }
  };

  render = () => {
    this.m_userLocationChange(
      this.props.reduxState.app.userLocation.currentLocation
    );
    if (this.mapReadyForMarkers)
      this.m_renderMarkers(
        this.props.reduxState.search.options.applyToMap
          ? this.props.reduxState.search.results
          : this.props.reduxState.poi.filtered?.length > 0
          ? this.props.reduxState.poi.filtered
          : this.props.reduxState.poi.all,
        this.props.reduxState.app.isLocked,
        this.requestImage
      );

    let tileServer = this.props.reduxState.app.available_tileservers.find(
      (server) =>
        server.id === this.props.reduxState.app.prefs.map_tileserverId.value
    );

    return (
      <React.Fragment>
        {this.props.reduxState.app.prefs.hasUserLocate.value && (
          <div
            onClick={this.onEnableLocation}
            style={{
              top: this.props.reduxState.ui.timeFilter.isVisible
                ? '8.5rem'
                : '3.5rem'
            }}
            className={
              this.props.reduxState.app.userLocation.watchID === null
                ? styles.user_control
                : this.state.trackLocation
                ? styles.user_control_enabled_active
                : styles.user_control_enabled
            }
          >
            <div className="fas fa-map-marker-alt" />
          </div>
        )}
        {this.props.reduxState.user.isAuthenticated &&
          this.state.contextMenu.isVisible && (
            <ContextMenu
              id={this.state.contextMenu.id}
              top={this.state.contextMenu.top}
              left={this.state.contextMenu.left}
              isLocked={this.props.reduxState.app.isLocked}
              menu={this.state.contextMenu.menu}
              onClose={this.state.contextMenu.onClose}
            />
          )}
        {this.state.tooltip.isVisible && (
          <Tooltip
            top={this.state.tooltip.top}
            left={this.state.tooltip.left}
            contentTitle={this.state.tooltip.contentTitle}
            content={this.state.tooltip.content}
          />
        )}
        <div
          className={styles.mapContainer}
          ref={(el) => (this.mapContainer = el)}
        >
          <div
            className={styles.attribution}
            dangerouslySetInnerHTML={{ __html: tileServer?.attribution }}
          />
        </div>
      </React.Fragment>
    );
  };

  m_renderMarkers = memoizeOne((poi, isLocked) => {
    if (!poi) return;

    poi = filterPoiByTags(poi, this.props.reduxState.app.queryParams.tags);

    if (this.props.reduxState.app.queryParams.poi) {
      setTimeout(() => {
        let poi = this.props.reduxState.poi.all.find(
          (poi) =>
            poi.id === parseInt(this.props.reduxState.app.queryParams.poi, 10)
        );
        mapHelper.centerOn(poi?.coordinate);
      }, 250);
    }

    let poiByType = [];
    this.props.reduxState.app.representation_options.forEach((option) => {
      poiByType.push({ ...option });
    });
    poi.forEach((thisPoi) => {
      let thisType = poiByType.find(
        (type) => type.id === parseInt(thisPoi.representation_id, 10)
      );
      if (!thisType.poi) thisType.poi = [];
      thisType.poi.push(thisPoi);
    });
    if (!this.mapObj) return;
    if (isLocked) {
      this.draggableRendered = false;
      mapHelper.renderMarkers(
        this.mapObj,
        poiByType,
        this.requestImage,
        this.onTooltip
      );
    } else {
      if (!this.draggableRendered) {
        mapHelper.renderDraggableMarkers(this.mapObj, poiByType);
        this.draggableRendered = true;
      }
    }
  });

  m_userLocationChange = memoizeOne((location) => {
    if (this.mapObj && location) {
      mapHelper.renderUserLocation(this.mapObj, location, styles);
      if (this.state.trackLocation) {
        this.mapObj.panTo({
          lat: location.coords.latitude,
          lng: location.coords.longitude
        });
      }
    }
  });

  componentDidMount() {
    this.initMap();
  }

  componentWillUnmount() {
    if (this.mapObj) {
      this.mapObj.remove();
    }
  }

  onEnableLocation = () => {
    if (this.props.reduxState.app.userLocation.watchID === null) {
      this.props.dispatch(actions.enableLocation());
    } else {
      const trackLocation = !this.state.trackLocation;
      this.setState({ trackLocation });
      if (
        trackLocation &&
        this.props.reduxState.app.userLocation.currentLocation
      ) {
        this.mapObj.panTo({
          lat: this.props.reduxState.app.userLocation.currentLocation.coords
            .latitude,
          lng: this.props.reduxState.app.userLocation.currentLocation.coords
            .longitude
        });
      }
    }
  };
}

MapView.defaultProps = {
  isPlaying: false,
  showAttribution: true
};

MapView.propTypes = {
  dispatch: PropTypes.func,
  isPlaying: PropTypes.bool,
  showAttribution: PropTypes.bool,
  onMapDidLoad: PropTypes.func,
  reduxState: PropTypes.object.isRequired,
  disableClickableMarkers: PropTypes.bool
};

export default connect((reduxState) => {
  document.title = reduxState.app.prefs.title.value;
  return {
    reduxState
  };
})(MapView);
