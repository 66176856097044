import React from 'react';
import PropTypes from 'prop-types';

//import colors from 'colors';
//import CONST from 'utility/constants';

const Range = () => {
  return <div />;
};
Range.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  id: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.object
};

Range.defaultProps = {
  onChange: () => {}
};

export default Range;
