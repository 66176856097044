import initialState from './init/packager';
import * as actions from 'state/actions';

export default function app(state = initialState, action) {
  switch (action.type) {
    case actions.PKG_GET_MANIFEST_RESPONSE:
      return { ...state, manifest: action.payload.response.manifest };
    case actions.PKG_GET_STATUS_RESPONSE:
      return { ...state, status: action.payload.response.status };
    default:
      return state;
  }
}
