import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './SearchResults.module.css';
import IconButton from '../../IconButton';
import moment from 'moment';

class SearchResults extends Component {
  componentDidMount = () => {
    window.addEventListener('resize', this.onResize);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.onResize);
  };

  onResize = () => {
    if (!this.props.isPaused) this.props.onPause();
  };

  componentDidUpdate = () => {
    if (this.props.isPlaying) {
      let height = document.getElementById(`sequence_${this.props.playPointer}`)
        .offsetHeight;

      document.getElementById('searchResultsContainer').scrollTop =
        document.getElementById(`sequence_${this.props.playPointer}`)
          .offsetTop - height;

      if (this.props.playPointer > 0) {
        document.getElementById('rippleEffect').classList.add('ripple_active');
        setTimeout(() => {
          const el = document.getElementById('rippleEffect');
          if (el) el.classList.remove('ripple_active');
        }, 250);
      }
    }
  };

  render() {
    this.truncateAt = 40;
    return (
      <React.Fragment>
        {this.props.isPlaying && (
          <div id="rippleEffect" className={styles.ripple} />
        )}
        <table className={styles.table}>
          <tbody>
            <tr className={styles.tableRowHead}>
              <td
                className={styles.tableRowCell}
                style={{ textAlign: 'left', padding: '0 0 0 0.5rem' }}
              >
                <div
                  onClick={this.props.onSort}
                  data-id="sortOn"
                  data-sorton="displayName"
                >
                  Name{' '}
                  {this.props.search.options.sortOn === 'displayName' && (
                    <span
                      className={
                        this.props.search.options.isReversed
                          ? 'fas fa-caret-up'
                          : 'fas fa-caret-down'
                      }
                    />
                  )}
                </div>
              </td>
              <td className={styles.tableRowCell}>
                <div
                  onClick={this.props.onSort}
                  data-id="sortOn"
                  data-sorton="date"
                >
                  Date
                  {this.props.search.options.sortOn === 'date' && (
                    <span
                      className={
                        this.props.search.options.isReversed
                          ? 'fas fa-caret-up'
                          : 'fas fa-caret-down'
                      }
                    />
                  )}
                </div>
              </td>
              {this.props.additionalFields?.map((field, idx) => {
                return (
                  <td
                    className={styles.tableRowCell}
                    key={`${field.name}_${idx}`}
                  >
                    <div
                      onClick={this.props.onSort}
                      data-id="sortOn"
                      data-sorton={field.search_scope}
                    >
                      {field.name}
                      {this.props.search.options.sortOn ===
                        field.search_scope && (
                        <span
                          className={
                            this.props.search.options.isReversed
                              ? 'fas fa-caret-up'
                              : 'fas fa-caret-down'
                          }
                        />
                      )}
                    </div>
                  </td>
                );
              })}

              <td className={styles.tableRowCell} />
            </tr>

            {this.props.results.length > 0 &&
              this.props.results?.map((result, idx) => {
                let currentPlaying =
                  this.props.isPlaying && this.props.playPointer === idx;
                return (
                  <tr
                    id={`sequence_${idx}`}
                    onClick={() => this.props.onEdit(result.id, idx + 1)}
                    className={`${styles.tableRow} ${styles.hoverable} ${
                      currentPlaying ? styles.selected : ''
                    }`}
                    key={`name_${idx}`}
                  >
                    <td
                      className={`${styles.tableRowCell}`}
                      style={{ textAlign: 'left' }}
                    >
                      <div
                        data-poi_id={result.id}
                        className={styles.tableRowCell}
                        title={
                          result.matchedOn
                            ? `Matched on: ${result.matchedOn} | ${result.displayName}`
                            : result.displayName
                        }
                        style={{
                          margin: '0 0 0 .4rem'
                        }}
                      >
                        {(currentPlaying && (
                          <div className={styles.playbackBlock}>
                            <div className={styles.playbackBlockHeader}>
                              {idx}/{this.props.results.length}
                            </div>
                            <div>
                              {' '}
                              {result.displayName.length > this.truncateAt
                                ? `${result.displayName.substring(
                                    0,
                                    this.truncateAt
                                  )}…`
                                : result.displayName}
                            </div>
                          </div>
                        )) || (
                          <div>
                            {' '}
                            {result.displayName.length > this.truncateAt
                              ? `${result.displayName.substring(
                                  0,
                                  this.truncateAt
                                )}…`
                              : result.displayName}
                          </div>
                        )}
                      </div>
                    </td>

                    <td
                      className={styles.tableRowCell}
                      key={`date_${idx}`}
                      data-poi_id={result.id}
                      onClick={this.props.onNavigateTo}
                    >
                      {moment(result.date).isValid() &&
                        moment(result.date).format('MM/DD/YYYY')}
                    </td>
                    {this.props.additionalFields?.map((field) => {
                      const data = result.data.find(
                        (datum) => datum.search_scope === field.search_scope
                      );
                      return (
                        <td key={`${field.name}_${idx}`}>{data?.value}</td>
                      );
                    })}
                    {!this.props.isPlaying && (
                      <td
                        className={styles.tableRowCell_buttons}
                        data-poi_id={result.id}
                      >
                        <IconButton
                          onClick={this.props.onNavigateTo}
                          dataset={{
                            'data-id': result.id
                          }}
                          icon="map-marker-alt"
                        />
                        <IconButton
                          onClick={() => this.props.onDelete(result.id)}
                          dataset={{
                            'data-pointer': idx + 1,
                            'data-id': result.id
                          }}
                          icon="trash"
                        />
                      </td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>{' '}
      </React.Fragment>
    );
  }
}

export default SearchResults;
SearchResults.defaultProps = {
  isPlaying: false,
  playPointer: 0
};

SearchResults.propTypes = {
  sortOn: PropTypes.string,
  sortReversed: PropTypes.bool,
  search: PropTypes.object,
  isPaused: PropTypes.bool,
  isPlaying: PropTypes.bool,
  playPointer: PropTypes.number,
  results: PropTypes.array.isRequired,
  additionalFields: PropTypes.array,
  onNavigateTo: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onPause: PropTypes.func.isRequired
};
