import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './GeoSearch.module.css';
import Geocode from 'react-geocode';

class GeoSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      search_input: ''
    };
  }
  render() {
    return (
      <div className={styles.container}>
        <form onSubmit={this.handleSubmit}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center'
            }}
          >
            <div
              onClick={this.toggleSearchBox}
              className={['fas', 'fa-map', styles.iconButton].join(' ')}
            />
            <input
              className={styles.searchInput}
              value={this.state.search_input}
              name="searchInput"
              type="text"
              onChange={this.onChange}
            />
            <input className={styles.hidden} type="submit" />
          </div>
        </form>
      </div>
    );
  }

  onChange = (event) => {
    this.setState({ search_input: event.target.value });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);
    const address = data.get('searchInput');

    Geocode.setApiKey(window._env_.REACT_APP_GOOGLE_API_KEY);
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        this.props.onGeoSearch({
          location: address,
          coordinates: [lat, lng],
          response: response
        });
      },
      (error) => {
        this.props.onGeoSearch({
          location: address,
          error: error
        });
        console.error(error);
      }
    );
  };
}
export default GeoSearch;

GeoSearch.propTypes = {
  onGeoSearch: PropTypes.func
};
