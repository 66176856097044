import initialState from './init/proximity';
import * as actions from 'state/actions';
import common from './common.js';

export default function proximity(state = initialState, action) {
  switch (action.type) {
    case actions.PROXIMITY_GET_RESPONSE: {
      const newProximity = action.payload;
      let nextID = 0;
      newProximity.forEach((proximity) => {
        if (proximity.id > nextID) nextID = proximity.id;
      });
      nextID = nextID + 9000;
      return {
        ...state,
        nextID,
        all: newProximity
      };
    }

    case actions.PROXIMITY_CREATE_ENQUEUE: {
      const needsInsert = [...state.needsInsert];
      needsInsert.push(action.payload);
      let nextID = state.nextID + 1;
      return {
        ...state,
        all: common.insertIntoArray(state.all, action.payload),
        needsInsert,
        nextID
      };
    }

    case actions.PROXIMITY_UPDATE_ENQUEUE: {
      const needsUpdate = [...state.needsUpdate];
      needsUpdate.push(action.payload);
      return {
        ...state,
        all: common.updateInArrayById(state.all, action.payload),
        filtered: common.updateInArrayById(state.filtered, action.payload),
        needsUpdate
      };
    }

    case actions.PROXIMITY_DELETE_ENQUEUE: {
      const needsRemoval = [...state.needsRemoval];
      needsRemoval.push({
        ...state.all.find((proximity) => proximity.id === action.payload)
      });
      return {
        ...state,
        all: common.removeFromArrayById(state.all, action.payload),
        filtered: common.removeFromArrayById(state.filtered, action.payload),
        needsRemoval
      };
    }

    case actions.PROXIMITY_CLEAR_INSERT_QUEUE: {
      return {
        ...state,
        needsInsert: []
      };
    }

    case actions.PROXIMITY_CLEAR_REMOVE_QUEUE: {
      return {
        ...state,
        needsRemoval: []
      };
    }

    case actions.PROXIMITY_CLEAR_UPDATE_QUEUE: {
      return {
        ...state,
        needsUpdate: []
      };
    }

    case actions.PROXIMITY_UPDATE_ID: {
      return {
        ...state,
        all: common.updateIdInArray(state.all, action.payload),
        filtered: common.updateIdInArray(state.filtered, action.payload)
      };
    }

    case actions.PROXIMITY_RESEQUENCE: {
      let newAll = [...state.all];
      action.payload.forEach((change) => {
        let target = newAll.find(
          (proximity) => proximity.id === change.proximity_id
        );
        target.sortorder = change.sortorder;
      });
      newAll.sort((a, b) => a.sortorder - b.sortorder);
      return { ...state, all: newAll };
    }

    default:
      return state;
  }
}
