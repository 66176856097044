// APP Sagas
import * as actions from 'state/actions';
import { select, put } from 'redux-saga/effects';
import * as helper from './sagaHelper.js';
import mime from 'mime-types';

export const fileCreate = function* (action) {
  try {
    const reduxState = yield select();
    let ext = mime.extension(action.payload.type);
    ext = ext !== null ? ext : 'txt';
    let filename = `new.${ext}`;
    _urltoFile(action.payload.data, filename, action.payload.type).then(
      (file) => {
        _getSignedRequest(
          file,
          reduxState.user.token,
          action.payload.onSuccess,
          () => {
            console.error('FAILED');
          },
          action.payload.onProgress
        );
      }
    );
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.type
      }
    });
  }
};

export const fileDelete = function* (action) {
  if (!action.payload.id) return;
  try {
    const reduxState = yield select();
    const response = yield helper.signedServer(
      'GET',
      `delete/${action.payload.id}`,
      reduxState.user.token
    );

    yield put({
      type: actions.SERVER_S3_FILE_DELETE_RESPONSE,
      payload: {
        response: response,
        request: action.payload
      }
    });
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.type
      }
    });
  }
};

export const fileDelete_response = function* (action) {
  if (!action.payload.response.ok) {
    let errorPayload = {
      error: action.payload,
      source: actions.SERVER_S3_FILE_DELETE_RESPONSE
    };
    yield put({ type: actions.APP_SAGA_ERROR, payload: errorPayload });
  }
};

export const signUpload = function* () {};
export const signUpload_response = function* (action) {
  if (!action.payload.response.ok) {
    let errorPayload = {
      error: action.payload,
      source: actions.SERVER_S3_GET_SIGNED_UPLOAD_RESPONSE
    };
    yield put({ type: actions.APP_SAGA_ERROR, payload: errorPayload });
  }
};

const _getSignedRequest = (file, authToken, onSuccess, onFail, onProgress) => {
  const xhr = new XMLHttpRequest();
  const S3HELPER_URL = window._env_.REACT_APP_SERVER_S3_HELPER
    ? window._env_.REACT_APP_SERVER_S3_HELPER
    : `${window._env_.REACT_APP_BASE_URL}/server`;
  const requestURL = `${S3HELPER_URL}/sign-s3/?file-name=${file.name}&file-type=${file.type}`;
  xhr.open('GET', requestURL);
  xhr.setRequestHeader('Authorization', 'BEARER ' + authToken);
  xhr.onerror = (e) => {
    console.error(requestURL);
    console.error(e);
  };
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        _uploadToS3(
          file,
          response.signedRequest,
          response.app_id,
          response.filename,
          onSuccess,
          onFail,
          onProgress
        );
      } else {
        window.alertBar('Could not get signed URL.', { color: 'red' });
      }
    }
  };
  xhr.send();
};

const _uploadToS3 = (
  file,
  signedRequest,
  app_id,
  filename,
  onSuccess,
  onFail,
  onProgress
) => {
  let fileURL = `${filename}`;
  const xhr = new XMLHttpRequest();
  xhr.upload.onprogress = (e) => {
    if (e.lengthComputable) {
      let percentComplete = Math.ceil((e.loaded / e.total) * 100);

      onProgress(percentComplete);
    }
  };
  xhr.open('PUT', signedRequest);
  xhr.onreadystatechange = () => {
    if (xhr.readyState === 4) {
      if (xhr.status === 200) {
        onSuccess(fileURL);
        onProgress(0);
      } else {
        onFail('Could not upload file!');
        onProgress(0);
      }
    }
  };
  xhr.send(file);
};

const _urltoFile = (url, filename, mimeType) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
};
