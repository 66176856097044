import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrop, useDrag } from 'react-dnd';

export const Sortable = ({
  id,
  index,
  sortableItems,
  setSortableItems,
  children,
  accepts,
  onChange
}) => {
  const onMove = (dragIndex, hoverIndex) => {
    const dragCard = sortableItems[dragIndex];
    let newArray = [...sortableItems];
    newArray = newArray.filter((el) => el.id !== dragCard.id);
    newArray.splice(hoverIndex, 0, dragCard);
    setSortableItems(newArray);
  };
  const ref = useRef(null);
  const originalIndex = index;

  const [, drop] = useDrop({
    accept: accepts,
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
      onMove(dragIndex, hoverIndex);
      item.index = hoverIndex;
    }
  });

  const [{ isDragging }, drag] = useDrag({
    item: { type: accepts, id, index, originalIndex },
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging()
      };
    },
    end: () => {
      //(dropresult,monitor)
      if (typeof onChange === 'function') onChange(sortableItems);

      /* FIXME: The "out of bounds" is too agressive, disabling for now
        const { originalIndex } = monitor.getItem();
        const didDrop = monitor.didDrop();
        if (!didDrop) {
          onMove(index, originalIndex);
          dragInProgress = false;
        } else {
          dragInProgress = false;
          if (typeof onChange === 'function') onChange(sortableItems);
        }
      */
    }
  });

  const opacity = isDragging ? 0.25 : 1;
  const cursor = isDragging ? 'move' : 'grab';
  drag(drop(ref));
  return (
    <div
      data-isdragging={isDragging}
      data-id={id}
      ref={ref}
      style={{
        cursor,
        opacity
      }}
    >
      {children}
    </div>
  );
};

Sortable.propTypes = {
  accepts: PropTypes.string,
  id: PropTypes.number,
  index: PropTypes.number,
  sortableItems: PropTypes.array,
  setSortableItems: PropTypes.func,
  children: PropTypes.node,
  onChange: PropTypes.func
};
