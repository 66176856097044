import prefs from 'config/preferences.js';
import CONST from 'constants.js';
export default {
  queryParams: {},
  uiMode: CONST.UI_MODE.STANDARD,
  prefs,
  synchInProgress: false,
  error: {
    hasError: false,
    message: ''
  },
  dateFormat: {
    long: 'MMM DD, YYYY',
    longtime: 'MMM DD, YYYY @ hh:mm:ss A Z'
  },
  currentMarkerIndex: -1,
  progressPercentage: 0,
  users: [],
  isFramed: false,
  isInitialized: false,
  isLocked:
    localStorage.getItem('maplock') === null
      ? true
      : localStorage.getItem('maplock') === 'true',
  tileLayer: {
    attribution: '',
    url: ''
  },
  position: {},
  poi_fields: [],
  available_tileservers: [],
  userLocation: {
    watchID: null,
    currentLocation: null
  },
  drawing: []
};
