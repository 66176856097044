import initialState from './init/poi';
import * as actions from 'state/actions';
import common from './common.js';
import CONST from 'constants.js';

const _parsePOIData = (data) => {
  let poiWithData = [];
  if (data.length)
    data.forEach((datapoint) => {
      // FIXME:This is a patch to migrate old rep_config data, it can eventually be removed
      if (datapoint.representation_config?.leafletBaseObject) {
        datapoint.representation_config =
          datapoint.representation_config.options;
      }

      let index = poiWithData.indexOf(
        poiWithData.find((poi) => poi.id === datapoint.poi_id)
      );
      if (index === CONST.DOES_NOT_EXIST) {
        poiWithData.push({
          id: datapoint.poi_id,
          address_id: datapoint.poi_address_id,
          date: datapoint.poi_date,
          name: datapoint.poi_name,
          coordinate: datapoint.poi_coordinate,
          sortorder: datapoint.poi_sortorder,
          representation_id: datapoint.representation_id,
          representation_config: datapoint.representation_config,
          presentation_id: datapoint.presentation_id,
          presentation_config: datapoint.presentation_config,
          media_config: datapoint.media_config,
          data: []
        });
        index = poiWithData.length - 1;
      }
      if (datapoint.poi_data_id) {
        poiWithData[index].data.push({
          search_scope: datapoint.search_scope,
          is_published: datapoint.is_public,
          is_locked: datapoint.is_locked,
          is_markdown: datapoint.is_markdown,
          dataType: datapoint.field_data_type,
          dataId: datapoint.poi_data_id,
          poiId: datapoint.poi_id,
          field: datapoint.field,
          value: datapoint.field_value
        });
      }
    });
  return poiWithData;
};

export default function api(state = initialState, action) {
  switch (action.type) {
    case actions.POI_SET_CURRENT: {
      return {
        ...state,
        current: action.payload
          ? state.all.find((poi) => poi.id === action.payload)
          : null
      };
    }

    case actions.POI_GET_RESPONSE: {
      const newPoi = _parsePOIData(action.payload);
      let nextID = 0;
      newPoi.forEach((poi) => {
        if (poi.id > nextID) nextID = poi.id;
      });
      nextID = nextID + 9000;
      return {
        ...state,
        nextID,
        all: newPoi
      };
    }

    case actions.POI_GET_SELECTION_OPTIONS_RESPONSE:
      if (action.payload.length > 0) {
        let selectionOptions = [];
        action.payload.forEach((option) => {
          if (!selectionOptions[option.data_type_id])
            selectionOptions[option.data_type_id] = [];
          selectionOptions[option.data_type_id].push(option.value);
        });
        return {
          ...state,
          selection_options: selectionOptions
        };
      }
      return state;

    case actions.POI_SET_FILTERED:
      return {
        ...state,
        filtered: [...action.payload]
      };

    case actions.SEARCH_RESET:
      return {
        ...state,
        filtered: []
      };

    // NOTE: There are POI_* handlers in search.js as well
    case actions.POI_CREATE_ENQUEUE: {
      const needsInsert = [...state.needsInsert];
      needsInsert.push(action.payload);
      let nextID = state.nextID + 1;
      return {
        ...state,
        all: common.insertIntoArray(state.all, action.payload),
        needsInsert,
        nextID
      };
    }

    case actions.POI_UPDATE_ENQUEUE: {
      const needsUpdate = [...state.needsUpdate];
      needsUpdate.push(action.payload);
      return {
        ...state,
        current: action.payload,
        all: common.updateInArrayById(state.all, action.payload),
        filtered: common.updateInArrayById(state.filtered, action.payload),
        needsUpdate
      };
    }

    case actions.POI_DELETE_ENQUEUE: {
      const needsRemoval = [...state.needsRemoval];
      needsRemoval.push({
        ...state.all.find((poi) => poi.id === action.payload)
      });
      return {
        ...state,
        all: common.removeFromArrayById(state.all, action.payload),
        filtered: common.removeFromArrayById(state.filtered, action.payload),
        needsRemoval
      };
    }

    case actions.POI_CLEAR_INSERT_QUEUE: {
      return {
        ...state,
        needsInsert: []
      };
    }

    case actions.POI_CLEAR_REMOVE_QUEUE: {
      return {
        ...state,
        needsRemoval: []
      };
    }

    case actions.POI_CLEAR_UPDATE_QUEUE: {
      return {
        ...state,
        needsUpdate: []
      };
    }

    case actions.POI_UPDATE_ID: {
      return {
        ...state,
        all: common.updateIdInArray(state.all, action.payload),
        filtered: common.updateIdInArray(state.filtered, action.payload)
      };
    }

    case actions.POI_RESEQUENCE: {
      let newAll = [...state.all];
      action.payload.forEach((change) => {
        let target = newAll.find((poi) => poi.id === change.poi_id);
        target.sortorder = change.sortorder;
      });
      newAll.sort((a, b) => a.sortorder - b.sortorder);
      return { ...state, all: newAll };
    }

    default:
      return state;
  }
}
