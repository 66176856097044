import * as actions from 'state/actions';
import { select, put, delay } from 'redux-saga/effects';
import * as helper from './sagaHelper.js';

export const getManifest = function* (action) {
  if (action.payload !== null) {
    try {
      const reduxState = yield select();
      let manifesturl = `${action.payload.packageurl}/${reduxState.app.id}`;
      const response = yield helper.anonymous(
        'GET',
        `${manifesturl}/manifest.json?cachebust=${Math.random()}`
      );
      let response_json = null;
      if (response.ok) {
        response_json = yield response.json();
      }
      yield put({
        type: actions.SERVER_GET_MANIFEST_RESPONSE,
        payload: {
          packageurl: action.payload.packageurl,
          manifesturl: manifesturl,
          response: response,
          json: response_json,
          request: action.payload
        }
      });

      yield put({
        type: actions.SERVER_STATUS,
        payload: {
          id: null
        }
      });
    } catch (e) {
      let errorPayload = {
        error: e,
        source: actions.SERVER_GET_MANIFEST
      };
      yield put({ type: actions.APP_SAGA_ERROR, payload: errorPayload });
    }
  }
};
export const getManifest_response = function* (action) {
  if (action.payload.response.status === 404) {
    yield put({ type: actions.SERVER_INIT, payload: action.payload });
  }
};

export const initPackage = function* (action) {
  try {
    const reduxState = yield select();
    const response = yield helper.signedServer(
      'GET',
      `package/init/${reduxState.app.id}`,
      reduxState.user.token
    );
    if (response.ok) {
      yield put({
        type: actions.SERVER_GET_MANIFEST,
        payload: action.payload
      });
    } else {
      yield put({ type: actions.APP_SAGA_ERROR, payload: response });
    }
  } catch (e) {
    let errorPayload = {
      error: e,
      source: actions.SERVER_INIT
    };
    yield put({ type: actions.APP_SAGA_ERROR, payload: errorPayload });
  }
};

export const setManifest = function* (action) {
  try {
    yield put({ type: actions.SERVER_SET_INPROGRESS, payload: true });
    const reduxState = yield select();
    const response = yield helper.signedServer(
      'POST',
      `package/manifest/${reduxState.app.id}`,
      reduxState.user.token,
      JSON.stringify(action.payload.manifest)
    );
    if (response.ok) {
      yield put({ type: actions.SERVER_SET_INPROGRESS, payload: false });
      yield put({
        type: actions.SERVER_GET_MANIFEST,
        payload: { packageurl: reduxState.server.manifest.packageurl }
      });
    } else {
      yield put({
        type: actions.APP_SAGA_ERROR,
        payload: {
          error: response,
          source: actions.SERVER_SET_MANIFEST
        }
      });
    }
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: actions.SERVER_SET_MANIFEST
      }
    });
  }
};

export const packageCreate = function* (action) {
  try {
    yield put({ type: actions.SERVER_SET_INPROGRESS, payload: true });

    const reduxState = yield select();

    const config = yield helper.anonymousAPI(
      'GET',
      `config?id=eq.${reduxState.app.id}`
    );
    let configJSON = yield config.json();

    const geometry = yield helper.signedAPI(
      'GET',
      `s_geometrymap?map_id=eq.${reduxState.app.id}`,
      reduxState.user.token
    );
    let geometryJSON = yield geometry.json();

    const poi = yield helper.signedAPI(
      'GET',
      `s_poi_with_data?map_id=eq.${reduxState.app.id}`,
      reduxState.user.token
    );
    let poiJSON = yield poi.json();

    const media = yield helper.anonymousAPI(
      'GET',
      `mediaconfig?map_id=eq.${reduxState.app.id}`
    );
    let mediaJSON = yield media.json();

    const mediaManifest = yield helper.signedAPI(
      'GET',
      `s_media_manifest?map_id=eq.${reduxState.app.id}`,
      reduxState.user.token,
      action.payload.body
    );
    let mediaManifestJSON = yield mediaManifest.json();

    let body = JSON.stringify({
      mapid: reduxState.app.id,
      config: configJSON,
      geometry: geometryJSON,
      media: mediaJSON,
      poi: poiJSON,
      mediaManifest: mediaManifestJSON
    });

    const response = yield helper.signedServer(
      'POST',
      `package/create`,
      reduxState.user.token,
      body
    );

    if (response.status === 429) {
      alert('Packaging in progress! Please wait before requesting again.');
    } else {
      let json = yield response.json();
      yield put({
        type: actions.SERVER_CREATE_RESPONSE,
        payload: {
          response: response,
          response_json: json,
          request: action.payload
        }
      });
    }
  } catch (e) {
    let errorPayload = {
      error: e,
      source: actions.SERVER_CREATE
    };
    yield put({ type: actions.APP_SAGA_ERROR, payload: errorPayload });
  }
};
export const packageCreate_response = function* (action) {
  if (action.payload.response.ok) {
    yield put({
      type: actions.SERVER_STATUS,
      payload: {
        id: action.payload.response_json.id
      }
    });
  } else {
    yield put({ type: actions.SERVER_SET_INPROGRESS, payload: false });

    let errorPayload = {
      error: action.payload,
      source: actions.SERVER_CREATE_RESPONSE
    };
    yield put({ type: actions.APP_SAGA_ERROR, payload: errorPayload });
  }
};

export const packageDelete = function* (action) {
  try {
    const reduxState = yield select();
    const response = yield helper.signedServer(
      'GET',
      `package/delete/${action.payload.id}`,
      reduxState.user.token
    );
    yield put({
      type: actions.SERVER_DELETE_RESPONSE,
      payload: {
        response: response,
        request: action.payload
      }
    });
  } catch (e) {
    let errorPayload = {
      error: e,
      source: actions.SERVER_DELETE_RESPONSE
    };
    yield put({ type: actions.APP_SAGA_ERROR, payload: errorPayload });
  }
};
export const packageDelete_response = function* (action) {
  if (!action.payload.response.ok) {
    let errorPayload = {
      error: action.payload,
      source: actions.SERVER_DELETE_RESPONSE
    };
    yield put({ type: actions.APP_SAGA_ERROR, payload: errorPayload });
  }
};

export const packageStatus = function* (action) {
  try {
    const reduxState = yield select();
    let url =
      action.payload.id !== null
        ? `package/status/${action.payload.id}`
        : `package/status/`;
    const response = yield helper.signedServer(
      'GET',
      url,
      reduxState.user.token
    );
    let json = yield response.json();
    yield put({
      type: actions.SERVER_STATUS_RESPONSE,
      payload: {
        response: response,
        response_json: json,
        request: action.payload
      }
    });
  } catch (e) {
    let errorPayload = {
      error: e,
      source: actions.SERVER_STATUS
    };
    yield put({ type: actions.APP_SAGA_ERROR, payload: errorPayload });
  }
};
export const packageStatus_response = function* (action) {
  if (action.payload.response.ok) {
    if (action.payload.response_json.status === 'undefined') {
      return;
    }
    if (!action.payload.response_json.isComplete) {
      yield put({
        type: actions.SERVER_SET_INPROGRESS,
        payload: { beganAt: action.payload.response_json.requestMadeAt }
      });
      yield delay(250);
      yield put({
        type: actions.SERVER_STATUS,
        payload: {
          id: action.payload.request.id
        }
      });
    } else {
      const reduxState = yield select();
      yield put({
        type: actions.SERVER_SET_MANIFEST,
        payload: {
          manifest: {
            published: action.payload.request.id,
            packages: {
              ...reduxState.server.manifest.packages,
              [action.payload.request.id]: {
                id: action.payload.request.id,
                sizeInBytes:
                  action.payload.response_json.zipStatus.fs.processedBytes,
                packageDate: Date.now(),
                numberOfFiles:
                  action.payload.response_json.zipStatus.entries.total
              }
            }
          }
        }
      });
    }
  } else {
    let errorPayload = {
      error: action.payload,
      source: actions.SERVER_STATUS_RESPONSE
    };
    yield put({ type: actions.APP_SAGA_ERROR, payload: errorPayload });
  }
};
