import React from 'react';
import { useSelector } from 'react-redux';

const SystemInfo = () => {
  const server = useSelector((redux) => redux.server);
  const Row = (title, value) => {
    return (
      <tr>
        <td>{title}:</td>
        <td>
          <input
            style={{ width: '18rem' }}
            disabled={true}
            type="text"
            value={value ? value : ''}
          />
        </td>
      </tr>
    );
  };
  return (
    <table>
      <tbody>
        {Row('Run Mode', window._env_.REACT_APP_MODE)}
        {Row('Build ID', process.env.REACT_APP_BUILD_ID)}
        {Row('Build Date', process.env.REACT_APP_BUILD_DATE)}
        {Row('Asset Server', window._env_.REACT_APP_ASSET_SERVER)}

        {(window._env_.REACT_APP_GOOGLE_API_KEY.length > 0 && (
          <React.Fragment>
            {Row('✅ Google API Key', window._env_.REACT_APP_GOOGLE_API_KEY)}
          </React.Fragment>
        )) || (
          <React.Fragment>
            {Row('⚠️ Google API Key', '⚠️ Missing, geosearch will not work.')}
          </React.Fragment>
        )}

        {(window._env_.REACT_APP_MAPBOX_API_KEY.length > 0 && (
          <React.Fragment>
            {Row('✅ Mapbox API Key', window._env_.REACT_APP_MAPBOX_API_KEY)}
          </React.Fragment>
        )) || (
          <React.Fragment>
            {Row(
              '⚠️ Mapbox API Key',
              '⚠️ Missing: paid mapbox features will not work'
            )}
          </React.Fragment>
        )}
        {(server.isInitialized && (
          <React.Fragment>
            {Row('✅ Server', JSON.stringify(server))}
          </React.Fragment>
        )) || (
          <React.Fragment>
            {Row('⚠️ Server', '⚠️ Server offline')}
          </React.Fragment>
        )}
      </tbody>
    </table>
  );
};

export default SystemInfo;
