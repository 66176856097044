import initialState from './init/spinner';
import * as actions from 'state/actions';

export default function app(state = initialState, action) {
  switch (action.type) {
    case actions.APP_VISIBILITY_SPINNER:
      return { ...state, isVisible: action.payload };

    default:
      return state;
  }
}
