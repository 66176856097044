import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { NativeTypes } from 'react-dnd-html5-backend';
import TargetBox from './targetBox';
import styles from './DropArea.module.css';

const { FILE } = NativeTypes;
const DropArea = (props) => {
  const accepts = useMemo(() => [FILE], []);
  const onFileDrop = (item, monitor) => {
    if (monitor) {
      if (props.onDrop) props.onDrop({ item, files: monitor.getItem().files });
    }
  };

  return (
    <React.Fragment>
      <TargetBox
        onContextMenu={props.onContextMenu}
        style={props.style}
        {...props}
        {...props.dataset}
        styles={styles}
        accepts={accepts}
        onDrop={onFileDrop}
      />
    </React.Fragment>
  );
};
export default DropArea;

DropArea.defaultProps = {
  allowClickToZoom: false,
  children: null,
  dropMessage: 'Drop Here'
};

DropArea.propTypes = {
  onContextMenu: PropTypes.func,
  style: PropTypes.object,
  id: PropTypes.number,
  allowClickToZoom: PropTypes.bool,
  children: PropTypes.node,
  dropMessage: PropTypes.string,
  onRemovePreview: PropTypes.func,
  onDrop: PropTypes.func,
  previewImage: PropTypes.string,
  dataset: PropTypes.object,
  styles: PropTypes.object
};
