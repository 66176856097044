const common = {
  updateInArrayById: (arr, item) => {
    if (!arr) return arr;
    const targetPoi = arr.find((poi) => poi.id === item.id);
    let updatedArray = [...arr];
    updatedArray[updatedArray.indexOf(targetPoi)] = {
      ...targetPoi,
      ...item
    };
    return updatedArray;
  },

  insertIntoArray: (arr, item) => {
    if (!arr) return arr;
    let updatedArray = [...arr];
    updatedArray.push(item);
    return updatedArray;
  },

  removeFromArrayById: (arr, id) => {
    if (!arr) return arr;
    let updatedArray = [...arr];
    updatedArray.splice(
      arr.findIndex((item) => item.id === id),
      1
    );
    return updatedArray;
  },

  updateInArrayByCompare: (arr, itemToUpdate) => {
    if (!arr) return arr;
    let target;
    arr.forEach((item) => {
      if (common._matchAllKeys(item, { itemToUpdate })) target = item;
    });
    console.log(target);
    let updatedArray = [...arr];
    if (target)
      updatedArray[updatedArray.indexOf(target)] = {
        ...target,
        ...itemToUpdate
      };
    return updatedArray;
  },

  removeFromArrayByIdByCompare: (arr, itemToRemove) => {
    if (!arr) return arr;
    const splicedArray = [];
    arr.forEach((item) => {
      if (!common._matchAllKeys(item, itemToRemove)) splicedArray.push(item);
    });
    return splicedArray;
  },

  _matchAllKeys: (obj1, obj2) => {
    let match = true;
    Object.keys(obj1).forEach((key) => {
      if (match) match = obj1[key] === obj2[key];
    });
    return match;
  },

  updateIdInArray: (arr, payload) => {
    if (!arr) return arr;
    const targetPoi = arr.find((poi) => poi.id === payload.oldId);
    if (targetPoi) {
      let updatedArray = [...arr];
      updatedArray[updatedArray.indexOf(targetPoi)] = {
        ...targetPoi,
        id: payload.newId
      };
      return updatedArray;
    }
  }
};
export default common;
