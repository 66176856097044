import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'state/actions';
import PropTypes from 'prop-types';
import SentryBoundary from '../SentryBoundary';
import styles from './App.module.css';
import { connect } from 'react-redux';
import CONST from 'constants.js';
import { base64ToArrayBuffer } from 'utility';
import EXIF from 'exif-js';
import Mousetrap from 'mousetrap';
import * as util from 'utility';
import moment from 'moment';
import { mapHelper } from 'components/MapView';
import {
  AlertBar,
  AudioTourConfig,
  Dialog,
  GeoSearch,
  Inspector,
  MapView,
  MediaDisplay,
  MediaLibrary,
  Navigation,
  Pagination,
  Preferences,
  Progress,
  Search,
  Spinner,
  TimeFilter,
  UserManager,
  UserSelfManager,
  AudioTourPreview,
  Presentation
} from 'components';

const App = (props) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((redux) => redux);
  const onOptionUpdate = (opt) => {
    dispatch(actions.updateTimefilterOptions(opt));
    dispatch(
      actions.setFilteredPoi(
        reduxState.poi.all.filter((poi) => {
          if (moment(poi.date).isBetween(opt.start, opt.end)) return poi;
          return null;
        })
      )
    );
  };

  const onSearchOptionsUpdate = (search) =>
    dispatch(actions.updateSearchOptions(search));

  const onGeoSearch = (result) => {
    if (typeof result.error !== 'undefined') {
      window.alertBar('GEOSEARCH: Error, is your Google API key correct?');
    } else {
      if (typeof result.response.results[0].geometry.bounds !== 'undefined') {
        let bounds = [
          [
            result.response.results[0].geometry.bounds.northeast.lng,
            result.response.results[0].geometry.bounds.northeast.lat
          ],
          [
            result.response.results[0].geometry.bounds.southwest.lng,
            result.response.results[0].geometry.bounds.southwest.lat
          ]
        ];
        reduxState.app.map.fitBounds(bounds);
      } else if (
        typeof result.response.results[0].geometry.location !== 'undefined'
      ) {
        reduxState.app.map.easeTo({
          center: [
            result.response.results[0].geometry.location.lng,
            result.response.results[0].geometry.location.lat
          ],
          zoom: reduxState.app.map.getZoom() + 2
        });
      } else {
        console.warn(`I don't know how to navigate to ${result}`);
      }
    }
  };

  useEffect(() => {
    if (!util.missingValue(props.location.search)) {
      window.embedded_id = props.location.search.slice(4);
    } else {
      window.embedded_id = 'UNDEFINED';
    }

    if (!reduxState.app.isInitialized) {
      let mapID = parseInt(localStorage.getItem('mapID'), 10);
      if (!mapID || mapID === null || mapID === 'null') {
        localStorage.setItem('mapID', 1);
        dispatch(actions.initializeApp({ map_id: 1 }));
      } else {
        dispatch(actions.initializeApp({ map_id: mapID }));
      }
    }
    Mousetrap.bind(['ctrl+f', 'meta+f'], () => {
      Mousetrap.bind(['ctrl+f', 'meta+f'], () => {
        dispatch(actions.uiShow(CONST.UI.SEARCH));
        return false;
      });
      Mousetrap.bind(['ctrl+l', 'meta+l'], () => {
        mapHelper.onToggleLock();
        return false;
      });
      Mousetrap.bind(['esc'], () => {
        dispatch(actions.uiHideAll());
        return false;
      });
      return false;
    });
  }, []);

  const onDataUpload = (uploadType, data, type, metaData) => {
    let afterUpload;
    const exif = EXIF.readFromBinaryFile(base64ToArrayBuffer(data));
    delete exif.MakerNote;
    switch (uploadType) {
      case CONST.UPLOAD_TYPE.MEDIA:
        afterUpload = (url) => {
          dispatch(
            actions.createMedia({
              data: {
                name: metaData.name,
                url,
                type,
                exif: exif ? exif : {}
              }
            })
          );
        };
        break;
      case CONST.UPLOAD_TYPE.MEDIA_PREVIEW:
        afterUpload = (url) => {
          dispatch(
            actions.updateMedia({
              id: metaData.id,
              preview: url,
              preview_exif: exif ? exif : {}
            })
          );
        };
        break;
      case CONST.UPLOAD_TYPE.USERPIC:
        afterUpload = (url) => {
          dispatch(
            actions.updateUser({
              data: {
                picture: url,
                exif: exif ? exif : {}
              }
            })
          );
        };
        break;
      default:
        console.log("Don't know how to handle: " + uploadType);
        return;
    }

    dispatch(
      actions.s3CreateFile({
        data,
        type,
        onSuccess: afterUpload,
        onProgress: (percentage) => {
          dispatch(actions.setProgress({ percentage: percentage }));
        }
      })
    );
  };

  const onDataReplace = (uploadType, data, type, opt) => {
    const exif = EXIF.readFromBinaryFile(base64ToArrayBuffer(data));
    delete exif.MakerNote;
    let afterUpload = (url) => {
      dispatch(
        actions.replaceMedia({
          data: {
            replaces: reduxState.media.available.media.find(
              (media) => media.id === parseInt(opt.mediaid, 10)
            ),
            url,
            type,
            exif: exif ? exif : {}
          },
          onComplete: opt.onComplete
        })
      );
    };
    dispatch(
      actions.s3CreateFile({
        data,
        type,
        onSuccess: afterUpload,
        onProgress: (percentage) => {
          dispatch(actions.setProgress({ percentage: percentage }));
        }
      })
    );
  };

  const app = reduxState.app;
  const ui = reduxState.ui;
  const pref = (key) => {
    return app.prefs[key]?.value;
  };
  const isVisible = (key) => {
    return reduxState.ui[key]?.isVisible;
  };
  const isPinned = (key) => {
    return reduxState.ui[key]?.isPinned;
  };

  if (app.initWasEmpty) {
    return (
      <div>
        <SentryBoundary>
          <Navigation loginOnly={true} title={''} />
        </SentryBoundary>
      </div>
    );
  }

  if (!app.isInitialized) {
    return (
      <SentryBoundary>
        <Spinner
          showLogo={false}
          logoURL={window._env_.REACT_APP_PROJECT_LOGO}
        />
      </SentryBoundary>
    );
  }

  return (
    <div>
      <SentryBoundary>
        {window._env_.REACT_APP_MODE === CONST.APP_MODE.DEMO && (
          <div className={styles.demoLabel}>DEMO</div>
        )}
        <AlertBar />
        <Navigation
          onGeoSearch={onGeoSearch}
          onInfo={() => app.navigation.onInfo(this)}
        />
        <Progress percentage={app.progressPercentage} />
        <MapView
          disableClickableMarkers={
            reduxState.app.uiMode === CONST.UI_MODE.MAP_DRAWING ||
            reduxState.app.uiMode === CONST.UI_MODE.TOUR_PREVIEW
          }
          isPlaying={ui.playback.isPlaying}
          onMapDidLoad={mapHelper.onMapDidLoad}
        />
        {isVisible(CONST.UI.TOUR_PREVIEW) && <AudioTourPreview />}
        {pref('hasPagination') && app.available_maps && (
          <Pagination
            availableMaps={app.available_maps}
            currentID={app.id}
            onChange={(map_id) => dispatch(actions.initializeApp({ map_id }))}
          />
        )}
        {isVisible(CONST.UI.MEDIA_LIBRARY) && (
          <MediaLibrary
            showTypes={[]}
            onDataReplace={onDataReplace}
            onDataUpload={onDataUpload}
            onNavigateToPOI={(poiID) => app.onNavigateToPOI(this, { poiID })}
          />
        )}
        {isVisible(CONST.UI.DIALOG) && (
          <Dialog
            align={ui.dialog.align}
            confirmLabel={ui.dialog.confirmLabel}
            cancelLabel={ui.dialog.cancelLabel}
            dialogMessage={ui.dialog.message}
            onConfirm={ui.dialog.onConfirm}
            onCancel={ui.dialog.onCancel}
            outsideClickConfirm={true}
          />
        )}
        {isVisible(CONST.UI.PREFERENCES) && <Preferences />}
        {isVisible(CONST.UI.GEOSEARCH) && (
          <GeoSearch onGeoSearch={onGeoSearch} />
        )}
        {isVisible(CONST.UI.USER_MANAGER) && <UserManager />}
        {isVisible(CONST.UI.USER_SELF_MANAGER) && <UserSelfManager />}
        {pref('hasAudioTour') && isVisible(CONST.UI.AUDIO_TOUR) && (
          <AudioTourConfig />
        )}
        {pref('hasSearch') &&
          !isVisible(CONST.UI.POI_PRESENTATION) &&
          (isPinned(CONST.UI.SEARCH) ||
            (isVisible(CONST.UI.SEARCH) && (
              <Search
                isFiltered={reduxState.poi.filtered?.length > 0}
                isPaused={ui.playback.isPaused}
                isPinned={ui.timeFilter.isPinned}
                isPlaying={ui.playback.isPlaying}
                playPointer={ui.playback.pointer}
                poi={reduxState.poi}
                poiFields={app.poi_fields}
                search={reduxState.search}
                onEdit={(poiId, pointer) => {
                  dispatch(actions.setCurrentPoi(poiId));
                  dispatch(actions.setAllVisibilityOff());
                  dispatch(actions.uiShow(CONST.UI.POI_PRESENTATION));
                  dispatch(actions.searchSetPointer(pointer));
                }}
                onDelete={mapHelper.onDelete}
                onNavigateToPoi={mapHelper.onNavigate}
                onPause={mapHelper.onPause}
                onPlay={(search) => mapHelper.onPlay(search)}
                onSearchOptionsUpdate={onSearchOptionsUpdate}
                onSearchReset={() => dispatch(actions.resetFilter())}
                onStop={mapHelper.onStop}
              />
            )))}

        {isVisible(CONST.UI.POI_PRESENTATION) && <Presentation />}

        {isVisible(CONST.UI.TIME_FILTER) && (
          <TimeFilter
            options={reduxState.timeFilter}
            poi={reduxState.poi}
            dateFormat={app.dateFormat.long}
            isPinned={ui.timeFilter.isPinned}
            onOptionUpdate={onOptionUpdate}
          />
        )}
        {app.synchInProgress && (
          <div className={styles.workIndicator}>
            <div className={styles.workIndicatorCircle}>
              <div className={styles.workIndicatorSpinner} />
            </div>
          </div>
        )}
      </SentryBoundary>
    </div>
  );
};

export default connect((reduxState) => {
  return { reduxState };
})(App);

App.propTypes = {
  reduxState: PropTypes.object,
  dispatch: PropTypes.func,
  id: PropTypes.number,
  location: PropTypes.object
};
