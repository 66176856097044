export default {
  pointer: 1,
  results: [],
  options: {
    keyword: '',
    scope: '',
    caseSensitive: false,
    exactMatch: false,
    sortOn: 'displayName',
    isReversed: false,
    applyToMap: false
  }
};
