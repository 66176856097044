// USER Sagas
import * as actions from 'state/actions';
import { select, put, delay, call } from 'redux-saga/effects';
import * as helper from './sagaHelper.js';
import CONST from 'constants.js';
import { onReload } from 'utility';
import * as user from './user.js';

export const generateDummyToken = function* () {
  yield put({
    type: actions.APP_LOGIN_RESPONSE,
    payload: {
      response: { ok: true },
      json: [
        {
          token:
            'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJEZW1vIiwiaWF0IjoxNTgwMTcxNjQxLCJleHAiOjMyNTA1OTI4NDQxLCJhdWQiOiIiLCJzdWIiOiIiLCJyb2xlIjpbImRlbW9AZXhhbXBsZS5jb20iLCJkZW1vQGV4YW1wbGUuY29tIl0sIm5hbWUiOiJEZW1vIiwiZW1haWwiOiJkZW1vQGV4YW1wbGUuY29tIiwiZ3JvdXBfcm9sZSI6IndlYnN1cGVyIiwicGljdHVyZSI6Imh0dHBzOi8vYXNzZXRzLnRoZXBsYWNlbGFiLmNvbS9kZW1vL3VzZXIucG5nIiwiaWQiOiItMSIsImlzX3ZhbGlkYXRlZCI6InRydWUiLCJmb3JjZV9uZXdwYXNzIjoiZmFsc2UifQ.LV01c-pwvMR7SsPJVX26Qcxab0o99gyduV_ClkgbJPo'
        }
      ]
    }
  });
};

export const login = function* (action) {
  if (CONST.APP_MODE.DEMO === window._env_.REACT_APP_MODE) {
    yield generateDummyToken();
  } else {
    try {
      const reduxState = yield select();
      const response = yield helper.anonymousAPI('POST', `rpc/login`, {
        email: action.payload.username,
        pass: action.payload.password
      });
      let response_json = yield response.json();
      yield put({
        type: actions.APP_LOGIN_RESPONSE,
        payload: { response: response, json: response_json }
      });
      if (!reduxState.app.is_published) onReload();
      yield put({ type: actions.POI_REFRESH });
    } catch (e) {
      let errorPayload = {
        error: e,
        source: 'authenticate'
      };
      yield put({ type: actions.APP_SAGA_ERROR, payload: errorPayload });
    }
  }
};

export const checkAuthorizationStatus = function* () {
  const reduxState = yield select();
  while (true) {
    if (reduxState.user.isAuthenticated) {
      let now = Date.now() / 1000;
      let secondsUntilExpire = reduxState.user.claims.exp - now;
      if (secondsUntilExpire <= 120) {
        yield call(user.reauthorize);
      }
    } else {
      if (localStorage.getItem('authtoken') !== null) {
        yield call(user.reauthorize);
      } else {
        if (!reduxState.app.isLocked) {
          // /yield call(app.APP_TOGGLE_MAPLOCK);
        }
      }
    }

    yield delay(60000);
  }
};

export const reauthorize = function* () {
  if (CONST.APP_MODE.DEMO === window._env_.REACT_APP_MODE) {
    yield generateDummyToken();
  } else {
    try {
      let authToken = localStorage.getItem('authtoken');
      if (authToken === null) return;
      authToken = authToken.replace(/['"]+/g, '');
      const response = yield helper.signedAPI(
        'POST',
        `rpc/reauthorize`,
        authToken,
        JSON.stringify({ token: authToken })
      );

      let response_json = yield response.json();
      yield put({
        type: actions.APP_LOGIN_RESPONSE,
        payload: { response: response, json: response_json }
      });
    } catch (e) {
      let errorPayload = {
        error: e,
        source: 'reauthorize'
      };
      yield put({
        type: actions.APP_SAGA_ERROR_NONFATAL,
        payload: errorPayload
      });
    }
  }
};

export const login_resp = function* (action) {
  if (action.payload.response.ok) {
    yield localStorage.setItem(
      'authtoken',
      JSON.stringify(action.payload.json.token)
    );
  } else {
    yield localStorage.removeItem('authtoken');
  }
};

export const logout = function* () {
  const reduxState = yield select();
  yield localStorage.removeItem('authtoken');
  if (!reduxState.app.is_published) onReload();
};

export const getUsers = function* (action) {
  try {
    const reduxState = yield select();
    let response;
    response = yield helper.signedAPI('GET', `users`, reduxState.user.token);
    let response_json = yield response.json();
    yield put({
      type: actions.USER_GET_RESPONSE,
      payload: { response: response_json, request: action.payload }
    });
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.payload.type
      }
    });
  }
};

export const requestValidation = function* (action) {
  const reduxState = yield select();
  yield helper.signedServer(
    'GET',
    `user/request_validation/${action.payload.id}`,
    reduxState.user.token
  );
};

export const resetPassword = function* (action) {
  const reduxState = yield select();
  yield helper.signedServer(
    'GET',
    `user/reset_password/${action.payload.id}`,
    reduxState.user.token
  );
};

export const updateUserWithPasswordCheck = function* (action) {
  const payload = action.payload.payload;
  const opt = action.payload.opt;
  try {
    const response = yield helper.anonymousAPI('POST', `rpc/login`, {
      email: payload.email,
      pass: payload.password
    });
    if (response.ok) {
      yield put({
        type: actions._USER_UPDATE,
        payload: { id: payload.id, pass: payload.new_password },
        opt
      });
    } else {
      opt.onFail('Password not changed: Enter valid password');
    }
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.payload.type
      }
    });
  }
};

export const createUser = function* (action) {
  const payload = action.payload.payload;
  const opt = { ...action.payload.opt, sendValidation: true };
  yield put({
    type: actions._USER_CREATE,
    payload,
    opt
  });
};

export const updateUser = function* (action) {
  const payload = action.payload.payload;
  const opt = action.payload.opt;
  yield put({
    type: actions._USER_UPDATE,
    payload,
    opt
  });
};

export const deleteUser = function* (action) {
  const opt = action.payload.opt;
  yield put({
    type: actions._USER_DELETE,
    payload: action.payload,
    opt
  });
};

export const crud_helper = function* (method, endpoint, action) {
  const payload = action.payload;
  const opt = action.opt;
  try {
    if (method === 'DELETE' || method === 'PATCH') {
      endpoint = `${endpoint}?id=eq.${payload.id}`;
    }
    const reduxState = yield select();
    const response = yield helper.signedAPI(
      method,
      endpoint,
      reduxState.user.token,
      payload
    );

    if (response.ok) {
      if (opt) {
        if (opt.sendValidation) {
          let response_json = yield response.json();
          yield put({
            type: actions.USER_REQUEST_VALIDATION,
            payload: { id: response_json[0].id }
          });
        }
        yield opt.onSuccess(`User ${method === 'POST' ? 'created' : 'updated'}
      !`);
      }
      if (reduxState.user.claims.id === payload.id)
        yield put({
          type: actions.APP_REAUTHORIZE,
          payload: action.payload.map_id
        });
    } else {
      if (opt)
        yield opt.onFail(
          `ERROR: User not ${method === 'POST' ? 'created' : 'updated'}`
        );
      yield put({
        type: actions.APP_SAGA_ERROR_NONFATAL,
        payload: {
          source: action.type
        }
      });
    }

    yield put({
      type: actions._USER_CRUD_RESPONSE,
      payload: {
        response: response,
        request: payload
      }
    });
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: payload.type
      }
    });
  }
};

export const crud_response = function* () {
  const reduxState = yield select();
  yield put({
    type: actions.USER_GET,
    payload: reduxState.app.id
  });
};
