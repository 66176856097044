import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Dialog.module.css';
import WatchForOutsideClick from '../WatchForOutsideClick';
import Button from '../Button';
import memoizeOne from 'memoize-one';

import { dangerouslyLoadContentIntoDiv } from 'utility';
class Dialog extends Component {
  constructor(props) {
    super(props);
    this.m_loadContent = memoizeOne((input) => {
      if (!input) return;
      if (input.includes('http')) {
        dangerouslyLoadContentIntoDiv(input, 'messageContent', () => {
          document.getElementById('dialogbox').style.opacity = '1';
        });
        return null;
      } else {
        return input;
      }
    });
  }

  componentDidMount() {
    if (this.props.dialogMessage.includes('http')) {
      document.getElementById('dialogbox').style.opacity = '0';
    }
  }

  render() {
    let dialogMessage = this.m_loadContent(this.props.dialogMessage);
    return (
      <div className={styles.dialog_modal}>
        <div id={'dialogbox'} className={styles.dialogbox}>
          <WatchForOutsideClick
            onOutsideClick={
              this.props.outsideClickConfirm
                ? this.props.onConfirm
                : this.props.onCancel
            }
          >
            <div>
              <div
                id="messageContent"
                className={styles.content}
                style={{ textAlign: this.props.align }}
              >
                {dialogMessage}
              </div>
              <div className={styles.buttons}>
                {this.props.confirmLabel !== null && (
                  <Button id="confirm" onClick={this.props.onConfirm}>
                    {this.props.confirmLabel}
                  </Button>
                )}
                {this.props.altLabel !== null && (
                  <Button id="alt" onClick={this.props.onAlt}>
                    {this.props.altLabel}
                  </Button>
                )}
                {this.props.cancelLabel !== null && (
                  <Button id="cancel" onClick={this.props.onCancel}>
                    {this.props.cancelLabel}
                  </Button>
                )}
              </div>
            </div>
          </WatchForOutsideClick>
        </div>
      </div>
    );
  }
}
export default Dialog;

Dialog.defaultProps = {
  dialogMessage: '',
  align: 'center',
  confirmLabel: 'Ok',
  altLabel: null,
  cancelLabel: 'Cancel',
  outsideClickConfirm: false
};

Dialog.propTypes = {
  confirmLabel: PropTypes.string,
  cancelLabel: PropTypes.string,
  altLabel: PropTypes.string,
  align: PropTypes.string,
  dialogMessage: PropTypes.any.isRequired,
  onConfirm: PropTypes.func,
  onAlt: PropTypes.func,
  onCancel: PropTypes.func,
  outsideClickConfirm: PropTypes.bool
};
