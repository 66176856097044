import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import FormInput, { FIELDTYPE } from 'components/FormInput';
import CONST from 'constants.js';
import { IconButton, Collapsable } from 'components';
import { useSelector } from 'react-redux';
import { Sortable } from 'components/DragNDrop';
import uuidv4 from 'uuid/v4';
import { useDispatch } from 'react-redux';
import * as actions from 'state/actions';
import ContentEditable from 'react-contenteditable';
import ce from './ceHelper.js';

const ConfigureTrack = (props) => {
  const dispatch = useDispatch();
  const tourSettings = useSelector((redux) => redux.audioTour);
  const [sortableItems, setSortableItems] = useState(null);
  const [dragGroupId] = useState(uuidv4());
  const media = useSelector((redux) => redux.media);
  const availableMedia = media.available.media?.filter(
    (media) => !media.type.includes('image')
  );

  useEffect(() => {
    setSortableItems(props.trackItems);
  }, [props.trackItems]);

  const trackItems =
    props.trackItems.length > 0
      ? props.trackItems
      : [
          {
            id: null,
            does_loop: false,
            does_duck: false,
            transition_type_id: CONST.TRANSITION.NONE,
            transition_options: {},
            trigger_type_id: props.triggerType,
            only_once: false,
            session_skip: false,
            never_after: null,
            only_after: null,
            on_tod: null,
            fires_on_count: null,
            fires_on_count_global: null,
            on_weather: null,
            follow_at: null,
            track_id: props.trackId,
            poi_id: props.poiId,
            proximity_id: props.proximityId
          }
        ];

  if (!media.available.transitions) return null;
  let doNothing = trackItems[0].transition_type_id === CONST.TRANSITION.NONE;

  let transitionDefaultOptions = media.available.transitions.find(
    (transition) =>
      transition.id === parseInt(trackItems[0].transition_type_id, 10)
  ).options;

  let transitionOptions_values = {};
  if (transitionDefaultOptions)
    Object.keys(transitionDefaultOptions.options).forEach(
      (key) =>
        (transitionOptions_values[key] =
          transitionDefaultOptions.options[key].default)
    );
  transitionOptions_values = {
    ...transitionOptions_values,
    ...trackItems[0].transition_options
  };

  let previousDidLoop = 0;
  return (
    <div
      style={{
        color: 'white',
        padding: 0,
        margin: 0,
        opacity: doNothing ? '0.5' : '1.0',
        borderLeft: `2rem solid rgba(0, 0, 0, .2)`,
        backgroundColor: `${tourSettings.trackColors[props.trackId]}`
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            width: '1rem',
            overflow: 'hidden',
            minHeight: '4rem',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            margin: '1rem 0 0 -1.5rem'
          }}
        >
          <div
            style={{
              transform: 'rotate(90deg)'
            }}
          >
            Track {props.trackId + 1}
          </div>
        </div>
        <div
          style={{
            width: '90%',
            marginLeft: '1rem',
            flexGrow: 1,
            padding: '1rem'
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderBottom: doNothing ? '' : '1px dashed #dddddd',
                padding: doNothing ? '' : '0 0 .5rem 0',
                margin: doNothing ? '' : '0 0 .5rem 0'
              }}
            >
              <div
                style={{
                  flexGrow: '1',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center'
                }}
              >
                <div
                  style={{
                    flexGrow: '1',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <div style={{ marginRight: '.2rem' }}>
                    <FormInput
                      style={{ input: { width: '6rem' } }}
                      type={FIELDTYPE.SELECT}
                      id="transition_type_id"
                      value={trackItems[0].transition_type_id}
                      onChange={props.onChangeGroup}
                      options={{
                        selectionOptions: media.available.transitions,
                        data: { trackItem: trackItems[0] }
                      }}
                    />
                  </div>
                  {transitionDefaultOptions?.editableOptions &&
                    transitionDefaultOptions.editableOptions.map((option) => {
                      const thisOption =
                        transitionDefaultOptions.options[option];
                      return (
                        <div
                          style={{ flexGrow: '1' }}
                          key={`${props.trackId}_transition_options_${option}`}
                        >
                          <FormInput
                            style={{
                              input: { width: '6rem', height: '2.25rem' }
                            }}
                            id="transition_options"
                            type={thisOption.formType}
                            onChange={props.onChangeGroup}
                            value={
                              transitionOptions_values[option]
                                ? transitionOptions_values[option]
                                : thisOption.default
                            }
                            options={{
                              data: {
                                trackItem: trackItems[0],
                                option
                              }
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
                <div style={{ textAlign: 'right', marginRight: '1rem' }}>
                  {!doNothing && (
                    <FormInput
                      id="session_skip"
                      type={FIELDTYPE.CHECKBOX_ICON}
                      onChange={props.onChangeGroup}
                      value={trackItems[0].session_skip}
                      label={
                        trackItems[0].session_skip
                          ? 'Gate will fire always. Clips should have exclusive (only/never) rules applied'
                          : 'Gate will fire once per session'
                      }
                      options={{
                        data: { trackItem: trackItems[0] },
                        invert: true,
                        icon: 'sort-numeric-down',
                        activeColor: '#d8ff00'
                      }}
                    />
                  )}
                </div>
                <div style={{ textAlign: 'right' }}>
                  {!doNothing && (
                    <FormInput
                      id="only_once"
                      type={FIELDTYPE.CHECKBOX_ICON}
                      onChange={props.onChangeGroup}
                      value={trackItems[0].only_once}
                      label={
                        trackItems[0].only_once
                          ? 'Track will fire only once, ever.'
                          : 'Track will fire every time this trigger is encountered'
                      }
                      options={{
                        data: { trackItem: trackItems[0] },
                        showBothActive: true,
                        iconAlt: 'infinity',
                        icon: 'long-arrow-alt-right',
                        activeColor: '#d8ff00'
                      }}
                    />
                  )}
                </div>
              </div>
            </div>

            {sortableItems?.map((sortable, idx) => {
              const trackItem = trackItems.find(
                (item) => item.id === sortable.id
              );
              if (!trackItem || doNothing) return null;

              if (trackItem.does_loop || previousDidLoop) previousDidLoop++;
              const isDisabled = previousDidLoop > 1;
              const isConditional =
                trackItem.never_after !== null ||
                trackItem.only_after !== null ||
                trackItem.on_tod !== null ||
                trackItem.on_weather !== null ||
                trackItem.fires_on_count !== null ||
                trackItem.fires_on_count_global !== null;

              let mediaName = media.available.media.find(
                (media) => media.id === trackItem.media_id
              );
              mediaName = trackItem.displayname
                ? trackItem.displayname
                : mediaName
                ? mediaName.name
                : 'SILENCE';

              return (
                <Sortable
                  accepts={dragGroupId}
                  key={`trackItem_${trackItem.id}`}
                  index={idx}
                  id={trackItem.id}
                  sortableItems={sortableItems}
                  setSortableItems={setSortableItems}
                  onChange={(trackItemsArr) => {
                    dispatch(
                      actions.resequenceTrackItems(
                        trackItemsArr.map((item, idx) => ({
                          audiotour_id: item.id,
                          sortorder: idx
                        }))
                      )
                    );
                  }}
                >
                  <div
                    key={`${props.trackId}_${idx}`}
                    style={styles.trackConfig}
                  >
                    <Collapsable
                      style={{ width: '100%', margin: '0' }}
                      isOpen={false}
                      clickableTitle={false}
                      title={
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            width: '100%',
                            flexGrow: '1'
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column'
                            }}
                          >
                            <ContentEditable
                              html={mediaName}
                              data-column="id"
                              data-row={trackItem.id}
                              onKeyPress={ce.disableNewlines}
                              onPaste={ce.pastePlainText}
                              onFocus={ce.highlightAll}
                              onBlur={(e) => {
                                props.onChange({
                                  id: 'displayname',
                                  value: e.target.textContent,
                                  data: { trackItem }
                                });
                              }}
                            />
                            <div style={styles.trackOptions}>
                              <div style={styles.trackOptions}>
                                <FormInput
                                  id="media_id"
                                  onChange={props.onChange}
                                  options={{
                                    selectionOptions: [
                                      { id: null, name: 'SILENCE' },
                                      ...(availableMedia ? availableMedia : '')
                                    ],
                                    data: { trackItem }
                                  }}
                                  value={trackItem.media_id}
                                  type={FIELDTYPE.SELECT}
                                  isDisabled={isDisabled}
                                />
                                <div
                                  style={{
                                    marginLeft: '.51rem',
                                    width: '5rem',
                                    borderRight: '1px solid grey',
                                    borderLeft: '1px solid grey'
                                  }}
                                >
                                  <div
                                    title={'This clip fires conditionally'}
                                    style={{
                                      fontSize: '1.5rem',
                                      color: '#d8ff00',
                                      marginRight: '1rem',
                                      opacity: isConditional ? 1 : 0,
                                      transform:
                                        'rotate(90deg) translate(5px, 1px)'
                                    }}
                                    className={`fas fa-code-branch`}
                                  />
                                  <div
                                    title={
                                      'This clip will never be reached (previous is looping)'
                                    }
                                    style={{
                                      fontSize: '1.5rem',
                                      color: 'red',
                                      opacity: isDisabled ? 1 : 0
                                    }}
                                    className={`fas fa-times`}
                                  />
                                </div>
                                <div
                                  style={{
                                    margin: '0.25rem',
                                    marginLeft: '0.5rem'
                                  }}
                                >
                                  <FormInput
                                    isDisabled={isDisabled}
                                    id="does_loop"
                                    value={trackItem.does_loop}
                                    onChange={props.onChange}
                                    type={FIELDTYPE.CHECKBOX_ICON}
                                    label="loop"
                                    options={{
                                      data: { trackItem },
                                      icon: 'infinity',
                                      activeColor: '#d8ff00'
                                    }}
                                  />
                                </div>
                                <div style={{ margin: '0.25rem' }}>
                                  <FormInput
                                    isDisabled={isDisabled}
                                    id="does_duck"
                                    value={trackItem.does_duck}
                                    onChange={props.onChange}
                                    type={FIELDTYPE.CHECKBOX_ICON}
                                    label="ducks other"
                                    options={{
                                      data: { trackItem },
                                      icon: 'caret-square-down',
                                      activeColor: '#d8ff00'
                                    }}
                                  />
                                </div>
                              </div>
                              <div style={styles.trackButtons}>
                                <IconButton
                                  isDisabled={sortableItems.length === 1}
                                  metaData={trackItem}
                                  isBorderless={true}
                                  icon={'minus'}
                                  onClick={props.onRemoveFollow}
                                />
                                {idx === sortableItems.length - 1 && (
                                  <IconButton
                                    metaData={{
                                      ...trackItem,
                                      id: null,
                                      media_id: null,
                                      sortorder: trackItems.length
                                    }}
                                    isBorderless={true}
                                    icon={'plus'}
                                    onClick={props.onAddFollow}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    >
                      <div style={styles.subOptions}>
                        {idx > 0 && (
                          <FormInput
                            style={{
                              label: { color: 'white', marginTop: '0.5rem' }
                            }}
                            onChange={props.onChange}
                            isDisabled={isDisabled}
                            id="follow_at"
                            value={
                              trackItem.follow_at ? trackItem.follow_at : ''
                            }
                            type={FIELDTYPE.DURATION}
                            label="Follow Delay (MM:SS)"
                            options={{ data: { trackItem } }}
                          />
                        )}
                        <div style={styles.rowItem}>
                          <div>
                            <FormInput
                              style={{
                                label: { color: 'white', marginTop: '0.5rem' }
                              }}
                              onChange={props.onChange}
                              isDisabled={isDisabled}
                              id="on_weather"
                              value={trackItem.on_weather}
                              type={FIELDTYPE.SELECT}
                              label="When Weather Is"
                              options={{
                                selectionOptions: [
                                  ...[{ id: null, name: '-' }],
                                  ...(props.availableWeather
                                    ? props.availableWeather
                                    : [])
                                ],
                                data: { trackItem }
                              }}
                            />
                          </div>
                          <div
                            title={'The condition'}
                            style={{
                              ...styles.branch,
                              opacity: trackItem.on_weather !== null ? 1 : 0
                            }}
                            className={`fas fa-code-branch`}
                          />
                        </div>
                        <div style={styles.rowItem}>
                          <div>
                            <FormInput
                              style={{
                                label: { color: 'white', marginTop: '0.5rem' }
                              }}
                              onChange={props.onChange}
                              isDisabled={isDisabled}
                              id="fires_on_count"
                              value={trackItem.fires_on_count}
                              type={FIELDTYPE.TEXT}
                              label="Fires On Visit"
                              options={{
                                data: { trackItem }
                              }}
                            />
                          </div>
                          <div
                            title={'The condition'}
                            style={{
                              ...styles.branch,
                              opacity: trackItem.fires_on_count !== null ? 1 : 0
                            }}
                            className={`fas fa-code-branch`}
                          />
                        </div>
                        <div style={styles.rowItem}>
                          <div>
                            <FormInput
                              style={{
                                label: { color: 'white', marginTop: '0.5rem' }
                              }}
                              onChange={props.onChange}
                              isDisabled={isDisabled}
                              id="fires_on_count_global"
                              value={trackItem.fires_on_count_global}
                              type={FIELDTYPE.TEXT}
                              label="Fires On Visit (Global)"
                              options={{
                                data: { trackItem }
                              }}
                            />
                          </div>
                          <div
                            title={'The condition'}
                            style={{
                              ...styles.branch,
                              opacity:
                                trackItem.fires_on_count_global !== null ? 1 : 0
                            }}
                            className={`fas fa-code-branch`}
                          />
                        </div>
                        <div style={styles.rowItem}>
                          <div>
                            <FormInput
                              style={{
                                label: { color: 'white', marginTop: '0.5rem' }
                              }}
                              onChange={props.onChange}
                              isDisabled={isDisabled}
                              id="on_tod"
                              value={trackItem.on_tod}
                              type={FIELDTYPE.SELECT}
                              label="When Time of Day Is"
                              options={{
                                selectionOptions: [
                                  ...[{ id: null, name: '-' }],
                                  ...(props.availableTod
                                    ? props.availableTod
                                    : [])
                                ],
                                data: { trackItem }
                              }}
                            />
                          </div>
                          <div
                            title={'The condition'}
                            style={{
                              ...styles.branch,
                              opacity: trackItem.on_tod !== null ? 1 : 0
                            }}
                            className={`fas fa-code-branch`}
                          />
                        </div>

                        <div style={styles.rowItem}>
                          <div>
                            <FormInput
                              style={{
                                label: { color: 'white', marginTop: '0.5rem' }
                              }}
                              onChange={props.onChange}
                              isDisabled={isDisabled}
                              id="never_after"
                              value={trackItem.never_after}
                              type={FIELDTYPE.SELECT}
                              label="Never Plays After"
                              options={{
                                selectionOptions: [
                                  ...[{ id: null, name: '-' }],
                                  ...(props.availablePoi
                                    ? props.availablePoi
                                    : [])
                                ],
                                data: { trackItem }
                              }}
                            />
                          </div>
                          <div
                            title={'The condition'}
                            style={{
                              ...styles.branch,
                              opacity: trackItem.never_after !== null ? 1 : 0
                            }}
                            className={`fas fa-code-branch`}
                          />
                        </div>
                        <div style={styles.rowItem}>
                          <div>
                            <FormInput
                              style={{
                                label: { color: 'white', marginTop: '0.5rem' }
                              }}
                              onChange={props.onChange}
                              isDisabled={isDisabled}
                              id="only_after"
                              value={trackItem.only_after}
                              type={FIELDTYPE.SELECT}
                              label="Only Plays After"
                              options={{
                                selectionOptions: [
                                  ...[{ id: null, name: '-' }],
                                  ...(props.availablePoi
                                    ? props.availablePoi
                                    : [])
                                ],
                                data: { trackItem }
                              }}
                            />
                          </div>
                          <div
                            title={'The condition'}
                            style={{
                              fontSize: '1.5rem',
                              color: '#d8ff00',
                              marginRight: '1rem',
                              marginLeft: '1.5rem',
                              opacity: trackItem.only_after !== null ? 1 : 0,
                              transform: 'rotate(90deg) translate(10px, 1px)'
                            }}
                            className={`fas fa-code-branch`}
                          />
                        </div>
                      </div>
                    </Collapsable>
                  </div>
                </Sortable>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfigureTrack;

const styles = {
  trackContainer: {
    color: 'white',
    padding: 0,
    margin: 0,
    minHeight: '4rem'
  },
  trackLabel: {
    margin: 0,
    padding: 0,
    border: '1px solid blue',
    position: 'relative',
    color: 'white',
    fontWeight: '900'
  },
  trackConfig: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  trackOptions: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
    alignItems: 'center',
    flexGrow: 1
  },
  trackButtons: { width: '4rem', textAlign: 'right' },
  subOptions: {
    margin: '1rem 0 2rem 2rem'
  },
  rowItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  branch: {
    fontSize: '1.5rem',
    color: '#d8ff00',
    marginRight: '1rem',
    marginLeft: '1.5rem',
    transform: 'rotate(90deg) translate(10px, 1px)'
  }
};

ConfigureTrack.defaultProps = {};

ConfigureTrack.propTypes = {
  availablePoi: PropTypes.array,
  availableTod: PropTypes.array,
  availableWeather: PropTypes.array,
  onAddFollow: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onChangeGroup: PropTypes.func.isRequired,
  onRemoveFollow: PropTypes.func,
  poiId: PropTypes.any,
  proximityId: PropTypes.any,
  sortableItems: PropTypes.array,
  trackId: PropTypes.number.isRequired,
  trackItems: PropTypes.array,
  triggerType: PropTypes.any
};
