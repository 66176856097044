import {
  WatchForOutsideClick,
  SystemInfo,
  Button,
  Collapsable
} from 'components';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import colors from 'colors';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'state/actions';
import CONST from 'constants.js';
import FormInput from 'components/FormInput';

const Preferences = () => {
  const dispatch = useDispatch();
  const prefs = useSelector((redux) => redux.app.prefs);
  const [globalPrefs, setGlobalPrefs] = useState({});

  const onChange = (e) => {
    const updatePrefs = (e) => {
      let updatedPrefs = {};
      Object.keys(prefs).forEach((pref) => {
        updatedPrefs[pref] = e.id === pref ? e.value : prefs[pref].value;
      });
      dispatch(actions.updatePrefs(updatedPrefs));
    };
    if (
      prefs[e.id].requiresConfirmation &&
      window.confirm(prefs[e.id].requiresConfirmation)
    ) {
      updatePrefs(e);
    } else if (!prefs[e.id].requiresConfirmation) {
      updatePrefs(e);
    }
  };

  const applyGlobal = () => {
    console.log(globalPrefs);
    const prefsToSave = Object.keys(globalPrefs);
    if (
      prefsToSave.length > 0 &&
      window.confirm(
        `Do you really want to apply the selected settings to every map in the set? This cannot be undone.`
      )
    ) {
      dispatch(actions.updateGlobalPrefs(prefsToSave));
    }
  };

  const updateGLobal = (e) => {
    let updatedPrefs = { ...globalPrefs };
    if (e.target.checked) {
      updatedPrefs[e.target.dataset.id] = true;
    } else {
      delete updatedPrefs[e.target.dataset.id];
    }
    setGlobalPrefs(updatedPrefs);
  };

  return (
    <div style={styles.outerContainer}>
      <WatchForOutsideClick
        onOutsideClick={() => dispatch(actions.uiHide(CONST.UI.PREFERENCES))}
      >
        <div style={styles.innerContainer}>
          <div style={styles.content}>
            {Object.keys(prefs).map((prefKey) => {
              const pref = prefs[prefKey];
              if (pref.isEditable) {
                return (
                  <div key={prefKey} style={styles.prefRow}>
                    <div style={{ width: '20rem' }}>
                      <FormInput
                        id={prefKey}
                        tooltip={pref.tooltip}
                        label={pref.label}
                        type={pref.formType}
                        value={pref.value}
                        onChange={onChange}
                        options={{ selectionOptions: pref.selectionOptions }}
                      />
                    </div>
                    <div style={{ marginLeft: '1rem' }}>
                      {pref.isShareable && (
                        <input
                          onChange={updateGLobal}
                          data-id={prefKey}
                          type="checkbox"
                        />
                      )}
                    </div>
                  </div>
                );
              } else if (pref.isShareable) {
                return (
                  <div key={prefKey} style={styles.prefRow}>
                    <div style={{ width: '20rem' }}>{pref.label}</div>
                    <div style={{ marginLeft: '1rem' }}>
                      {pref.isShareable && (
                        <input
                          onChange={updateGLobal}
                          data-id={prefKey}
                          type="checkbox"
                        />
                      )}
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}

            <div style={styles.prefRow}>
              <div style={{ margin: '0 auto 0 auto' }}>
                <div>
                  <Button onClick={applyGlobal}>Apply To All</Button>
                </div>
              </div>
            </div>

            <Collapsable
              title={
                <div className={styles.sectionHeader}>System Information</div>
              }
              isOpen={true}
            >
              <>
                <SystemInfo />
                <Button
                  onClick={() => {
                    dispatch(actions.exportStandalone());
                  }}
                >
                  Export Config
                </Button>
              </>
            </Collapsable>
          </div>
        </div>
      </WatchForOutsideClick>
    </div>
  );
};

const styles = {
  outerContainer: {
    zIndex: 1,
    position: 'fixed',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: colors.modalBackground
  },
  innerContainer: {
    borderRadius: '0.3rem',
    display: 'flex',
    flexDirection: 'column',
    width: '90vw',
    minWidth: '20rem',
    maxWidth: '40rem',
    height: '80vh',
    maxHeight: '60rem',
    backgroundColor: 'white',
    boxShadow: colors.shadow,
    overflow: 'scroll'
  },
  content: {
    margin: '0 auto',
    padding: '1rem'
  },
  prefRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1rem'
  }
};

export default Preferences;

Preferences.defaultProps = {
  scrollTop: 0,
  showArrow: true,
  resetToTop: false
};

Preferences.propTypes = {
  items: PropTypes.array
};
