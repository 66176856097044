import React from 'react';
import PropTypes from 'prop-types';
import colors from 'colors';
import { TabSet, Tab, FormInput, IconButton } from 'components';
import TriggerConfig from './TriggerConfig';
import Publishing from './Publishing';
import TourConfig from './TourConfig';
import CONST from 'constants.js';
import * as actions from 'state/actions';
import { useDispatch } from 'react-redux';
import WatchForOutsideClick from 'components/WatchForOutsideClick';
import { useSelector } from 'react-redux';
import { FIELDTYPE } from 'components/FormInput';

export const PROXTYPE = {
  START: -1,
  END: -2,
  ANY: -3
};

export const DIRECTION = {
  BOTH: 0,
  INCOMING: 1,
  OUTGOING: 2
};

const AddProximityTrigger = () => {
  const dispatch = useDispatch();
  const state = useSelector((redux) => redux);
  //const availablePoi = useSelector((redux) => redux.poi.all);
  let formValues = {
    distance: 0,
    poiId: PROXTYPE.START,
    direction: DIRECTION.BOTH
  };
  const onChange = (e) => {
    formValues = { ...formValues, [e.id]: e.value };
  };
  /*
  const selectionOptions = [
    { name: 'START', id: PROXTYPE.START },
    { name: 'ANY', id: PROXTYPE.ANY },
    ...availablePoi,
    { name: 'END', id: PROXTYPE.END }
  ];*/
  return (
    <div
      style={{
        margin: '0',
        padding: '1rem',
        backgroundColor: 'rgb(191, 207, 212)',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <div>At</div>
      <div style={{ marginLeft: '1rem' }}>
        <FormInput
          id="distance"
          style={{ input: { width: '6rem' } }}
          type={FIELDTYPE.TEXT}
          options={{ validation: { type: 'integer', min: 0 } }}
          onChange={onChange}
        />
      </div>
      {/* 
      <div style={{ marginLeft: '1rem' }}>meters from</div>
      <div style={{ marginLeft: '1rem', flexGrow: '1' }}>
        <FormInput
          id="poiId"
          style={{ input: { width: '6rem' } }}
          type={FIELDTYPE.SELECT}
          options={{
            selectionOptions
          }}
          onChange={onChange}
        />
      </div>*/}
      <div style={{ marginLeft: '1rem', flexGrow: '1' }}>
        <FormInput
          id="direction"
          style={{ input: { width: '6rem' } }}
          type={FIELDTYPE.SELECT}
          options={{
            selectionOptions: [
              { name: 'Both', id: DIRECTION.BOTH },
              { name: 'Incoming', id: DIRECTION.INCOMING },
              { name: 'Outgoing', id: DIRECTION.OUTGOING }
            ]
          }}
          onChange={onChange}
        />
      </div>
      <div>
        <IconButton
          metaData={{
            id: null,
            media_id: null
          }}
          isBorderless={true}
          icon={'plus'}
          onClick={() => {
            dispatch(
              actions.createProximity({
                map_id: state.app.id,
                name: `${formValues.distance} meters  ${
                  formValues.direction === 1 ? '(INCOMING)' : '(OUTGOING)'
                }`,
                options: { ...formValues },
                sortorder: 0
              })
            );
          }}
        />
      </div>
    </div>
  );
};

const AudioTourConfig = () => {
  const dispatch = useDispatch();
  const availablePoi = useSelector((redux) => redux.poi.all);
  const title = useSelector((redux) => redux.app.prefs.title.value);
  const availableProximity = useSelector((redux) => redux.proximity.all);
  return (
    <div style={styles.outerContainer}>
      <WatchForOutsideClick
        onOutsideClick={() => dispatch(actions.uiHide(CONST.UI.AUDIO_TOUR))}
      >
        <div style={styles.container}>
          <TabSet selected={0} styles={styles.tabs}>
            <Tab icon={'cog'} label={'Config'}>
              <div style={{ paddingTop: '3rem' }}>
                <div style={styles.pageTitle}>{title}</div>
                <TourConfig />
              </div>
            </Tab>
            <Tab icon={'map-marker-alt'} label={'Location'}>
              <div style={{ paddingTop: '3rem' }}>
                <div style={styles.pageTitle}>
                  ({availablePoi?.length}) Location Triggers | {title}
                </div>
                <TriggerConfig triggerType={CONST.TRIGGER.LOCATION} />
              </div>
            </Tab>
            <Tab icon={'street-view'} label={'Proximity'}>
              <div style={{ paddingTop: '3rem' }}>
                <div style={styles.pageTitle}>
                  ({availableProximity.length}) Proximity Triggers | {title}
                </div>
                <AddProximityTrigger />
                <TriggerConfig
                  triggerType={CONST.TRIGGER.PROXIMITY}
                  onDelete={(id) => {
                    if (
                      window.confirm(
                        'Do you really want to remove this proximity trigger? This cannot be undone.'
                      )
                    )
                      dispatch(actions.deleteProximity(id));
                  }}
                />
              </div>
            </Tab>
            <Tab icon={'upload'} label={'Publishing'}>
              <div style={{ paddingTop: '3rem' }}>
                <Publishing />
              </div>
            </Tab>
          </TabSet>
        </div>
      </WatchForOutsideClick>
    </div>
  );
};

const styles = {
  pageTitle: { margin: '0 0 1rem 1rem', fontSize: '1.2rem' },
  outerContainer: {
    zIndex: 1,
    position: 'fixed',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1.5rem 0 0 0',
    height: '100vh',
    width: '100vw',
    backgroundColor: colors.modalBackground
  },
  container: {
    borderRadius: '0.3rem',
    fontFamily: colors.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    width: '70vw',
    maxWidth: '60rem',
    padding: '0',
    margin: 'auto',
    backgroundColor: '#ffffff',
    height: '90vh',
    boxShadow: colors.shadow,
    overflow: 'hidden',
    overflowY: 'scroll'
  },
  tabs: colors.tabs
};

export default AudioTourConfig;

AudioTourConfig.defaultProps = {
  scrollTop: 0,
  showArrow: true,
  resetToTop: false
};

AudioTourConfig.propTypes = {
  items: PropTypes.array
};
