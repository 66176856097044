import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import defaultStyle from './defaultStyle.js';
import OptionButtons from './OptionButtons';
import Mousetrap from 'mousetrap';

const Duration = (props) => {
  const styles = {
    ...defaultStyle,
    input: { ...defaultStyle.input, ...props.style?.input },
    label: { ...defaultStyle.label, ...props.style?.label }
  };
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const minutesRef = useRef();
  const secondsRef = useRef();

  const parseValues = (min, sec) => {
    let minutes = parseInt(min, 10);
    let seconds = parseInt(sec, 10);
    minutes = isNaN(minutes) ? 0 : minutes;
    seconds = isNaN(seconds) ? 0 : seconds;
    minutes = seconds > 60 ? 0 : minutes;
    let totalSeconds = seconds + minutes * 60;
    minutes = Math.floor(totalSeconds / 60);
    seconds = totalSeconds - minutes * 60;
    setMinutes(minutes.toString().padStart(2, '0'));
    setSeconds(seconds.toString().padStart(2, '0'));
  };

  const onChange = () => {
    parseValues(minutesRef.current.value, secondsRef.current.value);
    let seconds =
      parseInt(minutesRef.current.value, 10) * 60 +
      parseInt(secondsRef.current.value, 10);
    props.onChange({
      id: props.id,
      value: seconds * 1000,
      data: {
        ...props.options.data,
        minutes: minutesRef.current.value,
        seconds: secondsRef.current.value
      }
    });
  };

  const handleArrow = (e) => {
    let ref = e.target.dataset.id === 'minutes' ? minutesRef : secondsRef;
    let currentVal = parseInt(ref.current.value, 10);
    currentVal = isNaN(currentVal) ? 0 : currentVal;
    ref.current.value =
      e.key === 'ArrowDown'
        ? currentVal - 1 >= 0
          ? currentVal - 1
          : 0
        : currentVal + 1;
    onChange(e);
  };

  useEffect(() => {
    let sec = props.value / 1000;
    let min = sec >= 60 ? Math.floor(sec / 60) : 0;
    sec = min > 0 ? sec - min * 60 : sec;
    parseValues(min, sec);
  }, [props.value]);

  return (
    <label style={styles.label}>
      <div
        title={props.tooltip}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div style={{ flexGrow: 1 }}>
          {props.label === null ? '' : props.label}
        </div>
        <div>
          <OptionButtons {...props} />
        </div>
      </div>
      <div style={props.isPublished ? null : styles.private}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <div style={{ width: '3rem' }}>
            <input
              className="mousetrap"
              onFocus={() => Mousetrap.bind(['up', 'down'], handleArrow)}
              onBlur={() => Mousetrap.unbind(['up', 'down'], handleArrow)}
              ref={minutesRef}
              placeholder={'MM'}
              style={styles.input}
              type="text"
              autoComplete="off"
              disabled={props.isDisabled || props.isLocked}
              data-id={'minutes'}
              value={minutes}
              onChange={onChange}
            />
          </div>
          <div style={{ margin: '0 .3rem 0 .6rem', textAlign: 'center' }}>
            :
          </div>
          <div style={{ width: '3rem' }}>
            <input
              className="mousetrap"
              onFocus={() => Mousetrap.bind(['up', 'down'], handleArrow)}
              onBlur={() => Mousetrap.unbind(['up', 'down'], handleArrow)}
              ref={secondsRef}
              placeholder={'SS'}
              style={styles.input}
              type="text"
              autoComplete="off"
              disabled={props.isDisabled || props.isLocked}
              data-id={'seconds'}
              value={seconds}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </label>
  );
};

Duration.defaultProps = {
  isPublished: true,
  onChange: () => {}
};

Duration.propTypes = {
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isPublished: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.object,
  tooltip: PropTypes.any,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Duration;
