// For weather:
// list of conditionCodes: https://openweathermap.org/weather-conditions
import perceivedTimeOfDay from './perceivedTimeOfDay.js';
import perceivedWeather from './perceivedWeather.js';

export default {
  nextID: -1,
  items: [],
  config: {},
  needsInsert: [],
  needsUpdate: [],
  needsRemoval: [],
  trackColors: [
    'rgba(241, 143, 1, 1)',
    'rgba(4, 139, 168, 1)',
    'rgba(46, 64, 87, 1)',
    'rgba(153, 194, 77, 1)',
    'rgba(47, 45, 46, 1)',
    'rgba(255, 175, 135, 1)',
    'rgba(255, 142, 114, 1)',
    'rgba(237, 106, 94, 1)',
    'rgba(76, 224, 179, 1)',
    'rgba(55, 119, 113, 1)'
  ],
  perceivedTimeOfDay,
  perceivedWeather
};
