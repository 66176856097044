import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import { missingValue } from 'utility';
import PropTypes from 'prop-types';

class TargetBox extends Component {
  render() {
    const isActive = this.props.canDrop && this.props.isOver;

    const dropTarget = this.props.connectDropTarget(
      <span
        onContextMenu={this.props.onContextMenu}
        {...this.props.dataset}
        style={this.props.style}
        onClick={this.onClick}
        className={
          isActive
            ? this.props.styles.dropZone_active
            : this.props.styles.dropZone
        }
      >
        {this.props.children}

        {!this.props.children &&
          isActive &&
          !missingValue(this.props.previewImage) && (
            <span className={this.props.styles.dragMessage}>
              {this.props.dropMessage}
            </span>
          )}

        {!this.props.children &&
          !isActive &&
          missingValue(this.props.previewImage) && (
            <span className={this.props.styles.dragMessage}>
              {this.props.dropMessage}
            </span>
          )}
      </span>
    );
    return <React.Fragment>{dropTarget}</React.Fragment>;
  }
}

TargetBox.propTypes = {
  onContextMenu: PropTypes.func,
  style: PropTypes.object,
  hasButton: PropTypes.bool,
  previewImage: PropTypes.string,
  children: PropTypes.node,
  onDrop: PropTypes.func,
  styles: PropTypes.object,
  dropMessage: PropTypes.string,
  allowClickToZoom: PropTypes.bool,
  dataset: PropTypes.object,
  connectDropTarget: PropTypes.func,
  canDrop: PropTypes.bool,
  isOver: PropTypes.bool
};

export default DropTarget(
  (props) => props.accepts,
  {
    drop(props, monitor) {
      if (props.onDrop) {
        props.onDrop(props, monitor);
      }
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(TargetBox);
