//https://www.pluralsight.com/guides/how-to-use-contenteditable-elements-in-a-react-app
module.exports = {
  disableNewlines: (e) => {
    const keyCode = e.keyCode || e.which;

    if (keyCode === 13) {
      e.returnValue = false;
      if (e.preventDefault) e.preventDefault();
    }
  },
  pastePlainText: (e) => {
    e.preventDefault();

    const text = e.clipboardData.getData('text/plain');
    document.execCommand('insertHTML', false, text);
  },
  highlightAll: () => {
    setTimeout(() => {
      document.execCommand('selectAll', false, null);
    }, 0);
  },
  handleUpdate: (e) => {
    console.log(e);
  }
};
