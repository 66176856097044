import PropTypes from 'prop-types';
import * as React from 'react';
import styles from './ProgressBar.module.css';
const ProgressBar = (props) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.stripes}
        style={{
          width: `${props.progress}%`,
          height: '1rem'
        }}
      />
    </div>
  );
};

ProgressBar.propTypes = {
  progress: PropTypes.any
};
ProgressBar.displayName = 'ProgressBar';
export default ProgressBar;
