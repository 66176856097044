/*
Core sunrise/set calculations from:
https://github.com/Triggertrap/sun-js

See also:
Methods for calculating solar position and day length including computer programs and subroutines
https://researchlibrary.agric.wa.gov.au/cgi/viewcontent.cgi?article=1122&context=rmtr
*/
import math from 'utility/math';
import moment from 'moment';
import 'moment-timezone';
import ts from '@mapbox/timespace';

const hoursBetweenTimes = (time1, time2, timezone) => {
  if (!timezone) return 0;
  const m = moment;
  const date = moment(time1).format('MM/DD/YYYY');
  const a = m(`${date} ${m(time1).tz(timezone).format('HH:mm')}`);
  const b = m(`${date} ${m(time2).tz(timezone).format('HH:mm')}`);
  return b.diff(a, 'hours', true);
};

const sun = {
  PERCEIVED_TIME_OF_DAY: {
    EARLY_MORNING: 'EARLY_MORNING',
    MORNING: 'MORNING',
    DAY: 'DAY',
    EVENING: 'EVENING',
    NIGHT: 'NIGHT'
  },

  info: ({ date, coordinate }, zenith) => {
    if (!coordinate) return null;
    let time = ts.getFuzzyLocalTimeFromPoint(Date.now(), [
      coordinate.lng,
      coordinate.lat
    ]);
    if (!time) return null;
    var timezone = time?.tz() ? time.tz() : null;
    let sunrise = moment(sun.sunrise({ date, coordinate }, zenith));
    let sunset = moment(sun.sunset({ date, coordinate }, zenith));
    const hoursOfDaylight = hoursBetweenTimes(sunrise, sunset, timezone);

    let daySpan = hoursOfDaylight / 3;
    let DAY_MIDDLE = moment(sunrise)
      .tz(timezone)
      .add(moment.duration(hoursOfDaylight / 2, 'hours'));
    let DAY_START = moment(DAY_MIDDLE).subtract(
      moment.duration(daySpan / 2, 'hours')
    );
    let DAY_END = moment(DAY_MIDDLE).add(moment.duration(daySpan / 2, 'hours'));
    let pTod = {};
    pTod[sun.PERCEIVED_TIME_OF_DAY.EARLY_MORNING] = {
      begins: moment(sunrise).tz(timezone).startOf('day'),
      ends: sunrise,
      duration: hoursBetweenTimes(
        moment(sunrise).tz(timezone).startOf('day'),
        sunrise.tz(timezone),
        timezone
      )
    };
    pTod[sun.PERCEIVED_TIME_OF_DAY.MORNING] = {
      begins: sunrise,
      ends: DAY_START,
      duration: hoursBetweenTimes(sunrise, DAY_START, timezone)
    };
    pTod[sun.PERCEIVED_TIME_OF_DAY.DAY] = {
      begins: DAY_START,
      ends: DAY_END,
      duration: hoursBetweenTimes(DAY_START, DAY_END, timezone)
    };
    pTod[sun.PERCEIVED_TIME_OF_DAY.EVENING] = {
      begins: DAY_END,
      ends: sunset,
      duration: hoursBetweenTimes(DAY_END, sunset, timezone)
    };
    pTod[sun.PERCEIVED_TIME_OF_DAY.NIGHT] = {
      begins: sunset,
      ends: moment(sunrise).endOf('day'),
      duration: hoursBetweenTimes(
        sunset,
        moment(sunrise).endOf('day'),
        timezone
      )
    };

    let retval = {
      sunrise: sunrise.tz(timezone),
      sunset: sunset.tz(timezone),
      hoursOfDaylight: hoursOfDaylight.toFixed(2),
      perceived: pTod,
      timezone
    };

    const isTimeBetween = function (aStartTime, anEndTime, aCurrTime) {
      // you may pass in aCurrTime or use the *actual* current time
      var currentTime = !aCurrTime ? moment() : moment(aCurrTime, 'HH:mm a');
      var startTime = moment(aStartTime, 'HH:mm a');
      var endTime = moment(anEndTime, 'HH:mm a');

      if (startTime.hour() >= 12 && endTime.hour() <= 12) {
        endTime.add(1, 'days'); // handle spanning days
      }

      var isBetween = currentTime.isBetween(startTime, endTime);
      return isBetween;
    };

    retval.perceivedTimeOfDay = () => {
      if (
        isTimeBetween(
          pTod.EARLY_MORNING.begins.format('HH:mm a'),
          pTod.EARLY_MORNING.ends.format('HH:mm a'),
          time.format('HH:mm a')
        )
      ) {
        return sun.PERCEIVED_TIME_OF_DAY.EARLY_MORNING;
      } else if (
        isTimeBetween(
          pTod.MORNING.begins.format('HH:mm a'),
          pTod.MORNING.ends.format('HH:mm a'),
          time.format('HH:mm a')
        )
      ) {
        return sun.PERCEIVED_TIME_OF_DAY.MORNING;
      } else if (
        isTimeBetween(
          pTod.DAY.begins.format('HH:mm a'),
          pTod.DAY.ends.format('HH:mm a'),
          time.format('HH:mm a')
        )
      ) {
        return sun.PERCEIVED_TIME_OF_DAY.DAY;
      } else if (
        isTimeBetween(
          pTod.EVENING.begins.format('HH:mm a'),
          pTod.EVENING.ends.format('HH:mm a'),
          time.format('HH:mm a')
        )
      ) {
        return sun.PERCEIVED_TIME_OF_DAY.EVENING;
      } else if (
        isTimeBetween(
          pTod.NIGHT.begins.format('HH:mm a'),
          pTod.NIGHT.ends.format('HH:mm a'),
          time.format('HH:mm a')
        )
      ) {
        return sun.PERCEIVED_TIME_OF_DAY.NIGHT;
      } else {
        console.log(time);
        console.warn('Failed to determine TOD!');
        return 'ERROR';
      }
    };
    return retval;
  },

  sunrise: ({ date, coordinate }, zenith) => {
    return sun.sunriseSet(date, coordinate.lat, coordinate.lng, true, zenith);
  },

  sunset: ({ date, coordinate }, zenith) => {
    return sun.sunriseSet(date, coordinate.lat, coordinate.lng, false, zenith);
  },

  sunriseSet: (date, latitude, longitude, sunrise, zenith = 90.8333) => {
    const DEGREES_PER_HOUR = 360 / 24;
    let hoursFromMeridian = longitude / DEGREES_PER_HOUR,
      dayOfYear = sun.getDayOfYear(date),
      approxTimeOfEventInDays,
      sunMeanAnomaly,
      sunTrueLongitude,
      ascension,
      rightAscension,
      lQuadrant,
      raQuadrant,
      sinDec,
      cosDec,
      localHourAngle,
      localHour,
      localMeanTime,
      time;

    if (sunrise) {
      approxTimeOfEventInDays = dayOfYear + (6 - hoursFromMeridian) / 24;
    } else {
      approxTimeOfEventInDays = dayOfYear + (18.0 - hoursFromMeridian) / 24;
    }

    sunMeanAnomaly = 0.9856 * approxTimeOfEventInDays - 3.289;

    sunTrueLongitude =
      sunMeanAnomaly +
      1.916 * math.sinDeg(sunMeanAnomaly) +
      0.02 * math.sinDeg(2 * sunMeanAnomaly) +
      282.634;
    sunTrueLongitude = math.mod(sunTrueLongitude, 360);

    ascension = 0.91764 * math.tanDeg(sunTrueLongitude);
    rightAscension = (360 / (2 * Math.PI)) * Math.atan(ascension);
    rightAscension = math.mod(rightAscension, 360);

    lQuadrant = Math.floor(sunTrueLongitude / 90) * 90;
    raQuadrant = Math.floor(rightAscension / 90) * 90;
    rightAscension = rightAscension + (lQuadrant - raQuadrant);
    rightAscension /= DEGREES_PER_HOUR;

    sinDec = 0.39782 * math.sinDeg(sunTrueLongitude);
    cosDec = math.cosDeg(math.asinDeg(sinDec));
    let cosLocalHourAngle =
      (math.cosDeg(zenith) - sinDec * math.sinDeg(latitude)) /
      (cosDec * math.cosDeg(latitude));

    localHourAngle = math.acosDeg(cosLocalHourAngle);

    if (sunrise) {
      localHourAngle = 360 - localHourAngle;
    }

    localHour = localHourAngle / DEGREES_PER_HOUR;

    localMeanTime =
      localHour + rightAscension - 0.06571 * approxTimeOfEventInDays - 6.622;

    time = localMeanTime - longitude / DEGREES_PER_HOUR;
    time = math.mod(time, 24);

    date = new Date(date);
    let midnight = new Date(0);
    midnight.setUTCFullYear(date.getUTCFullYear());
    midnight.setUTCMonth(date.getUTCMonth());
    midnight.setUTCDate(date.getUTCDate());

    let milli = midnight.getTime() + time * 60 * 60 * 1000;
    return new Date(milli);
  },

  getDayOfYear: (date) => {
    date = new Date(date);
    let onejan = new Date(date.getFullYear(), 0, 1);
    return Math.ceil((date - onejan) / 86400000);
  }
};
export default sun;
