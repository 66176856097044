import React from 'react';
import PropTypes from 'prop-types';
import { WatchForOutsideClick } from 'components';

export const Website = ({
  caption,
  url,
  currentPoi,
  onOutsideClick,
  styles
}) => {
  styles = {
    ...styles,
    frame: {
      border: 0,
      width: '100%',
      height: '100%',
      margin: 0,
      display: 'block'
    },
    innerContainer: {
      ...styles.innerContainer,
      backgroundColor: 'white'
    }
  };

  return (
    <div style={styles.outerContainer}>
      <WatchForOutsideClick onOutsideClick={onOutsideClick}>
        <div style={styles.innerContainer}>
          <div style={styles.header}>
            <div style={styles.backlink} onClick={onOutsideClick}>
              &lt; Back
            </div>
            <div style={styles.titleAndCaption}>
              <div style={styles.title}>{currentPoi.name}</div>
              {caption && caption.length > 0 && (
                <div style={styles.caption}>{caption}</div>
              )}
            </div>
          </div>
          <iframe src={url} style={styles.frame} />
        </div>
      </WatchForOutsideClick>
    </div>
  );
};
export default Website;

Website.defaultProps = {};
Website.propTypes = {
  onOutsideClick: PropTypes.func
};
