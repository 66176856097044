import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './MediaModal.module.css';
import { WatchForOutsideClick } from 'components';
class MediaModal extends Component {
  render() {
    return (
      <div className={styles.modal_modal}>
        <div className={styles.modalbox}>
          <WatchForOutsideClick onOutsideClick={this.props.onOutsideClick}>
            {this.props.children}
          </WatchForOutsideClick>
        </div>
      </div>
    );
  }
}
export default MediaModal;

MediaModal.defaultProps = {
  confirmLabel: 'Ok',
  cancelLabel: 'Cancel',
  outsideClickConfirm: false
};

MediaModal.propTypes = {
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onOutsideClick: PropTypes.func,
  children: PropTypes.node,
  outsideClickConfirm: PropTypes.bool
};
