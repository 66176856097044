import React, { Component } from 'react';
import styles from './TimeFilter.module.css';
import PropTypes from 'prop-types';
import moment from 'moment';
import memoizeOne from 'memoize-one';

class TimeFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      leftPercent: this.props.options.leftPercent,
      rightPercent: this.props.options.rightPercent,
      timelineLength: this.calculateTimelineSize()
    };

    this.isDragging = false;
    this.marginPx = 20;
    this.handleWidthPx = 7;
    this.markerWidthPx = 7;

    this.labelLookup = [];
    this.selectedHandle = null;

    const timelinePOI = this.props.poi.all;
    let earliestYear = Number.MAX_SAFE_INTEGER;
    let latestYear = 0;
    let emptyYear = {};
    for (let idx = 1; idx <= 12; idx++) {
      emptyYear[idx] = [];
    }

    this.timeline = {};
    timelinePOI.forEach((poi) => {
      if (moment(poi.date).isValid()) {
        let year = parseInt(moment(poi.date).format('YYYY'));
        let month = parseInt(moment(poi.date).format('MM'));
        latestYear = year > latestYear ? year : latestYear;
        earliestYear = year < earliestYear ? year : earliestYear;
        if (!this.timeline[year]) {
          latestYear = year;
          this.timeline[year] = emptyYear;
        }
        this.timeline[year][month].push(poi);
      }
    });
    this.timeline[latestYear + 1] = emptyYear;

    this.dateInfo = {
      start: moment(`${earliestYear}-01-01`),
      end: moment(`${latestYear}-12-31`),
      numberOfDays: moment(`${latestYear}-12-31`).diff(
        moment(`${earliestYear}-01-01`),
        'days'
      )
    };

    window.addEventListener('resize', this.onResize);

    this.m_updateSliders = memoizeOne((left, right) => {
      if (
        left !== this.state.leftPercent ||
        right !== this.state.rightPercent
      ) {
        this.setState({
          leftPercent: left,
          rightPercent: right
        });
        if (!(left === 0 && right === 100)) {
          this.props.onOptionUpdate({
            leftPercent: left,
            rightPercent: right,
            start: moment(this.dateAtPercentOnTimeline(left)).toISOString(),
            end: moment(this.dateAtPercentOnTimeline(right)).toISOString()
          });
        }
      }
    });
  }

  componentDidUpdate = () => {
    this.m_updateSliders(
      this.props.options.leftPercent,
      this.props.options.rightPercent
    );
  };

  dateAtPercentage = (percentage) => {
    if (this.dateInfo) {
      let offsetDays = this.dateInfo.numberOfDays * (percentage / 100);
      return moment(this.dateInfo.start, 'DD-MM-YYYY').add(offsetDays, 'days');
    }
    return null;
  };

  componentDidMount = () => {
    this.onResize();
  };

  onResize = () => {
    this.setState({
      timelineLength: this.calculateTimelineSize()
    });
  };

  positionFromPercentage = (percentage) => {
    let position = this.calculateTimelineSize() * (0.01 * percentage);
    position = position < 0 ? 0 : position;
    position =
      position > this.calculateTimelineSize()
        ? this.calculateTimelineSize()
        : position;
    return position;
  };

  render() {
    let markers = [];
    let numberOfYearsRepresented = Object.entries(this.timeline).length;
    if (numberOfYearsRepresented > 0) {
      Object.keys(this.timeline).map((year, idx) => {
        let percent = (idx / (numberOfYearsRepresented - 1)) * 100;
        let leftMargin =
          this.state.timelineLength * (0.01 * percent) - this.markerWidthPx;
        leftMargin = leftMargin < 0 ? 0 : leftMargin;

        let nextMarkerAt =
          this.state.timelineLength *
            (0.01 * (((idx + 1) / (numberOfYearsRepresented - 1)) * 100)) -
          this.markerWidthPx;

        let monthSpacing = (nextMarkerAt - leftMargin) / 13;
        markers.push(
          <div
            key={`marker_${year}`}
            className={styles.marker}
            style={{ marginLeft: leftMargin }}
          >
            <div className={styles.label}>{year}</div>
          </div>
        );

        if (Object.entries(this.timeline[year]).length > 0) {
          for (let month = 1; month <= 12; month++) {
            let monthMargin = leftMargin + month * monthSpacing;
            monthMargin += 5;
            let poiCount = Object.entries(this.timeline[year][month]).length;
            let markerHeight = poiCount * 2;
            markerHeight = markerHeight <= 0 ? 2 : markerHeight;

            let monthString = month.toString().padStart(2, '0');
            this.labelLookup[monthMargin] = moment(
              `${year}-${monthString}-01`
            ).format('MMMM YYYY');

            markers.push(
              <div
                key={`marker_${year}_${month}`}
                className={styles.monthMarker}
                style={{ marginLeft: monthMargin, height: markerHeight }}
              />
            );
          }
        }
        return true;
      });
    }

    return (
      <div
        onMouseDown={this.onContainerClick}
        onTouchStart={this.onContainerClick}
        className={styles.container}
        onDrag={this.onMouseMove}
        onTouchMove={this.onMouseMove}
        onMouseMove={this.onMouseMove}
        onTouchEnd={this.onMouseUp}
        onMouseUp={this.onMouseUp}
        onMouseLeave={this.onMouseLeave}
        onTouchCancel={this.onMouseUp}
        style={{ padding: `0 ${this.marginPx}px 0 ${this.marginPx}px` }}
      >
        <div
          id="left"
          className={styles.handleLeft}
          onTouchStart={this.onHandleClick}
          onMouseDown={this.onHandleClick}
          style={{
            marginLeft: this.positionFromPercentage(this.state.leftPercent)
          }}
        >
          <div className={styles.handleLabel}>
            {moment(
              this.dateAtPercentOnTimeline(this.state.leftPercent)
            ).format(this.props.dateFormat)}
          </div>
        </div>

        {markers}

        <div className={styles.timeline} />
        <div
          className={styles.timelineInner}
          style={{
            width:
              this.positionFromPercentage(this.state.rightPercent) -
              this.positionFromPercentage(this.state.leftPercent),
            marginLeft: this.positionFromPercentage(this.state.leftPercent)
          }}
        />
        <div
          id="right"
          className={styles.handleRight}
          onTouchStart={this.onHandleClick}
          onMouseDown={this.onHandleClick}
          style={{
            marginLeft: this.positionFromPercentage(this.state.rightPercent)
          }}
        >
          <div className={styles.handleLabel}>
            {moment(
              this.dateAtPercentOnTimeline(this.state.rightPercent)
            ).format(this.props.dateFormat)}
          </div>
        </div>
      </div>
    );
  }

  calculateTimelineSize = () => {
    var width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;
    const size = width - this.marginPx * 2 - this.handleWidthPx;
    return isNaN(size) ? 0 : size;
  };

  onMouseMove = (e) => {
    if (this.isDragging) {
      e.stopPropagation();
      this.onContainerClick(e);
    }
  };

  onMouseLeave = (e) => {
    this.onMouseUp(e);
  };

  onMouseUp = () => {
    this.isDragging = false;
    this.props.onOptionUpdate({
      leftPercent: this.state.leftPercent,
      rightPercent: this.state.rightPercent,
      start: moment(
        this.dateAtPercentOnTimeline(this.state.leftPercent)
      ).toISOString(),
      end: moment(
        this.dateAtPercentOnTimeline(this.state.rightPercent)
      ).toISOString()
    });
  };

  onHandleClick = (e) => {
    e.stopPropagation();
    this.isDragging = true;
    let startX = e.touches ? e.touches[0].clientX : e.clientX;
    this.selectedHandle = e.target.id;
    this.startX = startX;
  };

  onContainerClick = (e) => {
    e.stopPropagation();
    let leftPos = this.positionFromPercentage(this.props.options.leftPercent);
    let rightPos = this.positionFromPercentage(this.props.options.rightPercent);
    let clientX = e.touches ? e.touches[0].clientX : e.clientX;
    let isLeft = clientX <= leftPos + (rightPos - leftPos) / 2;
    let newX = clientX;
    newX =
      clientX > this.calculateTimelineSize()
        ? this.calculateTimelineSize()
        : clientX;
    newX = newX - 20;

    let newPercentage = (newX / this.calculateTimelineSize()) * 100;

    let offset = 2;
    newPercentage = isLeft
      ? newPercentage < this.props.options.rightPercent - offset
        ? newPercentage
        : this.props.options.rightPercent - offset
      : newPercentage > this.props.options.leftPercent + offset
      ? newPercentage
      : this.props.options.leftPercent + offset;

    this.setState({
      [isLeft ? 'leftPercent' : 'rightPercent']: newPercentage
    });
  };

  dateAtPercentOnTimeline = (percent) => {
    return moment(this.dateAtPercentage(percent)).toISOString();
  };
}

export default TimeFilter;

TimeFilter.defaultProps = {
  dateFormat: 'MM/DD/YY'
};

TimeFilter.propTypes = {
  dateFormat: PropTypes.string,
  isPinned: PropTypes.bool,
  onOptionUpdate: PropTypes.func.isRequired,
  onPin: PropTypes.func,
  options: PropTypes.object.isRequired,
  poi: PropTypes.object.isRequired
};
