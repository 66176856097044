import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './UserManager.module.css';
import { Dialog, Modal, IconButton } from 'components/';
import { connect } from 'react-redux';
import * as actions from 'state/actions';
import CONST from 'constants.js';
import Edit from 'components/UserSelfManager/Edit';

class UserManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: {},
      editor: { isVisible: false }
    };
  }

  componentDidMount() {
    if (this.props.reduxState.app.users.length === 0) {
      this.props.dispatch(actions.getUsers());
    }
  }

  render = () => {
    let error_message;
    if (this.props.reduxState.app.users?.length === 0)
      error_message = <div>Loading...</div>;
    if (!this.props.reduxState.app.users.length)
      error_message = (
        <div>
          FATAL ERROR: Cannot get userlist <br />
          <br />
          {JSON.stringify(this.props.reduxState.app.users)}
        </div>
      );
    if (error_message) {
      return (
        <Modal
          scrollable={false}
          onOutsideClick={() =>
            this.props.dispatch(actions.uiHide(CONST.UI.USER_MANAGER))
          }
          className={styles.userModal}
        >
          {' '}
          <div className={styles.container}>{error_message}</div>
        </Modal>
      );
    }

    return (
      <Modal
        scrollable={false}
        onOutsideClick={() =>
          this.props.dispatch(actions.uiHide(CONST.UI.USER_MANAGER))
        }
        className={styles.userModal}
      >
        {this.state.editor.isVisible && (
          <Edit
            userInfo={this.state.editor.userInfo}
            onCancel={() =>
              this.setState({
                editor: { ...this.state.editor, isVisible: false }
              })
            }
            onChangePassword={this.onChangePassword}
            onUpdateUser={this.onUpdateUser}
            onAddUser={this.onAddUser}
          />
        )}
        {this.state.dialog.isVisible && <Dialog {...this.state.dialog} />}
        <div className={styles.container}>
          <div className={styles.colRight}>
            <IconButton
              tooltip="Add User"
              icon="plus"
              onClick={() => {
                this.setState({
                  editor: {
                    ...this.state.editor,
                    userInfo: null,
                    isVisible: true
                  }
                });
              }}
            />
          </div>
          <hr />

          <div className={styles.gridContainer}>
            {this.props.reduxState.app.users?.map((user) => {
              return (
                <div key={user.email}>
                  <div className={styles.row}>
                    <div style={{ width: '3rem', marginRight: '1rem' }}>
                      <div className={styles.avatarImage}>
                        <img alt={styles.email} src={user.picture} />
                      </div>
                    </div>
                    <div style={{ marginRight: '.5rem', width: '2rem' }}>
                      {user.role === CONST.ROLE.SUPERUSER && (
                        <span
                          style={{ marginLeft: '.5rem', color: '#54818c' }}
                          title="Superuser"
                          className="fas fa-bolt"
                        />
                      )}
                    </div>
                    <div style={{ marginRight: '.5rem', minWidth: '5rem' }}>
                      {user.name}
                    </div>
                    <div style={{ marginRight: '.5rem', minWidth: '11rem' }}>
                      <span className={styles.email}>
                        {!user.is_validated && (
                          <span
                            style={{ marginLeft: '.3rem', color: 'orange' }}
                            title="Not Validated"
                            className="fas fa-exclamation-circle"
                          />
                        )}
                        {user.is_validated && (
                          <span
                            style={{
                              marginLeft: '.3rem',
                              color: '#54818c'
                            }}
                            title="Validated"
                            className="fas fa-check"
                          />
                        )}{' '}
                        {user.email}
                      </span>
                    </div>

                    <div
                      className={styles.colRight}
                      style={{ minWidth: '11rem' }}
                    >
                      {!user.is_validated && (
                        <IconButton
                          tooltip="Send Validation Request"
                          icon="exclamation-circle"
                          metaData={{ id: user.id }}
                          onClick={this.onValidate}
                        />
                      )}
                      <IconButton
                        tooltip="Reset Password"
                        icon="redo-alt"
                        metaData={{ id: user.email }}
                        onClick={this.onReset}
                      />
                      <IconButton
                        tooltip="Edit User"
                        icon="pen"
                        metaData={{ id: user.id }}
                        onClick={() => {
                          this.setState({
                            editor: {
                              ...this.state.editor,
                              userInfo: user,
                              isVisible: true
                            }
                          });
                        }}
                      />
                      {this.props.reduxState.user.claims.id !== user.id && (
                        <IconButton
                          tooltip="Remove User"
                          icon="minus"
                          metaData={{ id: user.id }}
                          onClick={this.onRemove}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Modal>
    );
  };

  onChangePassword = (payload, opt) => {
    this.props.dispatch(actions.updateUserWithPasswordCheck({ payload, opt }));
  };

  onUpdateUser = (payload, opt) => {
    this.props.dispatch(actions.updateUser({ payload, opt }));
  };

  onAddUser = (payload, opt) => {
    this.props.dispatch(actions.createUser({ payload, opt }));
  };

  onRemove = (e) => {
    const user = JSON.parse(e.currentTarget.dataset.meta);
    this.setState({
      dialog: {
        isVisible: true,
        dialogMessage: `Do you really want to delete this user? This action cannot be undone.`,
        confirmLabel: 'Ok',
        onCancel: () => {
          this.setState({
            dialog: {
              ...this.state.dialog,
              isVisible: false
            }
          });
        },
        onConfirm: () => {
          this.props.dispatch(actions.deleteUser({ id: user.id }));
          this.setState({
            dialog: {
              ...this.state.dialog,
              isVisible: false
            },
            selectedID: null,
            currentMedia: null
          });
        }
      }
    });
  };

  onValidate = (e) => {
    const user = JSON.parse(e.currentTarget.dataset.meta);
    window.alertBar('Validation request sent by email.');
    this.props.dispatch(actions.userRequestValidation({ id: user.id }));
  };

  onReset = (e) => {
    const user = JSON.parse(e.currentTarget.dataset.meta);
    window.alertBar('Password reset and emailed to user.');
    this.props.dispatch(actions.userPassReset({ id: user.id }));
  };
}
export default connect((reduxState) => {
  return {
    reduxState
  };
})(UserManager);

UserManager.defaultProps = {};

UserManager.propTypes = {
  reduxState: PropTypes.object,
  onCancel: PropTypes.func,
  dispatch: PropTypes.func
};
