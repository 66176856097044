import colors from 'colors';

export default {
  label: {
    margin: '0',
    fontSize: '0.7rem',
    fontWeight: '900',
    color: '#000000',
    width: '100%'
  },
  input: {
    width: '98%',
    border: `1px solid ${colors.uiBackground}`,
    height: `2rem`,
    fontSize: '1rem',
    borderRadius: `0.3rem`,
    color: colors.text
  },
  selectInput: {
    width: '100%',
    border: `1px solid ${colors.uiBackground}`,
    height: `2.4rem`,
    fontSize: '1rem',
    borderRadius: `0.3rem`,
    color: colors.text
  },
  selectInput_disabled: {
    width: '100%',
    border: `0`,
    height: `2.4rem`,
    fontSize: '1rem',
    borderRadius: `0.3rem`,
    color: colors.text,
    opacity: 1,
    backgroundColor: 'rgb(250, 250, 250)'
  },
  checkboxText: {
    fontSize: '1rem',
    color: colors.text,
    marginLeft: '.5rem'
  },
  textarea: {
    borderRadius: `0.3rem`,
    overflow: 'scroll',
    color: colors.text,
    border: `1px solid ${colors.uiBackground}`,
    margin: '0 1rem 0 0.1rem',
    fontSize: '.8rem',
    padding: '0.5rem 0.5rem 0.5rem 0.5rem',
    background: 'white',
    minHeight: '10rem',
    width: '94%',
    height: '10rem'
  },
  textarea_disabled: {
    background: 'rgb(250, 250, 250)',
    borderRadius: `0.3rem`,
    overflow: 'scroll',
    color: colors.text,
    margin: '0 1rem 0 0.1rem',
    fontSize: '.8rem',
    padding: '0.5rem 0.5rem 0.5rem 0.5rem',
    width: '94%',
    height: '10rem'
  },
  tag: {
    height: '1rem',
    fontSize: '1rem',
    lineHeight: '1rem',
    margin: '.5rem .1rem 0 .1rem',
    color: colors.white,
    padding: '0.25rem 0.5rem',
    backgroundColor: 'rgb(190, 207, 211)',
    borderRadius: '.5rem'
  },
  tagCollection: {
    height: '2.5rem',
    display: 'flex',
    flexDirection: 'row',
    flexAlign: 'center'
  },
  color: {
    width: '36px',
    height: '14px',
    borderRadius: '2px',
    backgroundColor: 'transparent',
    border: '1px solid #e4e4e4'
  },
  colorSwatch: {
    padding: '5px',
    background: '#ffffff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0, 0, 0, 0.1)',
    display: 'inline-block',
    cursor: 'pointer',
    border: '1px solid #e4e4e4'
  },
  colorPopover: { position: 'relative', zIndex: '1' },
  colorCover: {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px'
  },
  selectionDropdown: {
    zIndex: '1',
    position: 'relative',
    backgroundColor: '#ffffff',
    padding: '0',
    margin: '0.03rem 0 0 0.1rem',
    width: '10rem',
    border: '0 1px 1px 1px solid #9e9e9e',
    boxShadow: colors.shadow,
    overflow: 'hidden',
    display: 'block',
    fontSize: '1rem',
    borderRadius: `0 0 0.3rem 0.3rem`,
    color: colors.text
  },
  selectionDropdownItem: {
    padding: '0.2rem 0 0.2rem 0.4rem'
  },
  selectionDropdownItem_hover: {
    padding: '0.2rem 0 0.2rem 0.4rem',
    backgroundColor: '#54818c88',
    color: 'white'
  },
  mediaPicker: {
    padding: '0',
    border: `1px solid ${colors.uiBackground}`,
    borderRadius: '0.3rem',
    width: '100%',
    height: '10rem'
  },
  mediaPicker_disabled: {},
  icon: {
    color: 'rgb(84, 129, 140)',
    marginLeft: '1rem',
    cursor: 'pointer',
    fontSize: '1rem',
    marginRight: '-.5rem'
  },
  private: {
    border: '0.1rem dashed #e4b7b7'
  }
};
