import initialState from './init/search';
import * as actions from 'state/actions';
import common from './common.js';

export default function api(state = initialState, action) {
  switch (action.type) {
    case actions.APP_VISIBILITY_SEARCH:
      return { ...state, isVisible: state.isPinned ? true : action.payload };

    case actions.APP_PIN_SEARCH:
      return { ...state, isPinned: action.payload };

    case actions.SEARCH_RESET:
      return { ...initialState, isVisible: state.isVisible };

    case actions.SEARCH_OPTIONS_UPDATE:
      return {
        ...state,
        ...action.payload
      };

    case actions.SEARCH_SET_POINTER:
      return {
        ...state,
        pointer: action.payload
      };

    case actions.SEARCH_SET_RESULTS:
      return {
        ...state,
        results: action.payload
      };

    case actions.POI_UPDATE_ENQUEUE: {
      return {
        ...state,
        results: common.updateInArrayById(state.results, action.payload)
      };
    }

    case actions.POI_UPDATE_ID: {
      return {
        ...state,
        results: common.updateIdInArray(state.results, action.payload)
      };
    }

    case actions.POI_DELETE_ENQUEUE: {
      return {
        ...state,
        results: common.removeFromArrayById(state.results, action.payload)
      };
    }

    default:
      return state;
  }
}
