//https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class WatchForOutsideClick extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (typeof this.props.onOutsideClick === 'function') {
        this.props.onOutsideClick(event);
      } else {
        console.warn(
          'WatchForOutsideClick: Please pass me onOutsideClick function to call!'
        );
      }
    }
  }

  render() {
    return (
      <div style={{ whiteSpace: 'normal' }} ref={this.setWrapperRef}>
        {this.props.children}
      </div>
    );
  }
}

WatchForOutsideClick.propTypes = {
  onOutsideClick: PropTypes.func,
  children: PropTypes.node.isRequired
};
