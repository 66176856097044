import React, { useState } from 'react';
import PropTypes from 'prop-types';
import defaultStyle from './defaultStyle.js';

const Coordinates = (props) => {
  const styles = { ...defaultStyle, ...props.style };
  const [isValid, setIsValid] = useState(null);
  const [fieldValue, setFieldValue] = useState(
    `${props.value?.lat ? props.value.lat : 0}, ${
      props.value?.lng ? props.value.lng : 0
    }`
  );
  const icon = isValid ? (
    <div className="fas fa-check" style={{ color: '#bada55' }} />
  ) : (
    <div className="fas fa-times" style={{ color: '#ff0000' }} />
  );
  const onValidate = (e) => {
    setFieldValue(e.target.value);
    setIsValid(false);
    try {
      let coordinates = e.target.value.split(',');
      let lat = parseFloat(coordinates[0]);
      let lng = parseFloat(coordinates[1]);
      if (lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180) {
        let coordinates = { lat, lng };
        setIsValid(true);
        setFieldValue(`${lat}, ${lng}`);
        props.onChange({
          id: props.id,
          value: coordinates,
          data: props.options?.data
        });
      }
    } catch (error) {
      //Noop
    }
  };
  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <label style={styles.label}>
          <div>{props.label === null ? '' : props.label}</div>
          <div style={props.isPublished ? null : styles.private}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%'
              }}
            >
              <input
                disabled={props.isDisabled || props.isLocked}
                style={styles.input}
                onChange={onValidate}
                value={fieldValue}
                onBlur={(e) => {
                  if (!isValid) {
                    e.target.value = props.value
                      ? `${props.value?.lat}, ${props.value.lng}`
                      : '0,0';
                    onValidate(e);
                  }
                }}
              />
              <div style={{ textAlign: 'center' }}>
                {isValid !== null && (
                  <div style={{ width: '1rem' }}>{icon}</div>
                )}
              </div>
            </div>
          </div>
        </label>
      </div>
    </div>
  );
};

Coordinates.defaultProps = {
  onChange: () => {}
};

Coordinates.propTypes = {
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isPublished: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.any,
  value: PropTypes.shape({
    lat: PropTypes.any,
    lng: PropTypes.any
  })
};

export default Coordinates;
