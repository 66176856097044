import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Tooltip.module.css';
import moment from 'moment';

class Tooltip extends Component {
  render() {
    return (
      <div
        style={{ top: this.props.top - 60, left: this.props.left - 65 }}
        className={styles.tooltip}
      >
        {this.props.contentTitle !== 'null' &&
          this.props.contentTitle.length > 0 && (
            <div className={styles.contentTitle}>
              {moment(this.props.contentTitle).isValid()
                ? moment(this.props.contentTitle).format('MM/DD/YYYY')
                : this.props.contentTitle}
            </div>
          )}
        <div className={styles.content}>{this.props.content}</div>
      </div>
    );
  }
}
export default Tooltip;

Tooltip.defaultProps = {
  top: 0,
  left: 0,
  disabled: false
};

Tooltip.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  content: PropTypes.string,
  contentTitle: PropTypes.string
};
