import React from 'react';
import styles from './Inspector.module.css';
import PropTypes from 'prop-types';
//import Mousetrap from 'mousetrap';
import { IconButton } from 'components';
import FormInput, { FIELDTYPE } from 'components/FormInput';
import { Collapsable } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'state/actions';
import poi from 'state/reducers/init/poi';
import CONST from 'constants.js';
import { mapHelper } from 'components/MapView';

export const SubForm = ({ template, config, onChange, id }) => {
  let defaultConfig = {};
  template.editableOptions.forEach((opt) => {
    defaultConfig[opt] = template.options[opt].default;
  });
  config = {
    ...defaultConfig,
    ...config
  };
  let ui = [];
  template.editableOptions.forEach((fieldName, idx) => {
    ui.push(
      <FormInput
        key={`${template.options.const}_${idx}`}
        id={id}
        label={template.options[fieldName].display}
        type={template.options[fieldName].formType}
        value={config[fieldName]}
        onChange={onChange}
        options={{
          selectionOptions: template.options[fieldName],
          data: { key: fieldName, json: config }
        }}
      />
    );
  });
  return ui;
};

export const Inspector = (props) => {
  const reduxState = useSelector((redux) => redux);
  const dispatch = useDispatch();

  const currentPoi = useSelector((redux) =>
    redux.poi.all?.find((item) => item.id === redux.poi.current?.id)
  );
  if (!currentPoi) return null;

  const currentRepresentation = reduxState.app.representation_options.find(
    (rep) => rep.id === parseInt(currentPoi.representation_id, 10)
  );
  const availableRepresentations = reduxState.app.representation_options.filter(
    (item) => item.is_selectable
  );

  const currentPresentation = reduxState.app.presentation_options.find(
    (rep) => rep.id === parseInt(currentPoi.presentation_id, 10)
  );
  const availablePresentations = reduxState.app.presentation_options.filter(
    (item) => item.is_selectable
  );

  const onNavigate = (pointer, poiId) => {
    dispatch(actions.searchSetPointer(pointer));
    dispatch(actions.setCurrentPoi(poiId));
    dispatch(actions.setAllVisibilityOff());
    dispatch(actions.uiShow(CONST.UI.POI_PRESENTATION));
    mapHelper.onNavigate(poiId);
  };

  const onChange = (e) => {
    let update = {};
    if (e.data && Object.keys(e.data).includes('key')) {
      update = {
        ...currentPoi,
        [e.id]: { ...e.data.json, [e.data.key]: e.value }
      };
    } else {
      if (parseInt(e.id, 10).toString() === e.id.toString()) {
        let data = [...currentPoi.data];
        const existing = currentPoi.data.findIndex(
          (item) => item.dataType === parseFloat(e.id)
        );
        if (existing !== -1) {
          data[existing][e.key ? e.key : 'value'] = e.value;
        } else {
          data.push({ ...e.data, [e.key ? e.key : 'value']: e.value });
        }
        update = { ...currentPoi, data };
      } else {
        update = { ...currentPoi, [e.id]: e.value };
      }
    }
    dispatch(actions.updatePoi(update));
  };

  const optionsRep = reduxState.app.representation_options.find(
    (rep) => rep.id === currentPoi.representation_id
  );

  const optionsPres = reduxState.app.presentation_options.find(
    (rep) => rep.id === currentPoi.presentation_id
  );

  const mediaOptions = {
    hasMediaLibrary: reduxState.app.prefs.hasMediaLibrary,
    hasAudioTour: reduxState.app.prefs['hasAudioTour'].value,
    available: reduxState.media.available
  };

  return (
    <div
      className={
        reduxState.ui[CONST.UI.TIME_FILTER]?.isVisible
          ? `${styles.inspector_container} ${styles.offset}`
          : styles.inspector_container
      }
    >
      <div style={{ overflow: 'hidden scroll' }}>
        <div className={styles.searchnav}>
          <div className={styles.backArrow}>
            <IconButton
              icon="search"
              onClick={() => dispatch(actions.uiShow(CONST.UI.SEARCH))}
            />
          </div>
          {(reduxState.search.results?.length > 0 && (
            <div className={styles.searchPagination}>
              <IconButton
                isDisabled={reduxState.search.pointer === 1}
                onClick={() => {
                  //Prev
                  let newPointer = reduxState.search.pointer - 1;
                  newPointer = newPointer <= 0 ? 1 : newPointer;
                  let selectedPoiID =
                    reduxState.search.results[newPointer - 1].id;
                  onNavigate(newPointer, selectedPoiID);
                }}
                icon="arrow-circle-left"
              />
              <div style={{ flexGrow: 1, textAlign: 'center' }}>
                {reduxState.search.options.keyword} ({reduxState.search.pointer}
                /{reduxState.search.results?.length})
              </div>
              <IconButton
                isDisabled={
                  reduxState.search.pointer ===
                  reduxState.search.results?.length
                }
                onClick={() => {
                  //Next
                  let newPointer = reduxState.search.pointer + 1;
                  newPointer =
                    newPointer > reduxState.search.results.length
                      ? reduxState.search.results.length
                      : newPointer;
                  let selectedPoiID =
                    reduxState.search.results[newPointer - 1].id;
                  onNavigate(newPointer, selectedPoiID);
                }}
                icon="arrow-circle-right"
              />
            </div>
          )) || (
            <div className={styles.backArrow}>
              <IconButton
                icon="map-marker-alt"
                onClick={() => {
                  onNavigate(0, currentPoi.id);
                }}
              />
            </div>
          )}
        </div>
        <div style={{ paddingTop: '2rem', paddingBottom: '1rem' }}>
          {currentRepresentation.is_selectable &&
            reduxState.user.isAuthenticated && (
              <div style={{ margin: '1rem 0 0 0' }}>
                <Collapsable
                  isOpen={false}
                  style={{ marginTop: '1rem', marginLeft: '-.5rem' }}
                  title={`Pin: ${currentRepresentation.name} ${
                    mediaOptions.hasAudioTour ? `[${currentPoi.sortorder}]` : ''
                  }`}
                >
                  <div style={{ margin: '1rem' }}>
                    <FormInput
                      id="representation_id"
                      type={FIELDTYPE.SELECT}
                      isDisabled={!reduxState.user.isAuthenticated}
                      isPublished={true}
                      isLocked={false}
                      value={currentPoi.representation_id}
                      onChange={onChange}
                      options={{ selectionOptions: availableRepresentations }}
                    />
                    <div style={{ margin: '.5rem' }}>
                      <SubForm
                        id="representation_config"
                        template={optionsRep.template}
                        config={currentPoi.representation_config}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </Collapsable>
              </div>
            )}

          {currentPresentation?.is_selectable &&
            reduxState.user.isAuthenticated && (
              <div style={{ margin: '1rem 0 0 0' }}>
                <Collapsable
                  isOpen={true}
                  style={{ marginTop: '1rem', marginLeft: '-.5rem' }}
                  title={`Presents: ${currentPresentation.name}`}
                >
                  <div style={{ margin: '1rem' }}>
                    <FormInput
                      id="presentation_id"
                      type={FIELDTYPE.SELECT}
                      isDisabled={!reduxState.user.isAuthenticated}
                      isPublished={true}
                      isLocked={false}
                      value={currentPoi.presentation_id}
                      onChange={onChange}
                      options={{ selectionOptions: availablePresentations }}
                    />
                    <div style={{ margin: '.5rem' }}>
                      <SubForm
                        id="presentation_config"
                        template={optionsPres.template}
                        config={currentPoi.presentation_config}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </Collapsable>
                <hr />
              </div>
            )}

          <div>
            <div style={{ marginTop: '0.8rem' }}>
              <FormInput
                id="name"
                type={FIELDTYPE.TEXT}
                label="Name"
                isPublished={true}
                value={currentPoi.name}
                isDisabled={!reduxState.user.isAuthenticated}
                onChange={onChange}
              />
            </div>
            <div style={{ marginTop: '0.8rem' }}>
              <FormInput
                id="date"
                type={FIELDTYPE.DATE}
                label={'Date'}
                isPublished={true}
                value={currentPoi.date}
                isDisabled={!reduxState.user.isAuthenticated}
                onChange={onChange}
              />
            </div>
          </div>
          <div style={{ marginTop: '0.8rem' }}>
            <FormInput
              id="coordinate"
              type={FIELDTYPE.COORDINATE}
              label="Coordinate"
              isPublished={true}
              value={currentPoi.coordinate}
              isDisabled={!reduxState.user.isAuthenticated}
              onChange={onChange}
            />
          </div>

          <div>
            <hr />

            {reduxState.app.poi_fields?.map((field) => {
              let currentData = currentPoi.data.find(
                (item) => item.dataType === field.id
              );
              let fieldData = {
                search_scope: field.search_scope,
                is_published: field.is_published_by_default,
                is_locked: field.is_locked_by_default,
                is_markdown: field.is_markdown_by_default,
                dataType: field.id,
                poiId: poi.id,
                field: field.name,
                ...currentData
              };
              const selectionOptions =
                typeof reduxState.poi.selection_options !== 'undefined'
                  ? reduxState.poi.selection_options[field.id]
                  : [];
              return (
                <div style={{ marginTop: '0.8rem' }} key={field.id}>
                  <FormInput
                    hasOptionToggles={true}
                    id={field.id}
                    type={field.format.const}
                    label={field.name}
                    isMarkdown={fieldData?.is_markdown}
                    isPublished={fieldData?.is_published}
                    isLocked={fieldData?.is_locked}
                    isDisabled={!reduxState.user.isAuthenticated}
                    value={fieldData?.value}
                    onChange={onChange}
                    options={{
                      data: fieldData,
                      onTagClick: props.onTagClick,
                      mediaOptions: mediaOptions,
                      onLoadMediaLibrary: (opt) => {
                        props.onLoadMediaLibrary(opt, (item) => {
                          onChange({
                            target: {
                              dataset: { id: field.id },
                              value: item ? item.id : null
                            }
                          });
                        });
                      },
                      selectionOptions
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Inspector;

Inspector.defaultProps = {};

Inspector.propTypes = {
  onLoadMediaLibrary: PropTypes.func,
  filteredPOI: PropTypes.array,
  propsMediaLibrary: PropTypes.object,
  mediaOptions: PropTypes.object,
  search: PropTypes.object,
  mapID: PropTypes.number,
  onChangeVisibility: PropTypes.func,
  onNavigate: PropTypes.func,
  onSearch: PropTypes.func,
  onCreateCustom: PropTypes.func,
  onTagClick: PropTypes.func,
  poiFields: PropTypes.array,
  poiReavailablePresentations: PropTypes.array,
  poiSelectionOptions: PropTypes.array,
  token: PropTypes.string
};
