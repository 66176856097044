import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import Text from './Text';
import moment from 'moment';
import defaultStyle from './defaultStyle.js';
import './Date.css';
moment.suppressDeprecationWarnings = true;

const Date = (props) => {
  const styles = { ...defaultStyle, ...props.style };
  const onChange = (value) => {
    props.onChange({
      id: props.id,
      value,
      data: props.options?.data
    });
  };

  if (props.isDisabled || props.isLocked) {
    return <Text {...props} />;
  } else {
    return (
      <label style={styles.label}>
        <div>{props.label === null ? '' : props.label}</div>
        <div style={props.isPublished ? null : styles.private}>
          <DayPickerInput
            formatDate={formatDate}
            value={props.value ? props.value : ''}
            onDayChange={(day) => {
              if (day) onChange(day);
            }}
            inputProps={{
              onChange: (e) => onChange(e.target.value)
            }}
          />
        </div>
      </label>
    );
  }
};

Date.defaultProps = {
  onChange: () => {},
  isPublished: true
};

Date.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  id: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.object
};

export default Date;
