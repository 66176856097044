import React, { useState } from 'react';
import PropTypes from 'prop-types';
import defaultStyle from './defaultStyle.js';
import WatchForOutsideClick from 'components/WatchForOutsideClick';

const Selection = (props) => {
  const [selectionVisible, setSelectionVisible] = useState(false);
  const [hoverOn, setHoverOn] = useState(false);
  const onFocus = () => {
    setSelectionVisible(true);
  };

  const onSelect = (e) => {
    props.onChange({
      id: props.id,
      value: parseInt(e.target.dataset.value, 10),
      data: {
        ...props.options?.data,
        item:
          props.options.selectionOptions[parseInt(e.target.dataset.value, 10)]
      }
    });
    setSelectionVisible(false);
  };

  const onNew = (e) => {
    props.onChange({
      id: props.id,
      value: -1,
      data: {
        ...props.options?.data,
        item: { name: e.target.value, id: null }
      }
    });
    setSelectionVisible(false);
  };

  const styles = { ...defaultStyle, ...props.style };
  let dropdown_id = `selection_dropdown_${props.id}`;
  return (
    <label style={styles.label}>
      <div>{props.label === null ? '' : props.label}</div>
      <div style={props.isPublished ? null : styles.private}>
        {!props.options.acceptsNew && (
          <div onClick={onFocus}>{props.value}</div>
        )}
        {props.options.acceptsNew && (
          <input
            style={styles.input}
            autoComplete="off"
            disabled={props.isDisabled || props.isLocked}
            data-id={props.id}
            type="text"
            onFocus={onFocus}
            value={props.value ? props.value : ''}
            onChange={onNew}
          />
        )}
        <WatchForOutsideClick onOutsideClick={() => setSelectionVisible(false)}>
          <div
            id={dropdown_id}
            style={{
              ...styles.selectionDropdown,
              display: selectionVisible ? 'block' : 'none'
            }}
          >
            {props.options.selectionOptions &&
              props.options.selectionOptions.map((item, idx) => {
                const style =
                  hoverOn === idx
                    ? styles.selectionDropdownItem_hover
                    : styles.selectionDropdownItem;
                return (
                  <div
                    onMouseEnter={() => setHoverOn(idx)}
                    onTouchStart={() => setHoverOn(idx)}
                    onTouchCancel={() => setHoverOn(null)}
                    onTouchEnd={() => setHoverOn(null)}
                    onMouseLeave={() => setHoverOn(null)}
                    key={`${props.id}_${idx}`}
                    data-id={idx + 1}
                    onClick={onSelect}
                    style={style}
                    data-value={item.id === null ? '' : item.id}
                  >
                    {item.name}
                  </div>
                );
              })}
          </div>
        </WatchForOutsideClick>
      </div>
    </label>
  );
};

Selection.defaultProps = {
  onChange: () => {}
};

Selection.propTypes = {
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isPublished: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.object,
  value: PropTypes.any
};

export default Selection;
