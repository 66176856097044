import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Spinner.module.css';

class Spinner extends Component {
  render() {
    return (
      <div
        className={
          this.props.isVisible ? styles.spinner_container : styles.invisible
        }
      >
        <div className={styles.spinnerInternal}>
          <div className={this.props.hasError ? styles.hidden : styles.spinner}>
            <div className={styles.bounce1} />
            <div className={styles.bounce2} />
            <div className={styles.bounce3} />
          </div>
          <div className={this.props.hasError ? '' : styles.hidden}>
            <span
              className="fas fa-exclamation-triangle"
              style={{ fontSize: '5rem', color: 'black' }}
            />
            <div className={styles.errorMessage}>{this.props.errorMessage}</div>
          </div>
          <img
            className={
              this.props.showLogo ? styles.spinner_logo : styles.hidden
            }
            alt=""
            src={this.props.logoURL}
          />
        </div>
      </div>
    );
  }
}
export default Spinner;

Spinner.defaultProps = {
  isVisible: true,
  hasError: false,
  showLogo: false,
  logoURL: '',
  errorMessage: 'An Error Has Occurred!'
};

Spinner.propTypes = {
  errorMessage: PropTypes.string,
  hasError: PropTypes.bool,
  isVisible: PropTypes.bool,
  logoURL: PropTypes.string,
  showLogo: PropTypes.bool
};
