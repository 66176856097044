// FIXME: This should be retired in favor of DropArea, with the drop area passed as a children

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { NativeTypes } from 'react-dnd-html5-backend';
import TargetBox from './targetBox';
import styles from './Dropzone.module.css';
import IconButton from 'components/IconButton';

const { FILE } = NativeTypes;
const Dropzone = (props) => {
  const accepts = useMemo(() => [FILE], []);
  const onFileDrop = (item, monitor) => {
    if (monitor) {
      if (props.onDrop) props.onDrop({ item, files: monitor.getItem().files });
    }
  };

  const thisStyles = { ...styles, ...props.styles };

  const onRemovePreview = (e) => {
    if (typeof props.onRemovePreview === 'function') props.onRemovePreview(e);
  };
  let hasButton =
    typeof props.onRemovePreview === 'function' &&
    props.previewImage &&
    props.previewImage.length > 0;

  return (
    <div className={props.children ? '' : thisStyles.dropzone_container}>
      {props.onRemovePreview && props.previewImage && (
        <IconButton
          isBorderless={true}
          dataset={{
            'data-mediaid': props.id
          }}
          style={{
            float: 'right',
            zIndex: 1,
            position: 'relative',
            margin: '0.5rem',
            background: '#00000036',
            padding: '.2rem'
          }}
          icon="times-circle"
          onClick={onRemovePreview}
        />
      )}
      <TargetBox
        {...props}
        {...props.dataset}
        hasButton={hasButton}
        styles={styles}
        accepts={accepts}
        onDrop={onFileDrop}
      />
    </div>
  );
};
export default Dropzone;

Dropzone.defaultProps = {
  allowClickToZoom: false,
  children: null,
  dropMessage: 'Drop Here'
};

Dropzone.propTypes = {
  isDisabled: PropTypes.bool,
  id: PropTypes.number,
  allowClickToZoom: PropTypes.bool,
  children: PropTypes.node,
  dropMessage: PropTypes.string,
  onRemovePreview: PropTypes.func,
  onDrop: PropTypes.func,
  previewImage: PropTypes.string,
  dataset: PropTypes.object,
  styles: PropTypes.object
};
