import React, { useState } from 'react';
import PropTypes from 'prop-types';
import defaultStyle from './defaultStyle.js';
import { SketchPicker } from 'react-color';
import { hexToRgb } from 'utility';

const Color = (props) => {
  const colorRGB = hexToRgb(props.value);
  const isDisabled = props.isDisabled || props.isLocked;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [colorpicker, setColorpicker] = useState({
    sendOpacity: props.options.sendOpacity ? true : false,
    color: {
      r: colorRGB.r,
      g: colorRGB.g,
      b: colorRGB.b,
      a: props.options.alpha
    }
  });

  const onClick = () => {
    if (!isDisabled) setDisplayColorPicker(!displayColorPicker);
  };

  const onClose = () => {
    setDisplayColorPicker(false);
  };

  const onChange = (color) => {
    setColorpicker({ ...colorpicker, color: color.rgb });
    props.onChange({
      id: props.id,
      value: {
        color: color.hex,
        opacity: color.rgb.a === 0 ? 0.1 : color.rgb.a
      },
      data: props.options?.data
    });
  };

  const styles = { ...defaultStyle, ...props.style };
  return (
    <div>
      <div style={{ ...styles.label, marginBottom: '.52rem' }}>
        {props.label === null ? '' : props.label}
      </div>
      <div style={styles.colorSwatch} onClick={onClick}>
        <div style={props.isPublished ? null : styles.private}>
          <div
            style={{
              ...styles.color,
              backgroundColor: `rgba(${colorpicker.color.r},
                      ${colorpicker.color.g},
                      ${colorpicker.color.b},
                      ${colorpicker.color.a ? colorpicker.color.a : 1})`
            }}
          />
        </div>
      </div>
      {displayColorPicker ? (
        <div style={styles.colorPopover}>
          <div style={styles.colorCover} onClick={onClose} />
          <SketchPicker
            color={colorpicker.color}
            onChange={(color) => onChange(color, colorpicker.sendOpacity)}
          />
        </div>
      ) : null}
    </div>
  );
};

Color.defaultProps = {
  onChange: () => {},
  isPublished: true
};

Color.propTypes = {
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isPublished: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.object,
  value: PropTypes.any
};

export default Color;
