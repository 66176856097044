function action(type, payload = {}) { return { type, payload }; }

export const APP_ENABLE_DRAWING = 'APP_ENABLE_DRAWING';
export const APP_DISABLE_DRAWING = 'APP_DISABLE_DRAWING';
export const APP_FORCE_MAP_INIT = 'APP_FORCE_MAP_INIT';
export const APP_SET_MAP_OBJECT = 'APP_SET_MAP_OBJECT';
export const APP_EXPORT_STANDALONE = 'APP_EXPORT_STANDALONE';
export const APP_SYNCH_BEGIN = 'APP_SYNCH_BEGIN';
export const APP_SYNCH_END = 'APP_SYNCH_END';
export const APP_SYNCH_END_DEBOUNCED = 'APP_SYNCH_END_DEBOUNCED';
export const APP_SAGA_ERROR = 'APP_SAGA_ERROR';
export const APP_SAGA_ERROR_NONFATAL = 'APP_SAGA_ERROR_NONFATAL';
export const APP_ERROR_CLEAR = 'APP_ERROR_CLEAR';
export const APP_ERROR_SET = 'APP_ERROR_SET';
export const APP_EMPTY_INITIALIZATION_RECEIVED = 'APP_EMPTY_INITIALIZATION_RECEIVED';
export const APP_INITIALIZATION_RECEIVED = 'APP_INITIALIZATION_RECEIVED';
export const APP_INITIALIZE = 'APP_INITIALIZE';
export const APP_LOGIN = 'APP_LOGIN';
export const APP_LOGIN_RESPONSE = 'APP_LOGIN_RESPONSE';
export const APP_LOGOUT = 'APP_LOGOUT';
export const APP_PIN_SEARCH = 'APP_PIN_SEARCH';
export const APP_PIN_TIMEFILTER = 'APP_PIN_TIMEFILTER';
export const APP_PIN_UNPIN_ALL = 'APP_PIN_UNPIN_ALL';
export const APP_PREVIEW_INSPECTOR = 'APP_PREVIEW_INSPECTOR';
export const APP_REAUTHORIZE = 'APP_REAUTHORIZE';
export const APP_REMOVE_CURRENT_GEOFENCE = 'APP_REMOVE_CURRENT_GEOFENCE';
export const APP_SET_GEOFENCE = 'APP_SET_GEOFENCE';
export const APP_SET_PROGRESS_PERCENTAGE = 'APP_SET_PROGRESS_PERCENTAGE';
export const APP_TOGGLE_MAPLOCK = 'APP_TOGGLE_MAPLOCK';
export const APP_LOCK_MAP = 'APP_LOCK_MAP';
export const APP_UPDATE_PREFS = 'APP_UPDATE_PREFS';
export const APP_UPDATE_PREFS_LIVE = 'APP_UPDATE_PREFS_LIVE';
export const APP_VISIBILITY_ALL_HIDDEN = 'APP_VISIBILITY_ALL_HIDDEN';
export const APP_VISIBILITY_AUDIOTOUR = 'APP_VISIBILITY_AUDIOTOUR';
export const APP_VISIBILITY_GEOMETRY = 'APP_VISIBILITY_GEOMETRY';
export const APP_VISIBILITY_SEARCH = 'APP_VISIBILITY_SEARCH';
export const APP_VISIBILITY_SPINNER = 'APP_VISIBILITY_SPINNER';
export const APP_VISIBILITY_TIMEFILTER = 'APP_VISIBILITY_TIMEFILTER';
export const APP_UPDATE_GLOBAL_PREFS = 'APP_UPDATE_GLOBAL_PREFS';
export const APP_SET_UI_MODE = 'APP_SET_UI_MODE';
export const APP_UPDATE_DRAWING = 'APP_UPDATE_DRAWING';
export const APP_CLEAR_DRAWING = 'APP_CLEAR_DRAWING';
export const setUiMode = (payload) => action(APP_SET_UI_MODE, payload);
export const updateCurrentDrawing = (payload) => action(APP_UPDATE_DRAWING, payload);
export const updateClearDrawing = (payload) => action(APP_CLEAR_DRAWING, payload);
export const setMapObject = (payload) => action(APP_SET_MAP_OBJECT, payload);
export const exportStandalone = () => action(APP_EXPORT_STANDALONE);
export const setError = (payload) => action(APP_ERROR_SET, payload);
export const clearError = (payload) => action(APP_ERROR_CLEAR, payload);
export const setGeofence = (payload) => action(APP_SET_GEOFENCE, payload);
export const removeCurrentGeofence = (payload) => action(APP_REMOVE_CURRENT_GEOFENCE, payload);
export const getSelectionOptions = (payload) => action(POI_GET_SELECTION_OPTIONS, payload);
export const initializeApp = (payload) => action(APP_INITIALIZE, payload);
export const login = (payload) => action(APP_LOGIN, payload);
export const logout = (payload) => action(APP_LOGOUT, payload);
export const mapLock = (payload) => action(APP_TOGGLE_MAPLOCK, payload);
export const reauthorize = (payload) => action(APP_REAUTHORIZE, payload);
export const setAllVisibilityOff = () => action(APP_VISIBILITY_ALL_HIDDEN);
export const setAudioTourVisibility = (payload) => action(APP_VISIBILITY_AUDIOTOUR, payload);
export const setInspectorPreview = (payload) => action(APP_PREVIEW_INSPECTOR, payload);
export const setProgress = (payload) => action(APP_SET_PROGRESS_PERCENTAGE, payload);
export const setSearchVisibility = (payload) => action(APP_VISIBILITY_SEARCH, payload);
export const setSpinnerVisibility = (payload) => action(APP_VISIBILITY_SPINNER, payload);
export const setTimeFilterVisibility = (payload) => action(APP_VISIBILITY_TIMEFILTER, payload);
export const updatePrefs = (payload) => action(APP_UPDATE_PREFS, payload);
export const updateGlobalPrefs = (payload) => action(APP_UPDATE_GLOBAL_PREFS, payload);
export const setTimeFilterPin = (payload) => action(APP_PIN_TIMEFILTER, payload);
export const setSearchPin = (payload) => action(APP_PIN_SEARCH, payload);
export const setAllUnpin = () => action(APP_PIN_UNPIN_ALL);

export const API_REFRESH_MAP = 'API_REFRESH_MAP';
export const API_UPDATE_PREFS = 'API_UPDATE_PREFS';
export const API_PERFORM_CRUD = 'API_PERFORM_CRUD';

export const LOCATION_CLEAR_CURRENT = 'LOCATION_CLEAR_CURRENT';
export const LOCATION_DISABLE = 'LOCATION_DISABLE';
export const LOCATION_ENABLE = 'LOCATION_ENABLE';
export const LOCATION_SET_CURRENT = 'LOCATION_SET_CURRENT';
export const enableLocation = () => action(LOCATION_ENABLE);
export const disableLocation = () => action(LOCATION_DISABLE);

export const POI_GET_SELECTION_OPTIONS = 'POI_GET_SELECTION_OPTIONS';
export const POI_GET_SELECTION_OPTIONS_RESPONSE = 'POI_GET_SELECTION_OPTIONS_RESPONSE';
export const POI_CLEAR_INSERT_QUEUE = 'POI_CLEAR_INSERT_QUEUE';
export const POI_CLEAR_REMOVE_QUEUE = 'POI_CLEAR_REMOVE_QUEUE';
export const POI_CLEAR_UPDATE_QUEUE = 'POI_CLEAR_UPDATE_QUEUE';
export const POI_CREATE_ENQUEUE = 'POI_CREATE_ENQUEUE';
export const POI_DELETE_ENQUEUE = 'POI_DELETE_ENQUEUE';
export const POI_UPDATE_ENQUEUE = 'POI_UPDATE_ENQUEUE';
export const POI_UPDATE_ID = 'POI_UPDATE_ID';
export const POI_SET_CURRENT = 'POI_SET_CURRENT';
export const POI_CREATE = 'POI_CREATE';
export const POI_CREATE_RESPONSE = 'POI_CREATE_RESPONSE';
export const POI_DELETE = 'POI_DELETE';
export const POI_DELETE_RESPONSE = 'POI_DELETE_RESPONSE';
export const POI_GET = 'POI_GET';
export const POI_GET_RESPONSE = 'POI_GET_RESPONSE';
export const POI_REFRESH = 'POI_REFRESH';
export const POI_SET_DEFAULTSTYLE = 'POI_SET_DEFAULTSTYLE';
export const POI_SET_FILTERED = 'POI_SET_FILTERED';
export const POI_UPDATE = 'POI_UPDATE';
export const POI_UPDATE_RESPONSE = 'POI_UPDATE_RESPONSE';
export const POI_RESEQUENCE = 'POI_RESEQUENCE';
export const setCurrentPoi = (payload) => action(POI_SET_CURRENT, payload);
export const getPoi = (payload) => action(POI_GET, payload);
export const setFilteredPoi = (payload) => action(POI_SET_FILTERED, payload);
export const setSearchResults = (payload) => action(SEARCH_SET_RESULTS, payload);
export const resetFilter = () => action(SEARCH_RESET);
export const refreshPoi = (payload) => action(POI_REFRESH, payload);
export const createPoi = (payload) => action(POI_CREATE, payload);
export const deletePoi = (payload) => action(POI_DELETE, payload);
export const updatePoi = (payload) => action(POI_UPDATE, payload);
export const setStyleAsDefault = (payload) => action(POI_SET_DEFAULTSTYLE, payload);
export const resequencePoi = (payload) => action(POI_RESEQUENCE, payload);

export const MEDIA_CREATE = 'MEDIA_CREATE';
export const MEDIA_REPLACE = 'MEDIA_REPLACE';
export const MEDIA_CREATE_RESPONSE = 'MEDIA_CREATE_RESPONSE';
export const MEDIA_DELETE = 'MEDIA_DELETE';
export const MEDIA_DELETE_RESPONSE = 'MEDIA_DELETE_RESPONSE';
export const MEDIA_DELETE_PREVIEW = 'MEDIA_DELETE_PREVIEW';
export const MEDIA_GET = 'MEDIA_GET';
export const MEDIA_GET_RESPONSE = 'MEDIA_GET_RESPONSE';
export const MEDIA_GET_CONFIG = 'MEDIA_GET_CONFIG';
export const MEDIA_GET_CONFIG_RESPONSE = 'MEDIA_GET_CONFIG_RESPONSE';
export const MEDIA_UPDATE = 'MEDIA_UPDATE';
export const MEDIA_UPDATE_RESPONSE = 'MEDIA_UPDATE_RESPONSE';
export const MEDIA_UPDATE_ENQUEUE = 'MEDIA_UPDATE_ENQUEUE';
export const MEDIA_CLEAR_UPDATE_QUEUE = 'MEDIA_CLEAR_UPDATE_QUEUE';
export const replaceMedia = (payload) => action(MEDIA_REPLACE, payload);
export const createMedia = (payload) => action(MEDIA_CREATE, payload);
export const updateMedia = (payload) => action(MEDIA_UPDATE, payload);
export const deleteMedia = (payload) => action(MEDIA_DELETE, payload);
export const deleteMediaPreview = (payload) => action(MEDIA_DELETE_PREVIEW, payload);

export const SERVER_S3_FILE_DELETE_RESPONSE = 'SERVER_S3_FILE_DELETE_RESPONSE';
export const SERVER_S3_FILE_CREATE = 'SERVER_S3_FILE_CREATE';
export const SERVER_S3_GET_SIGNED_UPLOAD = 'SERVER_S3_GET_SIGNED_UPLOAD';
export const SERVER_S3_GET_SIGNED_UPLOAD_RESPONSE = 'SERVER_S3_GET_SIGNED_UPLOAD_RESPONSE';
export const SERVER_S3_FILE_DELETE = 'SERVER_S3_FILE_DELETE';
export const SERVER_CREATE = 'SERVER_CREATE';
export const SERVER_CREATE_RESPONSE = 'SERVER_CREATE_RESPONSE';
export const SERVER_DELETE = 'SERVER_DELETE';
export const SERVER_DELETE_RESPONSE = 'SERVER_DELETE_RESPONSE';
export const SERVER_GET_MANIFEST = 'SERVER_GET_MANIFEST';
export const SERVER_GET_MANIFEST_RESPONSE = 'SERVER_GET_MANIFEST_RESPONSE';
export const SERVER_INIT = 'SERVER_INIT';
export const SERVER_INIT_RESPONSE = 'SERVER_INIT_RESPONSE';
export const SERVER_SET_INPROGRESS = 'SERVER_SET_INPROGRESS';
export const SERVER_SET_MANIFEST = 'SERVER_SET_MANIFEST';
export const SERVER_SET_MANIFEST_RESPONSE = 'SERVER_SET_MANIFEST_RESPONSE';
export const SERVER_STATUS = 'SERVER_STATUS';
export const SERVER_STATUS_RESPONSE = 'SERVER_STATUS_RESPONSE';
export const s3CreateFile = (payload) => action(SERVER_S3_FILE_CREATE, payload);
export const s3DeleteFile = (payload) => action(SERVER_S3_FILE_DELETE, payload);
export const s3GetSignedUpload = (payload) => action(SERVER_S3_GET_SIGNED_UPLOAD, payload);
export const updateManifest = (payload) => action(SERVER_SET_MANIFEST, payload);
export const getManifest = (payload) => action(SERVER_GET_MANIFEST, payload);
export const serverInitialize = (payload) => action(SERVER_INIT, payload);
export const serverCreate = (payload) => action(SERVER_CREATE, payload);
export const serverStatus = (payload) => action(SERVER_STATUS, payload);
export const serverDelete = (payload) => action(SERVER_DELETE, payload);

export const AUDIOTOUR_UPDATE_WEATHER = 'AUDIOTOUR_UPDATE_WEATHER';
export const AUDIOTOUR_UPDATE_CONFIG = 'AUDIOTOUR_UPDATE_CONFIG';
export const AUDIOTOUR_UPDATE_CONFIG_ENQUEUE = 'AUDIOTOUR_UPDATE_CONFIG_ENQUEUE';
export const AUDIOTOUR_GET_ITEMS = 'AUDIOTOUR_GET_ITEMS';
export const AUDIOTOUR_GET_ITEMS_RESPONSE = 'AUDIOTOUR_GET_ITEMS_RESPONSE';
export const AUDIOTOUR_GET_CONFIG = 'AUDIOTOUR_GET_CONFIG';
export const AUDIOTOUR_GET_CONFIG_RESPONSE = 'AUDIOTOUR_GET_CONFIG_RESPONSE';
export const AUDIOTOUR_CRUD_RESPONSE = 'AUDIOTOUR_CRUD_RESPONSE';
export const AUDIOTOUR_UPDATE_ITEM_ID = 'AUDIOTOUR_UPDATE_ITEM_ID';
export const AUDIOTOUR_UPDATE_ITEM = 'AUDIOTOUR_UPDATE_ITEM';
export const AUDIOTOUR_UPDATE_ITEM_GROUP = 'AUDIOTOUR_UPDATE_ITEM_GROUP';
export const AUDIOTOUR_CREATE_ITEM = 'AUDIOTOUR_CREATE_ITEM';
export const AUDIOTOUR_DELETE_ITEM = 'AUDIOTOUR_DELETE_ITEM';
export const AUDIOTOUR_UPDATE_ENQUEUE = 'AUDIOTOUR_UPDATE_ENQUEUE';
export const AUDIOTOUR_CREATE_ENQUEUE = 'AUDIOTOUR_CREATE_ENQUEUE';
export const AUDIOTOUR_DELETE_ENQUEUE = 'AUDIOTOUR_DELETE_ENQUEUE';
export const AUDIOTOUR_CLEAR_INSERT_QUEUE = 'AUDIOTOUR_CLEAR_INSERT_QUEUE';
export const AUDIOTOUR_CLEAR_REMOVE_QUEUE = 'AUDIOTOUR_CLEAR_REMOVE_QUEUE';
export const AUDIOTOUR_CLEAR_UPDATE_QUEUE = 'AUDIOTOUR_CLEAR_UPDATE_QUEUE';
export const AUDIOTOUR_RESEQUENCE = 'AUDIOTOUR_RESEQUENCE';
export const AUDIOTOUR_RECORD_CHANGES = 'AUDIOTOUR_RECORD_CHANGES';
export const updateWeather = (payload) => action(AUDIOTOUR_UPDATE_WEATHER, payload);
export const createAudiotourItem = (payload) => action(AUDIOTOUR_CREATE_ITEM, payload);
export const deleteAudiotourItem = (payload) => action(AUDIOTOUR_DELETE_ITEM, payload);
export const updateAudiotourItem = (payload) => action(AUDIOTOUR_UPDATE_ITEM, payload);
export const updateAudiotourConfig = (payload) => action(AUDIOTOUR_UPDATE_CONFIG, payload);
export const updateAudiotourItemGroup = (payload) => action(AUDIOTOUR_UPDATE_ITEM_GROUP, payload);
export const resequenceTrackItems = (payload) => action(AUDIOTOUR_RESEQUENCE, payload);

export const _USER_CREATE = '_USER_CREATE';
export const _USER_CRUD_RESPONSE = '_USER_CRUD_RESPONSE';
export const _USER_DELETE = '_USER_DELETE';
export const _USER_UPDATE = '_USER_UPDATE';
export const USER_CREATE = 'USER_CREATE';
export const USER_CREATE_RESPONSE = 'USER_CREATE_RESPONSE';
export const USER_DELETE = 'USER_DELETE';
export const USER_DELETE_RESPONSE = 'USER_DELETE_RESPONSE';
export const USER_GET = 'USER_GET';
export const USER_GET_RESPONSE = 'USER_GET_RESPONSE';
export const USER_PASS_RESET = 'USER_PASS_RESET';
export const USER_REQUEST_VALIDATION = 'USER_REQUEST_VALIDATION';
export const USER_UPDATE = 'USER_UPDATE';
export const USER_UPDATE_RESPONSE = 'USER_UPDATE_RESPONSE';
export const USER_UPDATE_WITH_CHECK = 'USER_UPDATE_WITH_CHECK';
export const USER_UPDATE_WITH_CHECK_RESP = 'USER_UPDATE_WITH_CHECK_RESP';
export const getUsers = (payload) => action(USER_GET, payload);
export const createUser = (payload) => action(USER_CREATE, payload);
export const updateUser = (payload) => action(USER_UPDATE, payload);
export const updateUserWithPasswordCheck = (payload) => action(USER_UPDATE_WITH_CHECK, payload);
export const deleteUser = (payload) => action(USER_DELETE, payload);
export const userPassReset = (payload) => action(USER_PASS_RESET, payload);
export const userRequestValidation = (payload) => action(USER_REQUEST_VALIDATION, payload);

export const SEARCH_OPTIONS_UPDATE = 'SEARCH_OPTIONS_UPDATE';
export const SEARCH_RESET = 'SEARCH_RESET';
export const SEARCH_SET_POINTER = 'SEARCH_SET_POINTER';
export const SEARCH_SET_RESULTS = 'SEARCH_SET_RESULTS';
export const searchSetPointer = (payload) => action(SEARCH_SET_POINTER, payload);
export const updateSearchOptions = (payload) => action(SEARCH_OPTIONS_UPDATE, payload);

export const TIMEFILTER_SET_RANGE = 'TIMEFILTER_SET_RANGE';
export const TIMEFILTER_SETVAL = 'TIMEFILTER_SETVAL';
export const TIMEFILTER_OPTIONS_UPDATE = 'TIMEFILTER_OPTIONS_UPDATE';
export const updateTimefilterOptions = (payload) => action(TIMEFILTER_OPTIONS_UPDATE, payload);
export const setTimeFilterRange = (payload) => action(TIMEFILTER_SET_RANGE, payload);
export const timeFilterSetValue = (payload) => action(TIMEFILTER_SETVAL, payload);

export const PKG_CREATE = 'PKG_CREATE';
export const PKG_DELETE = 'PKG_DELETE';
export const PKG_GET_STATUS = 'PKG_GET_STATUS';
export const PKG_GET_STATUS_RESPONSE = 'PKG_GET_STATUS_RESPONSE';
export const PKG_GET_MANIFEST = 'PKG_GET_MANIFEST';
export const PKG_GET_MANIFEST_RESPONSE = 'PKG_GET_MANIFEST_RESPONSE';
export const packageCreate = (payload) => action(PKG_CREATE, payload);
export const packageDelete = (payload) => action(PKG_DELETE, payload);

export const UI_PIN = 'UI_PIN';
export const UI_UNPIN = 'UI_UNPIN';
export const UI_UNPIN_ALL = 'UI_UNPIN_ALL';
export const UI_HIDE_ALL = 'UI_HIDE_ALL';
export const UI_HIDE = 'UI_HIDE';
export const UI_SHOW = 'UI_SHOW';
export const UI_SHOW_TOGGLE = 'UI_SHOW_TOGGLE';
export const UI_PIN_TOGGLE = 'UI_PIN_TOGGLE';
export const UI_SHOW_DIALOG = 'UI_SHOW_DIALOG';
export const UI_HIDE_DIALOG = 'UI_HIDE_DIALOG';
export const UI_SHOW_FATAL_ERROR = 'UI_SHOW_FATAL_ERROR';
export const UI_HIDE_FATAL_ERROR = 'UI_HIDE_FATAL_ERROR';
export const UI_UPDATE_PLAYBACK = 'UI_UPDATE_PLAYBACK';
export const uiHideAll = () => action(UI_HIDE_ALL);
export const uiHide = (payload) => action(UI_HIDE, payload);
export const uiShow = (payload) => action(UI_SHOW, payload);
export const uiShowToggle = (payload) => action(UI_SHOW_TOGGLE, payload);
export const uiUnpinAll = () => action(UI_UNPIN_ALL);
export const uiUnpin = (payload) => action(UI_UNPIN, payload);
export const uiPin = (payload) => action(UI_PIN, payload);
export const uiPinToggle = (payload) => action(UI_PIN_TOGGLE, payload);
export const uiShowDialog = (payload) => action(UI_SHOW_DIALOG, payload);
export const uiHideDialog = (payload) => action(UI_HIDE_DIALOG, payload);
export const uiShowFatalError = (payload) => action(UI_SHOW_FATAL_ERROR, payload);
export const uiHideFatalError = () => action(UI_HIDE_FATAL_ERROR);
export const uiUpdatePlayback = (payload) => action(UI_UPDATE_PLAYBACK, payload);

export const PROXIMITY_CLEAR_INSERT_QUEUE = 'PROXIMITY_CLEAR_INSERT_QUEUE';
export const PROXIMITY_CLEAR_REMOVE_QUEUE = 'PROXIMITY_CLEAR_REMOVE_QUEUE';
export const PROXIMITY_CLEAR_UPDATE_QUEUE = 'PROXIMITY_CLEAR_UPDATE_QUEUE';
export const PROXIMITY_CREATE_ENQUEUE = 'PROXIMITY_CREATE_ENQUEUE';
export const PROXIMITY_DELETE_ENQUEUE = 'PROXIMITY_DELETE_ENQUEUE';
export const PROXIMITY_UPDATE_ENQUEUE = 'PROXIMITY_UPDATE_ENQUEUE';
export const PROXIMITY_UPDATE_ID = 'PROXIMITY_UPDATE_ID';
export const PROXIMITY_CREATE = 'PROXIMITY_CREATE';
export const PROXIMITY_CREATE_RESPONSE = 'PROXIMITY_CREATE_RESPONSE';
export const PROXIMITY_DELETE = 'PROXIMITY_DELETE';
export const PROXIMITY_DELETE_RESPONSE = 'PROXIMITY_DELETE_RESPONSE';
export const PROXIMITY_GET = 'PROXIMITY_GET';
export const PROXIMITY_GET_RESPONSE = 'PROXIMITY_GET_RESPONSE';
export const PROXIMITY_REFRESH = 'PROXIMITY_REFRESH';
export const PROXIMITY_UPDATE = 'PROXIMITY_UPDATE';
export const PROXIMITY_UPDATE_RESPONSE = 'PROXIMITY_UPDATE_RESPONSE';
export const PROXIMITY_RESEQUENCE = 'PROXIMITY_RESEQUENCE';
export const getProximity = (payload) => action(PROXIMITY_GET, payload);
export const refreshProxmity = (payload) => action(PROXIMITY_REFRESH, payload);
export const createProximity = (payload) => action(PROXIMITY_CREATE, payload);
export const deleteProximity = (payload) => action(PROXIMITY_DELETE, payload);
export const updateProximity = (payload) => action(PROXIMITY_UPDATE, payload);
export const resequenceProximity = (payload) => action(PROXIMITY_RESEQUENCE, payload);
