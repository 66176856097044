import React, { Component } from 'react';
import * as utility from 'utility';
import styles from './Search.module.css';
import PropTypes from 'prop-types';
import SearchResults from './SearchResults';
import IconButton from '../IconButton';

export default class Search extends Component {
  constructor(props) {
    super(props);
    this.searchDebug = false;
  }

  componentDidUpdate = () => {
    if (this.props.search.results?.length !== this.results.length) {
      this.props.onSearchOptionsUpdate({
        results: this.results
      });
    }
  };

  render() {
    this.results = this.doSearch(
      this.props.poi.filtered?.length > 0
        ? this.props.poi.filtered
        : this.props.poi.all,
      this.props.search.options
    );
    this.results = this.sortResults(this.results, this.props.search.options);

    return (
      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <div
            className={
              this.props.timeFilterVisible
                ? `${styles.container} ${styles.offset}`
                : styles.container
            }
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <div
                className={
                  this.props.search.options.scope.length > 0 ||
                  this.props.search.options.caseSensitive ||
                  this.props.search.options.exactMatch
                    ? `${styles.searchScope} ${styles.highlight}`
                    : styles.searchScope
                }
              >
                <span style={{ margin: '0 .2rem 0 0' }}>
                  ({this.results.length})
                </span>
                {this.props.search.options.scope.length > 0
                  ? this.props.search.options.scope
                  : 'All fields'}
                {this.props.search.options.caseSensitive
                  ? ' (case sensitive)'
                  : ''}
                {this.props.search.options.exactMatch ? ' (exact match)' : ''}
              </div>
              <div>
                {!this.props.isPlaying && (
                  <IconButton
                    tooltip={'Apply search results to map'}
                    alternate={this.props.search.options.applyToMap}
                    onClick={this.toggleApplyToMap}
                    icon="filter"
                  />
                )}
                {!this.props.isPlaying && (
                  <IconButton
                    isDisabled={this.results.length === 0}
                    tooltip={'Playback search results'}
                    onClick={() => {
                      this.props.onPlay({
                        ...this.props.search,
                        results: this.results
                      });
                    }}
                    icon="play"
                  />
                )}
                {this.props.isPlaying && (
                  <div>
                    <IconButton
                      onClick={(e) => this.props.onStop(e)}
                      icon="stop"
                    />
                    <IconButton
                      onClick={(e) => this.props.onPause(e)}
                      icon="pause"
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={styles.search_input_results}>
              {this.props.isPlaying && (
                <div className={styles.playbackStatus}>
                  {this.props.isPaused ? '* PAUSED *' : 'Playing...'}
                </div>
              )}
              {!this.props.isPlaying && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <div>
                    <IconButton
                      onClick={this.props.onSearchReset}
                      icon="redo"
                    />
                  </div>
                  <div>
                    <input
                      value={this.props.search.options.keyword}
                      name="searchInput"
                      type="text"
                      onChange={this.onChange}
                    />
                  </div>
                  <div className={styles.latchButton}>
                    <div
                      title="Case Sensitive"
                      className={
                        this.props.search.options.caseSensitive
                          ? [styles.optionIcon, styles.on].join(' ')
                          : [styles.optionIcon, styles.off].join(' ')
                      }
                      data-id="case"
                      onClick={this.onChange}
                    >
                      Aa
                    </div>
                  </div>
                  <div className={styles.latchButton}>
                    <div
                      title="Exact Match"
                      className={
                        this.props.search.options.exactMatch
                          ? [styles.optionIcon, styles.on].join(' ')
                          : [styles.optionIcon, styles.off].join(' ')
                      }
                      data-id="exact"
                      onClick={this.onChange}
                    >
                      E
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`${styles.resultsDisplay} ${styles.search_results_table}`}
              >
                {this.results.length === 0 && (
                  <div className={styles.noneFound}>
                    No search results found
                  </div>
                )}
                {this.results.length > 0 && (
                  <div
                    style={{
                      height: this.props.timeFilterVisible
                        ? 'calc(100vh - 15.5rem)'
                        : 'calc(100vh - 12.5rem)'
                    }}
                    id="searchResultsContainer"
                    className={styles.resultsContainer}
                  >
                    <SearchResults
                      isPaused={this.props.isPaused}
                      isPlaying={this.props.isPlaying}
                      playPointer={this.props.playPointer}
                      search={this.props.search}
                      results={this.results}
                      onPause={(e) => this.props.onPause(e)}
                      additionalFields={this.props.poiFields?.filter(
                        (field) => field.appears_in_search_results === 'true'
                      )}
                      onNavigateTo={(e) =>
                        this.props.onNavigateToPoi(e.target.dataset.id)
                      }
                      onEdit={this.props.onEdit}
                      onSort={this.onChange}
                      onDelete={this.props.onDelete}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }

  onChange = (e) => {
    let options = this.props.search.options;
    if (e?.currentTarget.dataset.id) {
      options = {
        ...this.props.search.options,
        exactMatch:
          e.currentTarget.dataset.id === 'exact'
            ? !this.props.search.options.exactMatch
            : this.props.search.options.exactMatch,
        caseSensitive:
          e.currentTarget.dataset.id === 'case'
            ? !this.props.search.options.caseSensitive
            : this.props.search.options.caseSensitive,
        sortOn:
          e.currentTarget.dataset.id === 'sortOn'
            ? e.currentTarget.dataset.sorton
            : this.props.search.options.sortOn,
        isReversed:
          e.currentTarget.dataset.id === 'sortOn'
            ? !this.props.search.options.isReversed
            : this.props.search.options.isReversed
      };
    }

    const keyword =
      e.target.name === 'searchInput'
        ? e.target.value
        : this.props.search.options.keyword;

    let searchTerms = this._parseSearchTerms(keyword);

    this.props.onSearchOptionsUpdate({
      ...this.props.search,
      options: {
        ...options,
        scope:
          searchTerms.length > 0
            ? searchTerms[0].scope
            : this.props.search.options.scope,
        keyword
      }
    });
  };

  _parseSearchTerms = (searchInput) => {
    let searchTerms = [];
    searchInput.split('+').forEach((term) => {
      if (term.split(':').length > 1) {
        let scope = term.split(':')[0].trim();
        let keyword = term.split(':')[1].trim();
        searchTerms.push({ keyword: keyword, scope: scope });
      } else {
        searchTerms.push({
          keyword: term.trim(),
          scope: ''
        });
      }
    });
    return searchTerms;
  };

  doSearch = (poi, options) => {
    let validSearchScopes = [];
    if (this.props.poiFields)
      this.props.poiFields.forEach((field) => {
        validSearchScopes.push(field.search_scope);
      });

    let searchTerms = this._parseSearchTerms(options.keyword);

    let results = poi;
    if (searchTerms[0].keyword.length > 0)
      searchTerms.forEach((term) => {
        results = this._keywordSearch(
          results,
          term.keyword,
          term.scope,
          options
        );
      });

    return results;
  };

  sortResults(unsortedResults, options) {
    const displayFields = this.props.poiFields?.filter(
      (field) => field.appears_in_search_results === 'true'
    );
    let sortedResults = [];

    if (unsortedResults.length > 0) {
      unsortedResults.map((result, idx) => {
        if (typeof result.coordinate !== 'undefined') {
          sortedResults[idx] = result;
          let displayName;
          if (!utility.missingValue(result.name)) {
            displayName = result.name.replace(/(\r\n\t|\n|\r\t)/gm, '').trim();
          } else {
            displayName =
              result.coordinate !== null
                ? `${result.coordinate.lat},${result.coordinate.lng}`
                : 'untitled';
          }
          sortedResults[idx].displayName = displayName;
          if (displayFields)
            displayFields.map((field) => {
              sortedResults[idx][field.search_scope] = '';
              for (
                let x = 0;
                x < result.data?.length ? result.data.length : 0;
                x++
              ) {
                let item = result.data[x];
                if (item.field === field.name) {
                  sortedResults[idx][field.search_scope] = item.field_value;
                }
              }
              return null;
            });
        }
        return null;
      });
    }

    if (sortedResults.length > 0 && options.sortOn) {
      sortedResults.sort((a, b) => {
        let aa = a[options.sortOn];
        let bb = b[options.sortOn];
        if (!aa && !bb) {
          aa = a.data.find((item) => item.search_scope === options.sortOn);
          bb = b.data.find((item) => item.search_scope === options.sortOn);
          aa = aa ? aa.value : '';
          bb = bb ? bb.value : '';
        }
        aa = aa ? aa : '';
        bb = bb ? bb : '';
        return options.isReversed
          ? aa.localeCompare(bb, 'en-US-u-kn-true')
          : bb.localeCompare(aa, 'en-US-u-kn-true');
      });
    }
    return sortedResults;
  }

  toggleApplyToMap = () => {
    this.props.onSearchOptionsUpdate({
      options: {
        ...this.props.search.options,
        applyToMap: !this.props.search.options.applyToMap
      }
    });
  };

  _keywordSearch(searchSet, keyword, scope, options) {
    this._showDebugInfo(keyword, scope);
    let results = [];
    searchSet.forEach((thisPoi) => {
      let foundMatch = [];
      if (
        scope.length === 0 &&
        utility.compareWithOptions(
          thisPoi.name,
          keyword,
          options.caseSensitive,
          options.exactMatch
        )
      ) {
        results.push({ ...thisPoi, matchedOn: 'name' });
        foundMatch.push(thisPoi.id);
      }

      if (!foundMatch.includes(thisPoi.id)) {
        if (thisPoi.data)
          thisPoi.data.forEach((datum) => {
            if (!foundMatch.includes(thisPoi.id)) {
              if (
                (scope.length === 0 || datum.search_scope === scope) &&
                utility.compareWithOptions(
                  datum.value ? datum.value.trim() : datum.value,
                  keyword ? keyword.trim() : keyword,
                  options.caseSensitive,
                  options.exactMatch
                )
              ) {
                results.push({
                  ...thisPoi,
                  matchedOn: datum.search_scope
                });
                foundMatch.push(thisPoi.id);
              }
            }
          });
      }
    });
    return results;
  }

  _showDebugInfo = (keyword, scope) => {
    if (this.searchDebug) {
      console.log('--------------------------');
      console.log('Search on: ' + JSON.stringify(keyword));
      console.log('Scope: ' + scope);
      console.log(
        `Case sensitive?: ${
          this.props.search.options.caseSensitive ? 'yes' : 'no'
        }`
      );
      console.log(
        `Exact match?: ${this.props.search.options.exactMatch ? 'yes' : 'no'}`
      );
    }
  };
}

Search.defaultProps = {
  isPlaying: false,
  playPointer: 0,
  isFiltered: false
};

Search.propTypes = {
  onSearchReset: PropTypes.func.isRequired,
  onSearchOptionsUpdate: PropTypes.func.isRequired,
  isFiltered: PropTypes.bool,
  isPinned: PropTypes.bool,
  onPin: PropTypes.func,
  isPaused: PropTypes.bool,
  isPlaying: PropTypes.bool,
  playPointer: PropTypes.number,
  onPause: PropTypes.func,
  onStop: PropTypes.func,
  onPlay: PropTypes.func,
  onNavigateToPoi: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  poiFields: PropTypes.array,
  poi: PropTypes.object.isRequired,
  search: PropTypes.object.isRequired,
  timeFilterVisible: PropTypes.bool
};
