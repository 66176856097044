import { all } from 'redux-saga/effects';

// POI Sagas
import * as actions from 'state/actions';
import { select, put } from 'redux-saga/effects';
import * as helper from './sagaHelper.js';
import CONST from 'constants.js';

export const setPoi = function* (action) {
  const redux = yield select();
  const poiId = action.payload;
  const currentPoi = redux.poi.all?.find((item) => item.id === poiId);
  let mediaId = currentPoi?.data.find(
    (item) => item.field?.toLowerCase() === 'media'
  );
  mediaId = mediaId?.value ? parseInt(mediaId.value, 10) : -1;
  const media = redux.media.available.media?.find(
    (item) => item.id === mediaId
  );
  if (redux.app.isFramed) {
    const payload = {
      config: currentPoi?.presentation_config,
      currentPoi,
      media
    };
    window.parent.postMessage(payload, '*');
  }
};

export const getPOI = function* (action) {
  switch (window._env_.REACT_APP_MODE) {
    case CONST.APP_MODE.STANDALONE:
    case CONST.APP_MODE.DEMO:
      console.error(
        "WARNING: Only API mode is supported, removed at git tag'removeStandalone'"
      );
      break;
    default:
    case CONST.APP_MODE.API: {
      const reduxState = yield select();
      try {
        let response;
        let filterString = '';
        let url;
        if (
          typeof action.payload !== 'undefined' &&
          typeof action.payload.filter_min !== 'undefined' &&
          action.payload.filter_min.length > 0
        ) {
          filterString = `&poi_date=gte.${action.payload.filter_min}&poi_date=lte.${action.payload.filter_max}`;
        }
        if (reduxState.user.token && reduxState.user.token.length !== 0) {
          url = `s_poi_with_data?map_id=eq.${reduxState.app.id}${filterString}`;
          response = yield helper.signedAPI('GET', url, reduxState.user.token);
        } else {
          url = `poi_with_data?map_id=eq.${reduxState.app.id}${filterString}`;
          response = yield helper.anonymousAPI('GET', url);
        }
        let response_json = yield response.json();
        yield put({ type: actions.POI_GET_RESPONSE, payload: response_json });
      } catch (e) {
        yield put({
          type: actions.APP_SAGA_ERROR,
          payload: {
            error: e,
            source: action.type
          }
        });
      }
      break;
    }
  }
};

export const getSelectionOptions = function* (action) {
  switch (window._env_.REACT_APP_MODE) {
    case CONST.APP_MODE.STANDALONE:
    case CONST.APP_MODE.DEMO:
      console.error(
        "WARNING: Only API mode is supported, removed at git tag'removeStandalone'"
      );
      break;
    case CONST.APP_MODE.API:
    default: {
      const reduxState = yield select();
      if (!reduxState.user.token || reduxState.user.token.length === 0) return;

      try {
        const reduxState = yield select();
        const response = yield helper.signedAPI(
          'GET',
          `s_selection_options?map_id=eq.${reduxState.app.id}`,
          reduxState.user.token
        );

        let response_json = yield response.json();
        yield put({
          type: actions.POI_GET_SELECTION_OPTIONS_RESPONSE,
          payload: response_json
        });
      } catch (e) {
        yield put({
          type: actions.APP_SAGA_ERROR,
          payload: {
            error: e,
            source: action.type
          }
        });
      }
      break;
    }
  }
};

export const refreshPoi = function* (action) {
  const reduxState = yield select();
  yield put({ type: actions.POI_GET, payload: action.payload });
  yield put({
    type: actions.POI_GET_SELECTION_OPTIONS,
    payload: action.payload
  });
  yield put({
    type: actions.AUDIOTOUR_GET_ITEMS,
    payload: reduxState.app.id
  });
};

export const setDefaultStyle = function (action) {
  console.warn('ERROR: SET DEFAULT STYLE IS BROKEN');
};

export const recordChanges = function* (action) {
  const reduxState = yield select();
  yield all(
    reduxState.poi.needsInsert.map((poi) => {
      return put({
        type: actions.API_PERFORM_CRUD,
        payload: {
          isInsert: true,
          placeholderId: poi.id,
          method: 'POST',
          endpoint: `s_poi`,
          data: {
            map_id: reduxState.app.id,
            name: poi.name,
            coordinate: poi.coordinate,
            date: poi.date,
            representation_id: poi.representation_id,
            representation_config: poi.representation_config,
            presentation_id: poi.presentation_id,
            presentation_config: poi.presentation_config
          },
          type: action.type
        }
      });
    })
  );
  yield put({ type: actions.POI_CLEAR_INSERT_QUEUE });

  yield all(
    reduxState.poi.needsRemoval.map((poi) => {
      return put({
        type: actions.API_PERFORM_CRUD,
        payload: {
          method: 'DELETE',
          endpoint: `s_poi?id=eq.${poi.id}`,
          data: {},
          type: action.type
        }
      });
    })
  );
  yield put({ type: actions.POI_CLEAR_REMOVE_QUEUE });

  for (let idx = 0; idx < reduxState.poi.needsUpdate.length; idx++) {
    const poi = reduxState.poi.needsUpdate[idx];
    let payload = {
      id: poi.id,
      name: poi.name,
      date: poi.date,
      coordinate: poi.coordinate,
      map_id: reduxState.app.id,
      representation_id: poi.representation_id,
      representation_config: poi.representation_config,
      presentation_id: poi.presentation_id,
      presentation_config: poi.presentation_config,
      data: []
    };
    for (let idx2 = 0; idx2 < poi.data?.length; idx2++) {
      const thisPoi = poi.data[idx2];
      payload.data.push({
        poi_id: thisPoi.poiId,
        is_locked: thisPoi.is_locked,
        is_published: thisPoi.is_published,
        is_markdown: thisPoi.is_markdown,
        value: thisPoi.value,
        data_type_id: thisPoi.dataType
      });
    }
    yield put({
      type: actions.API_PERFORM_CRUD,
      payload: {
        method: 'POST',
        endpoint: 'rpc/update_poidata',
        type: action.type,
        data: { payload }
      }
    });
  }
  yield put({ type: actions.POI_CLEAR_UPDATE_QUEUE });
};

export const resequencePoi = function* (action) {
  yield put({
    type: actions.API_PERFORM_CRUD,
    payload: {
      method: 'POST',
      endpoint: 'rpc/resequence_poi',
      type: action.type,
      data: {
        payload: { data: action.payload }
      }
    }
  });
};
