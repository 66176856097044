import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Button.module.css';

class Button extends Component {
  onClick = (e) => {
    if (!this.props.isDisabled && typeof this.props.onClick === 'function') {
      e.stopPropagation();
      this.props.onClick(e);
    }
  };
  render() {
    return (
      <div
        style={this.props.style}
        onClick={this.onClick}
        data-id={this.props.id}
        className={`${
          this.props.isBorderless ? styles.buttonBorderless : styles.button
        } ${this.props.isDisabled ? styles.disabled : ''}`}
      >
        {this.props.children}
      </div>
    );
  }
}
export default Button;

Button.defaultProps = {
  isBorderless: false,
  isDisabled: false
};
Button.propTypes = {
  isBorderless: PropTypes.bool,
  children: PropTypes.node,
  isDisabled: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  id: PropTypes.string
};
