import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './TileserverSelector.module.css';

class TileserverSelector extends Component {
  render() {
    let serverBlock = [];
    if (!this.props.availableServers) return null;
    this.props.availableServers
      .sort((a, b) => a.id - b.id)
      .forEach((server) => {
        let previewUrl = server.url
          ? server.url
              .replace(/{s}/, 'a')
              .replace(/{z}/g, '3')
              .replace(/{x}|{y}+/g, '2')
          : null;

        serverBlock.push(
          <div
            onClick={this.onClick}
            data-id={this.props.id}
            data-value={server.id}
            className={styles.serverBlock}
            key={server.id}
          >
            <div
              className={
                server.id === this.props.value
                  ? `${styles.image} ${styles.selected}`
                  : styles.image
              }
            >
              <img alt={''} src={previewUrl} />
            </div>
            <div>{server.slug}</div>
          </div>
        );
      });
    return <div className={styles.container}>{serverBlock}</div>;
  }
  onClick = (e) => {
    this.props.onChange({
      id: this.props.id,
      value: parseInt(e.currentTarget.dataset.value, 10)
    });
  };
}

export default TileserverSelector;

TileserverSelector.defaultProps = {};

TileserverSelector.propTypes = {
  availableServers: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedID: PropTypes.number,
  value: PropTypes.any
};
