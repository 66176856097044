import React, { useState } from 'react';
import PropTypes from 'prop-types';
import colors from 'colors';
const iconContainerSize = '2rem';
const iconSize = '1.5rem';
const TabSet = (props) => {
  const childArray = React.Children.toArray(props.children);
  const [selectedTab, setSelectedTab] = useState(
    props.selected ? props.selected : 0
  );

  return (
    <div>
      <div
        style={{
          ...styles.container,
          ...props.styles?.container
        }}
      >
        {childArray.map((tab, idx) => {
          const thisSelected = selectedTab === idx;
          const tabStyle = thisSelected
            ? {
                ...styles.tab,
                ...styles.tabSelected,
                ...props.styles?.tab,
                ...props.styles?.tabSelected
              }
            : {
                ...styles.tab,
                ...props.styles?.tab
              };
          const iconClass = `fas fa-${tab.props.icon}`;
          return (
            <div
              key={idx}
              onClick={() => {
                setSelectedTab(idx);
                if (props.onSelect) props.onSelect(idx);
              }}
              style={tabStyle}
            >
              <div>
                {(tab.props.icon && (
                  <div style={styles.rowIcon}>
                    {(tab.props.icon.includes('http') && (
                      <img
                        style={{ height: iconContainerSize }}
                        src={tab.props.icon}
                        alt={tab.props.name}
                      />
                    )) || <div style={styles.faIcon} className={iconClass} />}
                  </div>
                )) || (
                  <div
                    style={{ ...styles.rowIcon, borderColor: 'transparent' }}
                  />
                )}
              </div>
              <div>{tab.props.label ? tab.props.label : `Tab ${idx + 1}`}</div>
            </div>
          );
        })}
        <div style={{ ...styles.tabFill, ...props.styles?.tabFill }} />
      </div>
      <div
        style={{ borderTop: '0px solid black', padding: props.styles?.padding }}
      >
        {childArray[selectedTab]?.props.children}
      </div>
    </div>
  );
};

export default TabSet;

// We only care about the props which we parse above
// eslint-disable-next-line no-unused-vars
const Tab = (props) => {
  return null;
};

const styles = {
  container: {
    fontFamily: colors.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  tab: {
    backgroundColor: '#dddddd',
    border: '1px solid black',
    borderBottom: '1px solid black',
    borderRadius: '0.3rem 0.3rem 0 0',
    width: '5rem',
    margin: '0',
    padding: '.5rem',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'flex',
    flexDirection: 'row'
  },
  tabSelected: { backgroundColor: 'white', borderBottom: '1px solid white' },
  tabFill: {
    borderBottom: '1px solid black',
    height: '2.5rem',
    flexGrow: 1
  },
  rowIcon: {
    width: iconContainerSize,
    minWidth: iconContainerSize,
    textAlign: 'center',
    borderRadius: iconContainerSize,
    height: iconContainerSize,
    border: '1px solid darkgrey',
    overflow: 'hidden',
    margin: '0 1rem 0 1rem'
  },
  faIcon: {
    fontSize: iconSize,
    lineHeight: iconContainerSize,
    color: colors.primary
  }
};

export { TabSet, Tab };

TabSet.defaultProps = {
  scrollTop: 0,
  showArrow: true,
  resetToTop: false
};

Tab.propTypes = {
  label: PropTypes.string.isRequired,
  selected: PropTypes.string
};

TabSet.propTypes = {
  styles: PropTypes.object,
  onSelect: PropTypes.func,
  items: PropTypes.array,
  selected: PropTypes.number,
  children: PropTypes.node
};
