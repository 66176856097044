import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TileserverSelector } from 'components';
const Tileselect = (props) => {
  const availableServers = useSelector(
    (redux) => redux.app.available_tileservers
  );

  return (
    <TileserverSelector
      id="map_tileserverId"
      onChange={props.onChange}
      value={props.value}
      availableServers={availableServers}
    />
  );
};

Tileselect.defaultProps = {
  isPublished: true,
  onChange: () => {}
};

Tileselect.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  id: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.object
};

export default Tileselect;
