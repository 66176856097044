import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Inspector from './Inspector';
import Video360 from './Video360.js';
import InlineVideo from './InlineVideo.js';
import Website from './Website.js';
import videoStyles from './videoStyles.js';

import * as actions from 'state/actions';
import CONST from 'constants.js';

export const Presentation = (props) => {
  const dispatch = useDispatch();
  const reduxState = useSelector((redux) => redux);
  const isFramed = useSelector((redux) => redux.app.isFramed);
  const prefs = useSelector((redux) => redux.app.prefs);
  const availableMedia = useSelector((redux) => redux.media.available.media);

  const currentPoi = useSelector((redux) =>
    redux.poi.all?.find((item) => item.id === redux.poi.current?.id)
  );

  const mediaId = currentPoi?.data.find(
    (item) => item.field?.toLowerCase() === 'media'
  )?.value;

  const media = availableMedia?.find(
    (item) => item.id === parseInt(mediaId, 10)
  );

  const thisPresentation = reduxState.app.presentation_options.find(
    (rep) =>
      rep.id ===
      parseInt(
        currentPoi?.presentation_id ? currentPoi?.presentation_id : 1,
        10
      )
  );
  if (isFramed || !currentPoi || !thisPresentation) return null;

  const onOutsideClick = () =>
    dispatch(actions.uiHide(CONST.UI.POI_PRESENTATION));
  let displayAs = <Inspector />;
  if (reduxState.user.isAuthenticated) {
    displayAs = <Inspector />;
  } else {
    const split = prefs.presentation_padding.value.split(' ');
    let padding = [];
    for (let x = 0; x < 4; x++) {
      padding[x] = split[x] ? parseFloat(split[x]) : 0;
    }

    const hPad = padding[1] + padding[3];
    const vPad = padding[0] + padding[2];
    const applyPadding = (styles) => {
      return {
        ...styles,
        outerContainer: {
          ...styles.outerContainer,
          padding: `${padding[0]}px ${padding[1]}px ${padding[2]}px ${padding[3]}px`
        },
        innerContainer: {
          ...styles.innerContainer,
          maxHeight: `calc(${styles.innerContainer.maxHeight} - ${vPad}px)`,
          maxWidth: `calc(${styles.innerContainer.maxWidth} - ${hPad}px)`
        }
      };
    };
    const styles = {
      ...applyPadding(videoStyles)
    };

    switch (thisPresentation.id) {
      default:
      case 1:
        displayAs = <Inspector />;
        break;
      case 2: {
        displayAs = (
          <Video360
            {...currentPoi.presentation_config}
            currentPoi={currentPoi}
            availableMedia={availableMedia}
            media={media}
            onOutsideClick={onOutsideClick}
            styles={styles}
          />
        );
        break;
      }
      case 3: {
        displayAs = (
          <InlineVideo
            {...currentPoi.presentation_config}
            currentPoi={currentPoi}
            media={media}
            onOutsideClick={onOutsideClick}
            styles={styles}
          />
        );
        break;
      }
      case 4:
        displayAs = (
          <Website
            {...currentPoi.presentation_config}
            currentPoi={currentPoi}
            onOutsideClick={onOutsideClick}
            styles={styles}
          />
        );
        break;
    }
  }
  return <div key={currentPoi.id}>{displayAs}</div>;
};
export default Presentation;

Presentation.defaultProps = {};
Presentation.propTypes = {};
