import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ImagePreview.module.css';
import { Modal } from 'components';
import IconButton from 'components/IconButton';
const rotateExif = (exif, setRotation) => {
  if (!exif) return;
  /*
      1 0 degrees – the correct orientation, no adjustment is required.
      2 0 degrees, mirrored – image has been flipped back-to-front.
      3 180 degrees – image is upside down.
      4 180 degrees, mirrored – image is upside down and flipped back-to-front.
      5 90 degrees – image is on its side.
      6 90 degrees, mirrored – image is on its side and flipped back-to-front.
      7 270 degrees – image is on its far side.
      8 270 degrees, mirrored – image is on its far side and flipped back-to-front.
    */
  let orientation = exif && exif.Orientation ? exif.Orientation : 0;
  let rot = 0;
  switch (orientation) {
    case 1:
    case 2:
    default:
      rot = 0;
      break;
    case 3:
    case 4:
      rot = 180;
      break;
    case 5:
    case 6:
      rot = 90;
      break;
    case 7:
    case 8:
      rot = 270;
      break;
  }
  setRotation(rot);
};

const copyTextToClipboard = (text) => {
  var textArea = document.createElement('textarea');
  // Place in the top-left corner of screen regardless of scroll position.
  textArea.style.position = 'fixed';
  textArea.style.top = 0;
  textArea.style.left = 0;

  // Ensure it has a small width and height. Setting to 1px / 1em
  // doesn't work as this gives a negative w/h on some browsers.
  textArea.style.width = '2em';
  textArea.style.height = '2em';

  // We don't need padding, reducing the size if it does flash render.
  textArea.style.padding = 0;

  // Clean up any borders.
  textArea.style.border = 'none';
  textArea.style.outline = 'none';
  textArea.style.boxShadow = 'none';

  // Avoid flash of the white box if rendered for any reason.
  textArea.style.background = 'transparent';
  textArea.value = text;

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    console.error('Unable to copy');
    console.error(err);
  }

  document.body.removeChild(textArea);
};

const ImagePreview = ({ src, id, exif }) => {
  const [imageIsBroken, setImageIsBroken] = useState(false);
  const [zoom, setZoom] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isZoomLoaded, setIsZoomLoaded] = useState(false);
  const [rotation, setRotation] = useState(0);

  useEffect(() => {
    console.log(src);
    setRotation(0);
    setZoom(false);
    setImageIsBroken(false);
    setIsLoaded(false);
    setIsZoomLoaded(false);
  }, [src]);
  const extension = src.split('.').slice(-1)[0].toLowerCase();
  const isPdf = extension === 'pdf';
  let placeholder =
    src && src.length > 0 ? (
      <div
        className={styles.spinner}
        style={!isZoomLoaded ? {} : { display: 'none' }}
      >
        <div className={styles.doublebounce1} />
        <div className={styles.doublebounce2} />
      </div>
    ) : (
      <div className={styles.missing} />
    );
  return (
    <div
      className={styles.container}
      onClick={() => {
        if (isPdf) {
          window.open(src, '_blank');
        } else {
          setZoom(true);
        }
      }}
    >
      {zoom && (
        <Modal
          scrollable={false}
          backgroundColor="rgba(0, 0, 0, 0.74)"
          naturalWidth={true}
          onOutsideClick={() => setZoom(false)}
        >
          {!isLoaded && placeholder}
          <img
            alt={src}
            key={id}
            id={`previewImage_${id}`}
            className={styles.image}
            style={
              isLoaded
                ? {
                    height: '90vh',
                    objectFit: 'contain',
                    transform: `rotate(${rotation}deg)`
                  }
                : { display: 'none' }
            }
            onLoad={() => rotateExif(exif, (r) => setRotation(r))}
            src={src}
          />
        </Modal>
      )}
      {!isLoaded && placeholder}
      <div
        style={
          isLoaded
            ? {
                width: '100%',
                backgroundColor: '#0000002e'
              }
            : {}
        }
      >
        {isLoaded && (
          <IconButton
            isBorderless={true}
            dataset={{
              'data-mediaid': id
            }}
            style={{
              position: 'absolute',
              zIndex: 1,
              margin: '0.5rem',
              background: '#00000036',
              padding: '.2rem'
            }}
            icon="link"
            onClick={() => copyTextToClipboard(src)}
          />
        )}
        <img
          alt={src}
          key={id}
          id={`previewImage_${id}`}
          className={styles.image}
          style={
            isLoaded
              ? {
                  width: '100%',
                  height: '100%',
                  objectFit: 'fit',
                  transform: `rotate(${rotation}deg)`
                }
              : { display: 'none' }
          }
          onLoad={() => {
            setIsLoaded(true);
            rotateExif(exif, (r) => setRotation(r));
          }}
          onError={() => setImageIsBroken(true)}
          src={
            imageIsBroken
              ? isPdf
                ? 'icons/pdf.png'
                : 'icons/missing.png'
              : src
          }
        />
      </div>
    </div>
  );
};
export default ImagePreview;

ImagePreview.defaultProps = {
  exif: null
};

ImagePreview.propTypes = {
  id: PropTypes.number,
  src: PropTypes.string.isRequired,
  exif: PropTypes.object
};
