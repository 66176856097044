import colors from 'colors';
export default {
  outerContainer: {
    zIndex: 1,
    position: 'fixed',
    top: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    width: '100vw',
    backgroundColor: `#00000088`
  },
  innerContainer: {
    zIndex: 1,
    borderRadius: '0.3rem',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'black',
    boxShadow: colors.shadow,
    overflow: 'scroll',
    width: '99vw',
    height: '99vh',
    maxWidth: '60rem',
    maxHeight: '60rem'
  },
  titleAndCaption: {
    flexGrow: '1',
    padding: '0.5rem',
    backgroundColor: '#d7d7d7',
    height: '3rem'
  },
  title: {
    fontSize: '1.5rem',
    fontWeight: '900',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  youtubeLauncher: {
    backgroundColor: '#cccccc',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    bottom: '0',
    padding: '1rem',
    flexDirection: 'column',
    height: '20rem'
  },
  caption: {
    textAlign: 'left',
    margin: '0',
    fontSize: '1rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  previewImage: {
    cursor: 'pointer',
    border: '1px solid black',
    height: '10rem',
    width: '10rem',
    borderRadius: '10rem',
    overflow: 'hidden',
    display: 'flex',
    backgroundColor: 'white',
    marginBottom: '1rem'
  },
  backlink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0px auto',
    minWidth: '4rem',
    maxWidth: '4rem',
    width: '4rem',
    fontWeight: '900',
    color: 'rgb(34 34 34 / 61%)',
    fontSize: '.7rem',
    whiteSpace: 'nowrap'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.modalBackground,
    height: '4rem',
    minHeight: '4rem',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  playerBox: {
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    width: '100%'
  }
};
