import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { makeAssetURL } from 'utility';
import { ImagePreview } from 'components';

const MediaPlayer = (props) => {
  if (props.media.type.includes('image') || props.media.type.includes('pdf')) {
    return (
      <ImagePreview
        exif={props.media.exif}
        id={props.media.id}
        src={makeAssetURL(props.media.url)}
      />
    );
  } else {
    if (props.media.url.length === 0)
      return (
        <ImagePreview
          exif={props.media.exif}
          id={props.media.id}
          src="icons/missing.png"
        />
      );
    return (
      <ReactPlayer
        height={props.media.type.includes('audio') ? '2rem' : '100%'}
        width="100%"
        url={makeAssetURL(props.media.url)}
        controls
      />
    );
  }
};

MediaPlayer.propTypes = {
  media: PropTypes.object
};

export default MediaPlayer;
