import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.css';
import { WatchForOutsideClick } from 'components';

class Modal extends Component {
  render() {
    let innerClass = this.props.naturalWidth
      ? styles.innerNatural
      : styles.inner;
    innerClass = this.props.scrollable
      ? `${innerClass} ${styles.scrollable}`
      : innerClass;

    return (
      <div
        className={styles.outer}
        style={{ ...this.props.style, background: this.props.backgroundColor }}
      >
        <div className={innerClass}>
          <WatchForOutsideClick onOutsideClick={this.props.onOutsideClick}>
            {this.props.children}
          </WatchForOutsideClick>
        </div>
      </div>
    );
  }
}
export default Modal;

Modal.defaultProps = {
  scrollable: true,
  backgroundColor: '#ffffff4f',
  naturalWidth: false,
  confirmLabel: 'Ok',
  cancelLabel: 'Cancel',
  outsideClickConfirm: false
};

Modal.propTypes = {
  style: PropTypes.object,
  backgroundColor: PropTypes.string,
  naturalWidth: PropTypes.bool,
  scrollable: PropTypes.bool,
  children: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onOutsideClick: PropTypes.func,
  outsideClickConfirm: PropTypes.bool
};
