import initialState from './init/app';
import * as actions from 'state/actions';
import { sanitizeTag } from 'utility';

const getTileLayerFromID = (availableServers, id) => {
  let found;
  availableServers.forEach((tileserver) => {
    if (tileserver.id === id) {
      found = tileserver;
    }
  });
  return found;
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.APP_UPDATE_DRAWING:
      return {
        ...state,
        drawing: { ...state.drawing, [action.payload.id]: action.payload.data }
      };
    case actions.APP_CLEAR_DRAWING:
      return {
        ...state,
        drawing: []
      };
    case actions.APP_SET_UI_MODE:
      return {
        ...state,
        uiMode: action.payload
      };
    case actions.APP_SET_MAP_OBJECT:
      return {
        ...state,
        map: action.payload
      };
    case actions.APP_SYNCH_BEGIN:
      return {
        ...state,
        synchInProgress: true
      };

    case actions.APP_SYNCH_END_DEBOUNCED:
      return {
        ...state,
        synchInProgress: false
      };

    case actions.APP_ERROR_SET:
      return {
        ...state,
        error: {
          hasError: true,
          message: action.payload
        }
      };

    case actions.APP_ERROR_CLEAR:
      return {
        ...state,
        error: {
          hasError: false,
          message: ''
        }
      };

    case actions.POI_UPDATE:
    case actions.POI_DELETE:
    case actions.POI_CREATE:
      return { ...state, synchInProgress: true };

    case actions.APP_UPDATE_PREFS_LIVE: {
      let newPrefs = {};
      Object.keys(state.prefs).forEach((pref) => {
        newPrefs[pref] = state.prefs[pref];
        if (Object.keys(action.payload).includes(pref))
          newPrefs[pref].value = action.payload[pref];
      });

      return { ...state, prefs: newPrefs };
    }

    case actions.USER_GET_RESPONSE:
      return { ...state, users: action.payload.response };

    case actions.APP_EMPTY_INITIALIZATION_RECEIVED: {
      let prefs = { ...state.prefs };
      Object.keys(state.prefs).forEach((pref) => {
        prefs[pref].value = prefs[pref].default;
      });
      return {
        ...state,
        initWasEmpty: true,
        is_published: false,
        isInitialized: true,
        prefs
      };
    }

    case actions.APP_INITIALIZE: {
      // Parse specific query params, enforcing defanging and datatype
      const searchParams = new URLSearchParams(window.location.search);
      let queryParams = {};
      for (var pair of searchParams.entries()) {
        const key = pair[0].toLowerCase().trim();
        const val = pair[1].toLowerCase().trim();
        if (key === 'poi') queryParams['poi'] = parseInt(val, 10);
        if (key === 'bounds') queryParams['bounds'] = sanitizeTag(val);
        if (key === 'tags') {
          const tagSets = val.split(' ');
          queryParams['tags'] = [];
          tagSets.forEach((tSet) => {
            queryParams['tags'].push(sanitizeTag(tSet)?.split(','));
          });
        }
      }
      return { ...state, queryParams };
    }

    case actions.APP_INITIALIZATION_RECEIVED: {
      let payload = action.payload[0];

      if (payload.preferences === null)
        console.error('DB MISCONFIGURED: Missing preferences');

      let selectedTileServer = getTileLayerFromID(
        payload.available_tileservers,
        payload.tileserver_id
      );

      // Load configs from static files
      const setDefault = (repOption) => {
        repOption.default = {};
        if (repOption.template)
          Object.keys(repOption.template.options).forEach((opt) => {
            repOption.default[opt] = {
              value:
                repOption.template.options[opt].validation === 'float'
                  ? parseFloat(repOption.template.options[opt].default)
                  : repOption.template.options[opt].validation === 'integer'
                  ? parseInt(repOption.template.options[opt].default)
                  : repOption.template.options[opt].default,
              options: repOption.template.options[opt]
            };
            repOption.default[
              opt
            ].options.isEditable = repOption.template.editableOptions.find(
              (el) => el === opt
            )
              ? true
              : false;
          });
      };
      payload.representation_options = require(`config/poiRepresentation.json`);
      payload.representation_options.forEach(setDefault);
      payload.presentation_options = require(`config/poiPresentation.json`);
      payload.presentation_options.forEach(setDefault);

      // Apply field formats
      const poiFormats = require(`config/poiDatatypeFormat.json`);
      if (payload.poi_fields)
        payload.poi_fields.forEach((field) => {
          field.format = poiFormats.find(
            (format) => format.id === field.format_id
          );
        });

      // Preferences
      let prefs = { ...state.prefs };
      Object.keys(state.prefs).forEach((pref) => {
        if (payload.preferences[pref]?.label) {
          console.log('Prefs: Converting old style');
          prefs[pref].value =
            typeof payload.preferences[pref] !== 'undefined'
              ? payload.preferences[pref].value
              : prefs[pref].default;
        } else {
          prefs[pref].value =
            typeof payload.preferences[pref] !== 'undefined'
              ? payload.preferences[pref]
              : prefs[pref].default;
        }
      });
      prefs.isPublished.value = payload.is_published;
      prefs.title.value = payload.title;
      prefs.uuid.value = payload.uuid;
      delete payload.preferences;
      delete payload.title;
      delete payload.uuid;
      delete payload.tileserver_id;
      delete payload.tileserver_url;

      return {
        ...state,
        ...payload,
        isFramed: window.self !== window.top,
        initWasEmpty: false,
        synchInProgress: false,
        prefs,
        isInitialized: true,
        mode: window._env_.REACT_APP_MODE,
        tileLayer: {
          ...state.tileLayer,
          attribution: selectedTileServer.attribution,
          url: selectedTileServer.url
        }
      };
    }

    case actions.LOCATION_ENABLE:
      return {
        ...state,
        userLocation: { ...state.userLocation, currentLocation: null }
      };

    case actions.LOCATION_DISABLE:
      return {
        ...state,
        userLocation: { ...state.userLocation, currentLocation: null }
      };

    case actions.LOCATION_CLEAR_CURRENT:
      return {
        ...state,
        userLocation: { ...state.userLocation, currentLocation: null }
      };

    case actions.LOCATION_SET_CURRENT:
      return {
        ...state,
        userLocation: {
          ...state.userLocation,
          currentLocation: action.payload.location,
          watchID: action.payload.watchID
        }
      };

    case actions.APP_SET_PROGRESS_PERCENTAGE:
      return {
        ...state,
        progressPercentage: action.payload.percentage
      };

    case actions.APP_LOGOUT:
      return {
        ...state,
        isLocked: true
      };

    case actions.TIMEFILTER_SET_RANGE:
      return {
        ...state
      };

    case actions.APP_TOGGLE_MAPLOCK:
      localStorage.setItem('maplock', JSON.stringify(action.payload));
      return {
        ...state,
        isLocked: action.payload
      };

    case actions.APP_LOCK_MAP:
      localStorage.setItem('maplock', JSON.stringify(true));
      return {
        ...state,
        isLocked: true
      };

    case actions.APP_SAGA_ERROR:
      return {
        ...state,
        hasError: true
      };

    default:
      return state;
  }
};
