import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Pagination.module.css';
class Pagination extends Component {
  render() {
    return (
      <div className={styles.container}>
        <div
          onClick={() => {
            if (this.props.currentID > 1) {
              this.props.onChange(this.props.currentID - 1);
            }
          }}
          className={`${styles.previous_button} ${
            this.props.currentID === 1 ? styles.disabled : ''
          }`}
        >
          Previous
        </div>
        <div
          onClick={() => {
            if (this.props.currentID < this.props.availableMaps.length) {
              this.props.onChange(this.props.currentID + 1);
            }
          }}
          className={`${styles.next_button} ${
            this.props.currentID === this.props.availableMaps.length
              ? styles.disabled
              : ''
          }`}
        >
          Next
        </div>
      </div>
    );
  }
}
export default Pagination;

Pagination.defaultProps = {
  isBorderless: false
};
Pagination.propTypes = {
  currentID: PropTypes.number,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  availableMaps: PropTypes.array
};
