import CONST from 'constants.js';
const localDebug = false;

export const signed = (method, url, token, body = {}) => {
  if (CONST.APP_MODE.DEMO === window._env_.REACT_APP_MODE) {
    console.error(
      `DEMO MODE: Refusing to honor SIGNED ${method} ${url}, this request should not be made.`
    );
    return {};
  }
  let request = {
    method: method,
    headers: {
      Authorization: 'Bearer ' + token,
      'Content-Type': 'application/json; charset=utf-8',
      Prefer: method === 'POST' ? 'return=representation' : ''
    },
    body:
      typeof body === 'string'
        ? body
        : JSON.stringify(body).replace(/\\u0000/g, '')
  };
  if (!body || method === 'GET') delete request.body;
  return fetch(url, request);
};

export const anonymous = (method, url, body) => {
  if (CONST.APP_MODE.DEMO === window._env_.REACT_APP_MODE) {
    console.error(
      `DEMO MODE: Refusing to honor ANON ${method} ${url}, this request should not be made.`
    );
    return {};
  }
  let request = {
    method: method,
    headers: CONST.ANON_HEADERS,
    body: typeof body === 'string' ? body : JSON.stringify(body)
  };
  if (!body || method === 'GET') delete request.body;
  return fetch(url, request);
};

export const embedded = (id, url) => {
  url = `/${id}/assets/${url}`;
  return anonymous('GET', url);
};

export const anonymousAPI = (method, url, body) => {
  url = `${window._env_.REACT_APP_BASE_URL}/api/${url}`;
  return anonymous(method, url, body);
};

export const signedAPI = (method, url, token, body) => {
  url = `${window._env_.REACT_APP_BASE_URL}/api/${url}`;
  return signed(method, url, token, body);
};

export const anonymousServer = (method, url, body) => {
  url = localDebug
    ? `http://127.0.0.1:8888/${url}`
    : `${window._env_.REACT_APP_BASE_URL}/server/${url}`;
  return anonymous(method, url, body);
};

export const signedServer = (method, url, token, body) => {
  url = localDebug
    ? `http://127.0.0.1:8888/${url}`
    : `${window._env_.REACT_APP_BASE_URL}/server/${url}`;
  return signed(method, url, token, body);
};
