export default [
  {
    id: 0,
    name: 'BAD',
    desc: '(cloudy or raining)',
    parameters: {
      tempRange: { lower: null, upper: null },
      conditionCodes: [
        200,
        201,
        202,
        210,
        211,
        212,
        221,
        230,
        231,
        232,
        300,
        301,
        302,
        310,
        311,
        312,
        313,
        314,
        321,
        500,
        501,
        502,
        503,
        504,
        511,
        520,
        521,
        522,
        531,
        600,
        601,
        602,
        611,
        612,
        613,
        615,
        616,
        620,
        621,
        622,
        701,
        711,
        721,
        731,
        741,
        751,
        761,
        762,
        771,
        781,
        803,
        804
      ]
    }
  },
  {
    id: 1,
    name: 'COLD',
    desc: '(under ____ degrees)',
    parameters: {
      tempRange: { lower: null, upper: 283.15 },
      conditionCodes: []
    }
  },
  {
    id: 2,
    name: 'NICE',
    desc: '(between ____ & ___ degrees, sunny)',
    parameters: {
      tempRange: { lower: 283.15, upper: 297.039 },
      conditionCodes: [800, 801, 802]
    }
  },
  {
    id: 3,
    name: 'HOT',
    desc: '(over ___ degrees)',
    parameters: {
      tempRange: { lower: 297.039, upper: null },
      conditionCodes: []
    }
  }
];
