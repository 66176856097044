import React from 'react';
import styles from './defaultStyle.js';
import PropTypes from 'prop-types';

const OptionButtons = ({
  hasOptionToggles,
  isPublished,
  isDisabled,
  isMarkdown,
  isLocked,
  onChange,
  id,
  options,
  supportsMarkdown
}) => {
  if (!hasOptionToggles) return null;
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {!isDisabled && (
        <div
          style={{
            display: 'flex',
            position: 'relative',
            right: '19px',
            textAlign: 'right'
          }}
        >
          {supportsMarkdown && (
            <div style={styles.icon}>
              <div
                onClick={() =>
                  onChange({
                    id: id,
                    value: !isMarkdown,
                    data: options.data,
                    key: 'is_markdown'
                  })
                }
                className={`${styles.iconButton}
                      ${isMarkdown ? 'fab fa-markdown' : 'fas fa-text-width'}`}
              />
            </div>
          )}
          <div style={styles.icon}>
            <div
              onClick={() =>
                onChange({
                  id: id,
                  value: !isPublished,
                  data: options.data,
                  key: 'is_published'
                })
              }
              value={isPublished ? 'true' : 'false'}
              className={`fas ${styles.iconButton} ${
                !isPublished ? 'fa-eye-slash' : 'fa-eye'
              } ${!isPublished ? styles.locked : styles.unlocked}`}
            />
          </div>
          <div style={styles.icon}>
            <div
              onClick={() =>
                onChange({
                  id: id,
                  value: !isLocked,
                  data: options.data,
                  key: 'is_locked'
                })
              }
              className={`fas ${styles.iconButton}
                      ${isLocked ? 'fa-lock' : 'fa-lock-open'}
                      ${isLocked ? styles.locked : styles.unlocked}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OptionButtons;

OptionButtons.defaultProps = {
  hasOptionToggles: false,
  supportsMarkdown: false
};

OptionButtons.propTypes = {
  hasOptionToggles: PropTypes.bool,
  supportsMarkdown: PropTypes.bool,
  isPublished: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isMarkdown: PropTypes.bool,
  isLocked: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.any,
  options: PropTypes.object
};
