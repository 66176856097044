import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import { missingValue } from 'utility';
import { Modal } from 'components';
import PropTypes from 'prop-types';

class TargetBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoom: false,
      loaded_zoom: false
    };
  }

  onClick = () => {
    console.log(this.props.previewImage);
    if (this.props.allowClickToZoom) this.openModal();
  };

  openModal = () => {
    if (this.props.previewImage) this.setState({ zoom: true });
  };

  closeModal = () => {
    this.setState({ zoom: false });
  };

  render() {
    const isActive = this.props.canDrop && this.props.isOver;
    let className = isActive
      ? this.props.children
        ? this.props.styles.dropZone_children_active
        : this.props.styles.dropZone_active
      : this.props.children
      ? this.props.styles.dropZone_children
      : this.props.styles.dropZone;
    className = this.props.hasButton
      ? [className, this.props.styles.hasButton].join(' ')
      : className;
    const dropTarget = this.props.connectDropTarget(
      <div
        {...this.props.dataset}
        onClick={this.onClick}
        className={className}
        style={
          missingValue(this.props.previewImage)
            ? {}
            : { backgroundImage: `url(${this.props.previewImage})` }
        }
      >
        {this.props.children}

        {!this.props.children &&
          isActive &&
          !missingValue(this.props.previewImage) && (
            <div className={this.props.styles.dragMessage}>
              {this.props.dropMessage}
            </div>
          )}

        {!this.props.children &&
          !isActive &&
          missingValue(this.props.previewImage) && (
            <div className={this.props.styles.dragMessage}>
              {this.props.dropMessage}
            </div>
          )}
      </div>
    );
    return (
      <React.Fragment>
        {dropTarget}
        {this.state.zoom && (
          <Modal
            backgroundColor="rgba(0, 0, 0, 0.74)"
            naturalWidth={true}
            onOutsideClick={this.closeModal}
          >
            <div
              className={this.props.styles.spinner}
              style={!this.state.loaded_zoom ? {} : { display: 'none' }}
            >
              <div className={this.props.styles.doublebounce1} />
              <div className={this.props.styles.doublebounce2} />
            </div>
            <img
              style={this.state.loaded_zoom ? {} : { display: 'none' }}
              onLoad={() => this.setState({ loaded_zoom: true })}
              onClick={this.closeModal}
              width="100%"
              src={this.props.previewImage}
              alt={this.props.previewImage}
            />
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

TargetBox.propTypes = {
  hasButton: PropTypes.bool,
  previewImage: PropTypes.string,
  children: PropTypes.node,
  onDrop: PropTypes.func,
  styles: PropTypes.object,
  dropMessage: PropTypes.string,
  allowClickToZoom: PropTypes.bool,
  dataset: PropTypes.object,
  connectDropTarget: PropTypes.func,
  canDrop: PropTypes.bool,
  isOver: PropTypes.bool
};

export default DropTarget(
  (props) => props.accepts,
  {
    drop(props, monitor) {
      if (props.onDrop) {
        props.onDrop(props, monitor);
      }
    }
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(TargetBox);
