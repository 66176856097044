import React from 'react';
import PropTypes from 'prop-types';
import defaultStyle from './defaultStyle.js';

const Checkbox = (props) => {
  const styles = { ...defaultStyle, ...props.style };
  return (
    <div
      title={props.tooltip}
      style={props.isPublished ? null : styles.private}
    >
      <label
        style={{
          height: '2rem',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <div>
          <input
            disabled={props.isDisabled || props.isLocked}
            type="checkbox"
            autoComplete="off"
            checked={props.value ? true : false}
            data-id={props.id}
            onChange={(e) =>
              props.onChange({
                id: e.target.dataset.id,
                value: props.value ? false : true,
                data: props.options?.data
              })
            }
          />
        </div>
        <div style={styles.checkboxText}>
          {props.label === null ? '' : props.label}
        </div>
      </label>
    </div>
  );
};

Checkbox.defaultProps = {
  isPublished: true,
  onChange: () => {}
};

Checkbox.propTypes = {
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isPublished: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.any,
  tooltip: PropTypes.string,
  value: PropTypes.any
};

export default Checkbox;
