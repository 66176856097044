import nearestPointOnLine from '@turf/nearest-point-on-line';
import { point } from '@turf/helpers';

const audioTourPreviewHelper = {
  mapOptions: [
    'scrollZoom',
    'boxZoom',
    'dragRotate',
    'dragPan',
    'keyboard',
    'doubleClickZoom',
    'touchZoomRotate'
  ],
  indicator: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: []
        }
      }
    ]
  },
  indicatorSnapped: {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: []
        }
      }
    ]
  },
  removeLayerAndSource: (map, id) => {
    let foundLayer = map.getStyle().layers.find((layer) => {
      return layer.id === id;
    });
    if (foundLayer) {
      map.removeLayer(id);
      map.removeSource(id);
    }
  },

  parseCoordinatesFromGPXData: (data) => {
    const base64 = data.replace(/^data:([^;]+);base64,/gim, '');
    const xml = atob(base64);
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xml, 'text/xml');

    let coordinates = [];
    for (let idx = 0; idx < xmlDoc.getElementsByTagName('wpt').length; idx++) {
      const el = xmlDoc.getElementsByTagName('wpt')[idx];
      coordinates.push([el.getAttribute('lon'), el.getAttribute('lat')]);
    }
    return coordinates;
  },

  clearIndicator: (map) => {
    audioTourPreviewHelper.indicator.features[0].geometry.coordinates = [];
    audioTourPreviewHelper.indicatorSnapped.features[0].geometry.coordinates = [];
    const source = map.getSource('indicator');
    const sourceSnapped = map.getSource('indicatorSnapped');
    if (source) {
      source.setData(audioTourPreviewHelper.indicator);
      sourceSnapped.setData(audioTourPreviewHelper.indicatorSnapped);
    }
  },

  nearestFitLine: (originalLine, idealPath) => {
    let fitLine = [];
    originalLine.forEach((linePoint) => {
      const unSnapped = point(linePoint);
      const snapped = nearestPointOnLine(idealPath, unSnapped, {
        units: 'meters'
      });
      fitLine.push(snapped.geometry.coordinates);
    });
    return fitLine;
  },

  updateIndicator: (idealPath, map, coordinate, threshold) => {
    if (!coordinate || !idealPath || !map) return;

    const unSnapped = point([coordinate.lng, coordinate.lat]);
    const snapped = nearestPointOnLine(idealPath, unSnapped, {
      units: 'meters'
    });

    if (
      audioTourPreviewHelper.indicator.features[0].geometry.coordinates.length >
      20
    )
      audioTourPreviewHelper.clearIndicator(map);

    audioTourPreviewHelper.indicator.features[0].geometry.coordinates.push(
      unSnapped.geometry.coordinates
    );

    if (snapped.properties.dist <= threshold) {
      audioTourPreviewHelper.indicatorSnapped.features[0].geometry.coordinates.push(
        snapped.geometry.coordinates
      );
    }
    const source = map.getSource('indicator');
    const sourceSnapped = map.getSource('indicatorSnapped');
    if (source) {
      source.setData(audioTourPreviewHelper.indicator);
      sourceSnapped.setData(audioTourPreviewHelper.indicatorSnapped);
    } else {
      map.addSource('indicator', {
        type: 'geojson',
        data: audioTourPreviewHelper.indicator
      });
      map.addSource('indicatorSnapped', {
        type: 'geojson',
        data: audioTourPreviewHelper.indicatorSnapped
      });
      map.addLayer({
        id: 'indicator',
        type: 'line',
        source: 'indicator',
        layout: {
          'line-cap': 'round',
          'line-join': 'round'
        },
        paint: {
          'line-color': '#2094fb',
          'line-width': 5,
          'line-opacity': 0.8
        }
      });
      map.addLayer({
        id: 'indicatorSnapped',
        type: 'line',
        source: 'indicatorSnapped',
        layout: {
          'line-cap': 'round',
          'line-join': 'round'
        },
        paint: {
          'line-color': '#00FF00',
          'line-width': 5,
          'line-opacity': 0.8
        }
      });
    }
    return snapped.properties.dist;
  }
};
export default audioTourPreviewHelper;
