import React from 'react';
import PropTypes from 'prop-types';
import defaultStyle from './defaultStyle.js';

const Password = (props) => {
  const styles = { ...defaultStyle, ...props.style };
  return (
    <label style={styles.label}>
      <div>{props.label === null ? '' : props.label}</div>
      <div style={props.isPublished ? null : styles.private}>
        <input
          style={styles.input}
          type="password"
          autoComplete="off"
          disabled={props.isDisabled || props.isLocked}
          data-id={props.id}
          value={props.value === null ? '' : props.value}
          onChange={(e) =>
            props.onChange({
              id: e.target.dataset.id,
              value: e.target.value,
              data: props.options.data
            })
          }
        />
      </div>
    </label>
  );
};

Password.defaultProps = {
  onChange: () => {}
};

Password.propTypes = {
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isPublished: PropTypes.any,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.any,
  value: PropTypes.any
};

export default Password;
