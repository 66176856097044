// APP Sagas
import * as actions from 'state/actions';
import { take, put, select, call } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';

export const disable = function* (action) {
  try {
    if (navigator.geolocation) {
      const reduxState = yield select();
      navigator.geolocation.clearWatch(reduxState.app.userLocation.watchID);
    } else {
      yield console.error('Geolocation is not supported by this browser.');
    }
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: 'Geolocation not supported by this browser',
        source: action.type
      }
    });
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.type
      }
    });
  }
};

export const enable = function* (action) {
  try {
    const reduxState = yield select();
    if (navigator.geolocation && reduxState.app.userLocation.watchID === null) {
      const channel = yield call(() => geoWatch_init());
      while (true) {
        const action = yield take(channel);
        yield put(action);
      }
    } else {
      yield put({ type: actions.LOCATION_CLEAR_CURRENT });
      yield put({
        type: actions.APP_SAGA_ERROR,
        payload: {
          error: 'Geolocation not supported by this browser',
          source: action.type
        }
      });
    }
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.type
      }
    });
  }
};

function geoWatch_init() {
  return eventChannel((emitter) => {
    const watchID = navigator.geolocation.watchPosition((location) => {
      return emitter({
        type: actions.LOCATION_SET_CURRENT,
        payload: { location, watchID }
      });
    });
    return () => {
      console.log('Unsubscribed');
    };
  });
}
