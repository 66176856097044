// PACKAGER Sagas
import * as actions from 'state/actions';
import { select, put, delay } from 'redux-saga/effects';
import * as helper from './sagaHelper.js';

export const createPackage = function* (action) {
  try {
    const reduxState = yield select();
    let response = yield helper.signedServer(
      'GET',
      `package/create/${action.payload}`,
      reduxState.user.token
    );
    if (response.status === 202) {
      window.alertBar('Packager is currently busy, please try again later', {
        color: 'red'
      });
    } else {
      yield put({ type: actions.PKG_GET_STATUS });
    }
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.type
      }
    });
  }
};

export const deletePackage = function* (action) {
  try {
    const reduxState = yield select();
    let response = yield helper.signedServer(
      'GET',
      `package/delete/${action.payload}`,
      reduxState.user.token
    );
    if (response.status === 202) {
      window.alertBar('Packager is currently busy, please try again later', {
        color: 'red'
      });
    } else {
      yield put({ type: actions.PKG_GET_STATUS });
    }
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.type
      }
    });
  }
};

export const getManifest = function* (action) {
  try {
    let response = yield helper.anonymousServer('GET', `package/manifest`);
    let response_json = yield response.json();
    yield put({
      type: actions.PKG_GET_MANIFEST_RESPONSE,
      payload: { response: response_json, request: action.payload }
    });
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.payload ? action.payload.type : ''
      }
    });
  }
};
export const getStatus = function* (action) {
  try {
    let response = yield helper.anonymousServer('GET', `package/status`);
    let response_json = yield response.json();
    yield put({
      type: actions.PKG_GET_STATUS_RESPONSE,
      payload: { response: response_json, request: action.payload }
    });
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR_NONFATAL,
      payload: {
        error: e,
        source: action.payload ? action.payload.type : ''
      }
    });
  }
};

export const getStatus_r = function* (action) {
  if (action.payload.response.status.isInProgress) {
    yield delay(500);
    yield put({ type: actions.PKG_GET_STATUS });
  } else {
    yield put({ type: actions.PKG_GET_MANIFEST });
  }
};
