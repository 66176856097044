import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Edit.module.css';
import { Dialog, Modal, Button } from 'components/';
import FormInput from 'components/FormInput';
import { FIELDTYPE } from 'components/FormInput';

class Edit extends Component {
  constructor(props) {
    super(props);
    const defaultUserInfo = {
      email: '',
      group_role: 'webuser',
      name: '',
      is_validated: false,
      force_newpass: true,
      picture: 'https://assets.theplacelab.com/demo/user.png'
    };
    this.addMode = !this.props.userInfo;
    this.state = {
      dialog: {},
      formVals: {
        ...defaultUserInfo,
        ...(this.props.changePass
          ? {
              ...this.props.userInfo,
              password: '',
              newPass1: '',
              newPass2: ''
            }
          : this.props.userInfo)
      }
    };
  }

  onChange = (e) => {
    const fieldName = e.currentTarget.dataset.id;
    const message = fieldName === 'email' ? 'Email needs to be validated' : '';
    this.setState({
      message,
      formVals: {
        ...this.state.formVals,
        [fieldName]: e.currentTarget.value,

        is_validated:
          fieldName === 'email' ? false : this.state.formVals.is_validated
      }
    });
  };

  onSubmit = () => {
    const onFail = (message) => {
      this.setState({ message });
    };
    const onSuccess = (message) => {
      window.alertBar(message);
      this.props.onCancel();
    };
    if (this.props.changePass) {
      const payload = {
        email: this.state.formVals.email,
        id: this.state.formVals.id,
        password: this.state.formVals.password,
        new_password: this.state.formVals.newPass1
      };
      this.props.onChangePassword(payload, { onFail, onSuccess });
    } else {
      let payload = {
        id: this.state.formVals.id,
        email: this.state.formVals.email,
        name: this.state.formVals.name,
        picture: this.state.formVals.picture
      };
      if (this.addMode) {
        delete payload.id;
        payload = { pass: '*', group_role: 'webuser', ...payload };
        this.props.onAddUser(payload, { onFail, onSuccess });
      } else {
        this.props.onUpdateUser(payload, { onFail, onSuccess });
      }
    }
  };

  render = () => {
    const hasMessage = this.state.message?.length > 0;
    const enabled = this.props.changePass
      ? this.state.formVals.password?.length > 0 &&
        this.state.formVals.newPass1?.length > 0 &&
        this.state.formVals.newPass1 === this.state.formVals.newPass2
      : true;
    return (
      <Modal
        scrollable={false}
        onOutsideClick={this.props.onCancel}
        style={{ minWidth: '40rem' }}
      >
        {this.state.dialog.isVisible && <Dialog {...this.state.dialog} />}
        <form onSubmit={this.onSubmit}>
          <div
            className={
              !hasMessage
                ? `${styles.container} ${styles.statusHidden}`
                : styles.container
            }
          >
            {hasMessage && (
              <div className={styles.dialogMessage}>{this.state.message}</div>
            )}
            {(!this.props.changePass && (
              <div className={styles.formFields}>
                <div>
                  <FormInput
                    type={FIELDTYPE.TEXT}
                    label={
                      <span>
                        {(this.state.formVals.is_validated && (
                          <span
                            style={{
                              marginRight: '.3rem',
                              color: '#54818c'
                            }}
                            title="Validated"
                            className="fas fa-check"
                          />
                        )) || (
                          <span
                            style={{ marginRight: '.3rem', color: 'orange' }}
                            title="Not Validated"
                            className="fas fa-exclamation-circle"
                          />
                        )}
                        email
                      </span>
                    }
                    dataID={'email'}
                    value={this.state.formVals.email}
                    onChange={this.onChange}
                  />
                </div>
                <FormInput
                  type={FIELDTYPE.TEXT}
                  label={'name'}
                  dataID={'name'}
                  value={this.state.formVals.name}
                  onChange={this.onChange}
                />
                <FormInput
                  type={FIELDTYPE.TEXT}
                  label={'picture'}
                  dataID={'picture'}
                  value={this.state.formVals.picture}
                  onChange={this.onChange}
                />
                <FormInput
                  type={FIELDTYPE.SELECT}
                  selectionOptions={[
                    { id: 'web_user', name: 'User' },
                    { id: 'web_superuser', name: 'Superuser' }
                  ]}
                  label={'role'}
                  dataID={'role'}
                  value={this.state.formVals.role}
                  onChange={this.onChange}
                />{' '}
              </div>
            )) || (
              <div className={styles.formFields}>
                <FormInput
                  type={FIELDTYPE.PASSWORD}
                  label={'Current Password'}
                  dataID={'password'}
                  value={this.state.formVals.password}
                  onChange={this.onChange}
                />
                <FormInput
                  type={FIELDTYPE.PASSWORD}
                  label={'New Password'}
                  dataID={'newPass1'}
                  value={this.state.formVals.newPass1}
                  onChange={this.onChange}
                />
                <div
                  className={
                    this.state.formVals.newPass1 ===
                    this.state.formVals.newPass2
                      ? ''
                      : styles.notSame
                  }
                >
                  <FormInput
                    type={FIELDTYPE.PASSWORD}
                    label={'New Password (Again)'}
                    dataID={'newPass2'}
                    value={this.state.formVals.newPass2}
                    onChange={this.onChange}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={styles.formButtons}>
            <Button type="submit" value="Update" onClick={this.props.onCancel}>
              Cancel
            </Button>
            <Button
              onClick={enabled ? this.onSubmit : null}
              type="submit"
              style={{
                opacity: enabled ? '' : 0.5
              }}
            >
              {this.addMode ? 'Add' : 'Update'}
            </Button>
          </div>
        </form>
      </Modal>
    );
  };
}
export default Edit;

Edit.defaultProps = {
  changePass: false
};

Edit.propTypes = {
  changePass: PropTypes.bool,
  userInfo: PropTypes.object,
  onCancel: PropTypes.func,
  onAddUser: PropTypes.func,
  onChangePassword: PropTypes.func.isRequired,
  onUpdateUser: PropTypes.func.isRequired
};
