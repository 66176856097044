import { all } from 'redux-saga/effects';
import * as actions from 'state/actions';
import { select, put } from 'redux-saga/effects';
import * as helper from './sagaHelper.js';
import CONST from 'constants.js';

export const getProximity = function* (action) {
  switch (window._env_.REACT_APP_MODE) {
    default:
    case CONST.APP_MODE.API: {
      const reduxState = yield select();
      try {
        let response;
        response = yield helper.anonymousAPI(
          'GET',
          `proximity?map_id=eq.${reduxState.app.id}`
        );
        let response_json = yield response.json();
        if (response.ok) {
          yield put({
            type: actions.PROXIMITY_GET_RESPONSE,
            payload: response_json
          });
        } else {
          yield put({
            type: actions.APP_SAGA_ERROR,
            payload: {
              error: response_json,
              source: action.type
            }
          });
        }
      } catch (e) {
        yield put({
          type: actions.APP_SAGA_ERROR,
          payload: {
            error: e,
            source: action.type
          }
        });
      }
      break;
    }
  }
};

export const refreshProximity = function* (action) {
  yield put({ type: actions.PROXIMITY_GET, payload: action.payload });
};

export const recordChanges = function* (action) {
  const reduxState = yield select();
  yield all(
    reduxState.proximity.needsInsert.map((proximity) => {
      return put({
        type: actions.API_PERFORM_CRUD,
        payload: {
          isInsert: true,
          placeholderId: proximity.id,
          method: 'POST',
          endpoint: `proximity`,
          data: {
            map_id: reduxState.app.id,
            name: proximity.name,
            options: proximity.options,
            sortorder: proximity.sortorder
          },
          type: action.type
        }
      });
    })
  );
  yield put({ type: actions.PROXIMITY_CLEAR_INSERT_QUEUE });

  yield all(
    reduxState.proximity.needsRemoval.map((proximity) => {
      return put({
        type: actions.API_PERFORM_CRUD,
        payload: {
          method: 'DELETE',
          endpoint: `proximity?id=eq.${proximity.id}`,
          data: {},
          type: action.type
        }
      });
    })
  );
  yield put({ type: actions.PROXIMITY_CLEAR_REMOVE_QUEUE });

  yield all(
    reduxState.proximity.needsUpdate.map((proximity) => {
      return put({
        type: actions.API_PERFORM_CRUD,
        payload: {
          method: 'POST',
          endpoint: `proximity?id=eq.${proximity.id}`,
          data: {
            map_id: reduxState.app.id,
            name: proximity.name,
            options: proximity.options,
            sortorder: proximity.sortorder
          },
          type: action.type
        }
      });
    })
  );
  yield put({ type: actions.PROXIMITY_CLEAR_UPDATE_QUEUE });
};

export const resequenceProximity = function* (action) {
  yield put({
    type: actions.API_PERFORM_CRUD,
    payload: {
      method: 'POST',
      endpoint: 'rpc/resequence_proximity',
      type: action.type,
      data: {
        payload: { data: action.payload }
      }
    }
  });
};
