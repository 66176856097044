import initialState from './init/timeFilter';
import * as actions from 'state/actions';

export default function app(state = initialState, action) {
  switch (action.type) {
    case actions.TIMEFILTER_SET_RANGE:
      return { ...state, ...action.payload };

    case actions.SEARCH_RESET:
      return {
        ...state,
        isPinned: state.isPinned,
        isVisible: state.isVisible,
        leftPercent: 0,
        rightPercent: 100
      };

    case actions.TIMEFILTER_OPTIONS_UPDATE:
      return {
        ...state,
        ...action.payload
      };

    case actions.TIMEFILTER_SETVAL:
      return { ...state, [action.payload.key]: action.payload.value };

    case actions.APP_VISIBILITY_TIMEFILTER:
      return { ...state, isVisible: state.isPinned ? true : action.payload };

    case actions.APP_PIN_TIMEFILTER:
      return { ...state, isPinned: action.payload };
    default:
      return state;
  }
}
