import React from 'react';
import PropTypes from 'prop-types';
import defaultStyle from './defaultStyle.js';

const Taglist = (props) => {
  const styles = { ...defaultStyle, ...props.style };
  if (props.isDisabled || props.isLocked) {
    let tag_output = [];
    let tags = props.value.split(',');
    for (let idx = 0; idx < tags.length; idx++) {
      let tag = tags[idx].trim();
      tag_output.push(
        <div key={`tag_${tag}`} style={styles.tag} onClick={props.onTagClick}>
          {tag}
        </div>
      );
    }

    return (
      <label style={styles.label}>
        <div>{props.label === null ? '' : props.label}</div>
        <div style={props.isPublished ? null : styles.private}>
          <div style={styles.tagCollection}>{tag_output}</div>
        </div>
      </label>
    );
  } else {
    return (
      <label style={styles.label}>
        <div>{props.label === null ? '' : props.label}</div>
        <div style={props.isPublished ? null : styles.private}>
          <input
            style={styles.input}
            type="text"
            autoComplete="off"
            disabled={props.isDisabled || props.isLocked}
            data-id={props.id}
            value={props.value === null ? '' : props.value}
            onChange={(e) =>
              props.onChange({
                id: e.target.dataset.id,
                value: e.target.value,
                data: props.options?.data
              })
            }
          />
        </div>
      </label>
    );
  }
};

Taglist.defaultProps = {
  onChange: () => {}
};

Taglist.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  id: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.object
};

export default Taglist;
