import * as actions from 'state/actions';
import React from 'react';
import colors from 'colors';
import { useSelector, useDispatch } from 'react-redux';
import CONST from 'constants.js';
import PopoverMenu from 'components/PopoverMenu.js';
import preferences from 'config/preferences';
import moment from 'moment';
import { mapHelper } from 'components/MapView';

window.admin = {
  login: (username, password) => {
    return this.props.onLogin(username, password);
  },
  logout: () => {
    return this.props.onLogout();
  },
  makeNavigationVisible: () => {
    document.getElementById('navigationBar').style.opacity = 1;
  }
};

const Navigation = () => {
  const dispatch = useDispatch();
  const app = useSelector((redux) => redux.app);
  const poi = useSelector((redux) => redux.poi);
  const isFramed = useSelector((redux) => redux.app.isFramed);
  const timeFilter = useSelector((redux) => redux.timeFilter);
  const user = useSelector((redux) => redux.user);
  const preference = useSelector((redux) => redux.app.prefs);
  let maptitle = app.prefs.title.value;
  if (poi.filtered?.length > 0) {
    maptitle += ` | ${moment(timeFilter.start).format(
      app.dateFormat.long
    )} - ${moment(timeFilter.end).format(app.dateFormat.long)} (${
      poi.filtered.length
    }/${poi.all.length})`;
  }
  let mapMenu = [];
  if (app.available_maps)
    app.available_maps.forEach((item) =>
      mapMenu.push({
        name: item.title,
        icon: 'map',
        action: () => {
          //FIXME: This should be in a single saga, it's not safe as-is
          localStorage.setItem('mapID', item.id);
          dispatch(actions.uiHideAll());
          dispatch(
            actions.initializeApp({
              map_id: item.id
            })
          );
        }
      })
    );

  let navigationMenu = [
    {
      name: user.claims.name
        ? user.claims.name
        : user.claims.email
        ? user.claims.email
        : 'Login',
      icon: 'user',
      action: () => dispatch(actions.uiShowToggle(CONST.UI.USER_SELF_MANAGER))
    },
    {
      name: 'Search (⌘-F)',
      icon: 'search',
      action: () => dispatch(actions.uiShowToggle(CONST.UI.SEARCH))
    },
    { name: 'Home', icon: 'home', action: mapHelper.onHome },
    { name: 'Fit POI', icon: 'vector-square', action: mapHelper.onAutoBounds },
    {
      name: 'About',
      icon: 'info-circle',
      action: () =>
        dispatch(
          actions.uiShowDialog({
            align: 'left',
            message: app.prefs.url_info.value,
            onCancel: null,
            cancelLabel: null,
            confirmLabel: 'Ok',
            onConfirm: () => dispatch(actions.uiHideDialog())
          })
        )
    },
    {
      name: app.isLocked ? 'Unlock (⌘-L)' : 'Lock (⌘-L)',
      icon: app.isLocked ? 'lock' : 'unlock',
      action: mapHelper.onToggleLock
    },
    {
      name: 'Time Filter',
      icon: 'filter',
      action: () => dispatch(actions.uiPinToggle(CONST.UI.TIME_FILTER))
    }
  ];
  if (preferences.hasGeoSearch.value)
    navigationMenu.push({
      name: 'Geo Search',
      icon: 'map',
      action: () => dispatch(actions.uiShowToggle(CONST.UI.GEOSEARCH))
    });

  const cogMenu = [
    {
      name: 'Preferences',
      icon: 'cog',
      action: () => dispatch(actions.uiShow(CONST.UI.PREFERENCES))
    }
  ];

  if (preference.hasMediaLibrary.value)
    cogMenu.push({
      name: 'Media Library',
      icon: 'book',
      action: () => dispatch(actions.uiShow(CONST.UI.MEDIA_LIBRARY))
    });
  if (
    preference.hasUserManager.value &&
    user.claims.group_role === CONST.ROLE.SUPERUSER
  )
    cogMenu.push({
      name: 'User Manager',
      icon: 'cog',
      action: () => dispatch(actions.uiShow(CONST.UI.USER_MANAGER))
    });
  if (preference.hasAudioTour.value) {
    cogMenu.push({
      name: 'Audio Tour',
      icon: 'cog',
      action: () => dispatch(actions.uiShow(CONST.UI.AUDIO_TOUR))
    });
    cogMenu.push({
      name: 'Audio Tour: Preview',
      icon: 'cog',
      action: () => dispatch(actions.uiShow(CONST.UI.TOUR_PREVIEW))
    });
  }
  cogMenu.push({
    name: 'Help',
    icon: 'cog',
    action: () => window.open(window._env_.REACT_APP_HELP_URL)
  });
  cogMenu.push({ name: 'divider' });
  cogMenu.push({
    name: 'Set Initial View',
    icon: 'cog',
    action: () => {
      if (
        window.confirm(
          `Do you really want to set the initial map position and view?`
        )
      )
        mapHelper.onSetInitialView();
    }
  });
  cogMenu.push({
    name: 'Report a Problem',
    icon: 'cog',
    action: () => window.open(window._env_.REACT_APP_BUG_REPORT_URL)
  });

  return (
    <div style={{ ...styles.container, display: isFramed ? 'none' : 'flex' }}>
      <div style={styles.mapTitle}>
        {(mapMenu.length > 1 && (
          <PopoverMenu
            forceLayout={10}
            resetToTop={true}
            items={mapMenu}
            layout={{ top: '40px', left: '0' }}
            showArrow={false}
          >
            <div>{maptitle}</div>
          </PopoverMenu>
        )) ||
          maptitle}
      </div>
      <div>
        <PopoverMenu
          resetToTop={true}
          forceLayout={11}
          items={navigationMenu}
          layout={{ top: '40px' }}
          showArrow={false}
        />
      </div>
      <div>
        {user.isAuthenticated && (
          <PopoverMenu
            resetToTop={true}
            forceLayout={11}
            items={cogMenu}
            icon="cog"
            layout={{ top: '40px' }}
            showArrow={false}
          />
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '0 1rem 0 1rem',
    fontFamily: colors.fontFamily,
    top: 0,
    left: 0,
    margin: 0,
    right: 0,
    backgroundColor: colors.uiNavbar,
    height: '2.5rem',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    color: 'white'
  },
  mapTitle: { flexGrow: 1 }
};

export default Navigation;

Navigation.propTypes = {};
