export default {
  degToRad: (num) => {
    return (num * Math.PI) / 180;
  },

  radToDeg: (radians) => {
    return (radians * 180.0) / Math.PI;
  },

  sinDeg: (deg) => {
    return Math.sin((deg * 2.0 * Math.PI) / 360.0);
  },

  acosDeg: (x) => {
    return (Math.acos(x) * 360.0) / (2 * Math.PI);
  },

  asinDeg: (x) => {
    return (Math.asin(x) * 360.0) / (2 * Math.PI);
  },

  tanDeg: (deg) => {
    return Math.tan((deg * 2.0 * Math.PI) / 360.0);
  },

  cosDeg: (deg) => {
    return Math.cos((deg * 2.0 * Math.PI) / 360.0);
  },

  mod: (a, b) => {
    var result = a % b;
    if (result < 0) {
      result += b;
    }
    return result;
  }
};
