import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorPage from 'components/ErrorPage';
import { uiShowFatalError } from 'state/actions';
class SentryBoundary extends Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.props.dispatch(uiShowFatalError(error));
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.props.reduxState.ui.errorScreen.isVisible) {
      return <ErrorPage />;
    } else {
      return this.props.children;
    }
  }
}

export default connect((reduxState) => {
  return { reduxState };
})(SentryBoundary);

SentryBoundary.propTypes = {
  reduxState: PropTypes.object,
  children: PropTypes.node,
  errorStatus: PropTypes.object,
  dispatch: PropTypes.func
};
