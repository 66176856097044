import { IconButton, MediaLibrary, MediaPlayer } from 'components';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import defaultStyle from './defaultStyle.js';
import { useSelector } from 'react-redux';
import { makeAssetURL } from 'utility';

const MediaPicker = ({
  style,
  onChange,
  value,
  id,
  options,
  isPublished,
  label,
  isDisabled,
  type
}) => {
  const availableMedia = useSelector((redux) => redux.media.available.media);
  const styles = { ...defaultStyle, ...style };
  const media = availableMedia.find((item) => item.id === parseInt(value, 10));
  const [mediaLibraryOpen, setMediaLibraryOpen] = useState(false);
  const onToggleMediaLibrary = (e) => {
    setMediaLibraryOpen(!mediaLibraryOpen);
    /*
    console.log({
      showTypes: options.showTypes
        ? options.showTypes
        : ['video', 'audio', 'custom'],
      type: e.target.dataset,
      selectedID: e.target.dataset.mediaid,
      'data-uniqueid': uniqueID,
      'data-typeid': dataTypeID,
      'data-id': dataID,
      'data-isPublished': isPublished ? 'true' : 'false',
      'data-meta': JSON.stringify(metaData)
    });*/
  };

  const onSelectItem = (e) => {
    onChange({
      id: id,
      value: e.selected,
      data: options?.data
    });
  };

  const mediaPreview = media
    ? media.url.includes('http')
      ? makeAssetURL(media.preview)
        ? media.preview
        : 'missing'
      : makeAssetURL(media.url)
    : 'missing';
  return (
    <div style={isPublished ? null : styles.private}>
      {mediaLibraryOpen && (
        <MediaLibrary
          onOutsideClick={onToggleMediaLibrary}
          onSelectItem={onSelectItem}
          selectOnly={true}
          showTypes={[]}
          selectedMediaId={media?.id}
        />
      )}
      <div style={{ flexGrow: 1 }}>{label === null ? '' : label}</div>
      <div
        style={isDisabled ? styles.mediaPicker_disabled : styles.mediaPicker}
      >
        {!isDisabled && (
          <IconButton
            style={{ position: 'relative', margin: '.5rem' }}
            icon={media ? 'pen' : 'plus'}
            dataset={{
              'data-type': type,
              'data-mediaid': media ? media.id : null
            }}
            onClick={onToggleMediaLibrary}
          />
        )}
        {media && (
          <div>
            <div>{media.name}</div>
            <div>
              <MediaPlayer media={media} />
              <img
                alt={media.name}
                style={{ maxWidth: '100%' }}
                src={makeAssetURL(mediaPreview)}
              ></img>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

MediaPicker.propTypes = {
  dataID: PropTypes.any,
  dataTypeID: PropTypes.any,
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isPublished: PropTypes.any,
  label: PropTypes.string,
  metaData: PropTypes.any,
  onChange: PropTypes.func,
  options: PropTypes.object,
  style: PropTypes.any,
  type: PropTypes.any,
  uniqueID: PropTypes.any,
  value: PropTypes.any
};

export default MediaPicker;
