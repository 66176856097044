import React from 'react';
import PropTypes from 'prop-types';
import * as Field from './Field';

export const FIELDTYPE = {
  CHECKBOX: 'CHECKBOX',
  CHECKBOX_ICON: 'CHECKBOX_ICON',
  COLOR: 'COLOR',
  COORDINATE: 'COORDINATE',
  DATE: 'DATE',
  HIDDEN: 'HIDDEN',
  MEDIAPICKER: 'MEDIAPICKER',
  PASSWORD: 'PASSWORD',
  SELECT: 'SELECT',
  SELECTION: 'SELECTION',
  SLIDER: 'SLIDER',
  TAGLIST: 'TAGLIST',
  TEXT: 'TEXT',
  TEXTAREA: 'TEXTAREA',
  TILESELECT: 'TILESELECT',
  DURATION: 'DURATION'
};

const FormInput = (props) => {
  const renderField = (props) => {
    switch (props.type) {
      case FIELDTYPE.COORDINATE:
        return <Field.Coordinates {...props} />;
      case FIELDTYPE.TAGLIST:
        return <Field.Taglist {...props} />;
      case FIELDTYPE.DATE:
        return <Field.Date {...props} />;
      case FIELDTYPE.SELECTION:
        return <Field.Selection {...props} />;
      case FIELDTYPE.COLOR:
        return <Field.Color {...props} />;
      case FIELDTYPE.MEDIAPICKER:
        return <Field.MediaPicker {...props} />;
      case FIELDTYPE.SLIDER:
        return <Field.Slider {...props} />;
      case FIELDTYPE.TEXTAREA:
        return <Field.Textarea {...props} />;
      case FIELDTYPE.SELECT:
        return <Field.Select {...props} />;
      case FIELDTYPE.CHECKBOX_ICON:
        return <Field.CheckboxIcon {...props} />;
      case FIELDTYPE.CHECKBOX:
        return <Field.Checkbox {...props} />;
      case FIELDTYPE.HIDDEN:
        return <Field.Hidden {...props} />;
      case FIELDTYPE.TEXT:
        return <Field.Text {...props} />;
      case FIELDTYPE.PASSWORD:
        return <Field.Password {...props} />;
      case FIELDTYPE.TILESELECT:
        return <Field.Tileselect {...props} />;
      case FIELDTYPE.DURATION:
        return <Field.Duration {...props} />;
      default: {
        return <Field.Text {...props} />;
      }
    }
  };
  if (props.isDisabled && props.value?.length === 0) return null;
  return <div>{renderField(props)}</div>;
};
export default FormInput;

FormInput.defaultProps = {
  hasOptionToggles: false
};

FormInput.propTypes = {
  hasOptionToggles: PropTypes.bool,
  styles: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.object,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isPublished: PropTypes.bool,
  isMarkdown: PropTypes.bool
};
