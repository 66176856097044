import mapboxgl from 'mapbox-gl';
import { isIsoDate, makeAssetURL, filterPoiByTags } from 'utility';
import CONST from 'constants.js';
import MapboxCircle from 'mapbox-gl-circle';
import moment from 'moment';
import store from 'store';
import * as actions from 'state/actions';

const SPIDERFY_FROM_ZOOM = 10;

const mapHelper = {
  draggableMarkers: [],

  removeAllMarkers: (map) => {
    if (!map) return;
    map.getStyle().layers.forEach((layer) => {
      if (layer.id.includes('pin') || layer.id.includes('circle')) {
        map.removeLayer(layer.id);
      }
    });
    Object.keys(map.getStyle().sources).forEach((source) => {
      if (source.includes('circle') || source.includes('pin')) {
        map.removeSource(source);
      }
    });
    if (mapHelper.draggableMarkers) {
      mapHelper.draggableMarkers.forEach((marker) => {
        marker.remove();
      });
      mapHelper.draggableMarkers = null;
    }
  },

  renderDraggableMarkers: (mapObj, poiByType) => {
    mapHelper.removeAllMarkers(mapObj);
    poiByType
      .filter((rep) => rep.id !== CONST.REPRESENTATION.GEOFENCE)
      .forEach((type) => {
        if (type.poi) {
          type.poi.forEach((poi) => {
            if (poi.coordinate) {
              let marker = new mapboxgl.Marker({
                draggable: true
              })
                .setLngLat([poi.coordinate.lng, poi.coordinate.lat])
                .addTo(mapObj);
              marker.on('dragstart', () =>
                store.dispatch(actions.setCurrentPoi(poi.id))
              );
              marker.on('dragend', (e) =>
                store.dispatch(
                  actions.updatePoi({
                    ...poi,
                    coordinate: e.target.getLngLat()
                  })
                )
              );
              if (!mapHelper.draggableMarkers) mapHelper.draggableMarkers = [];
              mapHelper.draggableMarkers.push(marker);
            }
          });
        }
      });
  },

  renderMarkers: (mapObj, poiByType, requestImage, onTooltip) => {
    const state = store.getState();
    const customImagePins = poiByType.find(
      (type) => type.id === CONST.REPRESENTATION.IMAGE_CUSTOM
    ).poi;
    const imagePreviewPins = poiByType.find(
      (type) => type.id === CONST.REPRESENTATION.IMAGE_PREVIEW
    ).poi;

    mapHelper.removeAllMarkers(mapObj);

    mapHelper._renderCircles(
      mapObj,
      poiByType.find((rep) => rep.id === CONST.REPRESENTATION.CIRCLE).poi
    );

    mapHelper._renderImagePinSet(
      mapObj,
      mapHelper._featuresFromPoiSet(
        poiByType.find((type) => type.id === CONST.REPRESENTATION.PIN).poi
      ),
      {
        ...mapHelper._defaultOptionsFor(CONST.REPRESENTATION.PIN),
        iconUrl: `icons/pin_80x109.png`
      },
      requestImage,
      onTooltip
    );

    if (customImagePins) {
      let pinsByUrl = {};
      customImagePins.forEach((customPin) => {
        let key = customPin.representation_config.iconUrl
          ? customPin.representation_config.iconUrl
          : 'icons/missing_small.png';
        key = `${key}|${customPin.representation_config.width}x${customPin.representation_config.height}`;
        if (!pinsByUrl[key]) pinsByUrl[key] = [];
        pinsByUrl[key].push(customPin);
      });
      Object.keys(pinsByUrl).forEach((key) => {
        let options = {
          ...mapHelper._defaultOptionsFor(CONST.REPRESENTATION.IMAGE_CUSTOM),
          ...pinsByUrl[key][0].representation_config,
          iconUrl: key.split('|')[0]
        };
        mapHelper._renderImagePinSet(
          mapObj,
          mapHelper._featuresFromPoiSet(pinsByUrl[key]),
          options,
          requestImage,
          onTooltip,
          parseFloat(state.app.prefs.scalar_custom_icon.value)
        );
      });
    }

    if (imagePreviewPins) {
      const state = store.getState();
      const availableMedia = state.media.available.media;
      if (!availableMedia) return;

      let pinsByUrl = {};
      imagePreviewPins.forEach((customPin) => {
        let customPinData = customPin.data.find(
          (opt) => opt.field.toLowerCase() === 'media'
        );
        let pinMedia = availableMedia.find(
          (media) => media.id === parseInt(customPinData?.value, 10)
        );
        let urlAsKey = customPin.representation_config.iconUrl
          ? customPin.representation_config.iconUrl
          : pinMedia?.preview
          ? makeAssetURL(pinMedia.preview)
          : 'icons/missing_small.png';
        urlAsKey =
          customPin.representation_config.width &&
          customPin.representation_config.height
            ? `${urlAsKey}|${customPin.representation_config.width}x${customPin.representation_config.height}`
            : urlAsKey;
        if (!pinsByUrl[urlAsKey]) pinsByUrl[urlAsKey] = [];
        pinsByUrl[urlAsKey].push(customPin);
      });
      Object.keys(pinsByUrl).forEach((pin) => {
        let options = {
          ...mapHelper._defaultOptionsFor(CONST.REPRESENTATION.IMAGE_PREVIEW),
          ...pinsByUrl[pin][0].representation_config,
          iconUrl: pin.split('|')[0]
        };
        options.height = parseInt(options.height, 10);
        options.width = parseInt(options.width, 10);

        mapHelper._renderImagePinSet(
          mapObj,
          mapHelper._featuresFromPoiSet(pinsByUrl[pin]),
          options,
          requestImage,
          onTooltip,
          parseFloat(state.app.prefs.scalar_preview_icon.value)
        );
      });
    }
  },

  renderUserLocation: (mapObj, location, styles) => {
    if (!mapObj.locationMarker) {
      const userLocation = document.createElement('div');
      userLocation.className = styles.user_outer;
      userLocation.style.width = '10rem';
      userLocation.style.height = '10rem';
      const userdot = document.createElement('div');
      userdot.className = styles.user_dot;
      userLocation.appendChild(userdot);
      mapObj.locationMarker = new mapboxgl.Marker(userLocation);
    }
    mapObj.locationMarker.setLngLat({
      lng: location.coords.longitude,
      lat: location.coords.latitude
    });

    mapObj.locationMarker.addTo(mapObj);
  },

  _featuresFromPoiSet: (poiSet) => {
    if (!poiSet) return [];
    return poiSet.map((poi) => {
      if (poi.coordinate) {
        return {
          type: 'feature',
          properties: {
            representation_id: poi.representation_id,
            id: poi.id.toString(),
            name: poi.name,
            date: poi.date,
            lnglat: { lng: poi.coordinate.lng, lat: poi.coordinate.lat }
          },
          geometry: {
            type: 'Point',
            coordinates: [poi.coordinate.lng, poi.coordinate.lat]
          },
          poi: poi
        };
      } else {
        return null;
      }
    });
  },

  _defaultOptionsFor: (type) => {
    const state = store.getState();
    const defaultConfig = state.app.representation_options.find(
      (option) => option.id === type
    ).default;
    let config = {};
    const defaultConfigs = defaultConfig ? Object.keys(defaultConfig) : null;
    if (!defaultConfigs) console.error('Missing Default Config');
    defaultConfigs.forEach((item) => {
      config[item] = defaultConfig[item].value;
    });
    return config;
  },

  _clusterPinInit: (mapObj, id) => {
    mapObj.addLayer({
      id: `cluster-pins-${id}`,
      type: 'circle',
      source: `pins-source-${id}`,
      filter: ['all', ['has', 'point_count']],
      paint: {
        'circle-color': {
          type: 'interval',
          property: 'point_count',
          stops: [
            [5, 'rgba(81, 187, 214, .2)'],
            [10, 'rgba(81, 187, 214, .5)'],
            [25, 'rgba(81, 187, 214, .7)'],
            [50, 'rgba(81, 187, 214, 1)']
          ]
        },
        'circle-radius': 25
      }
    });

    let foundLayer = mapObj.getStyle().layers.find((layer) => {
      return layer.id.includes(`cluster-pins-count-${id}`);
    });

    if (!foundLayer)
      mapObj.addLayer({
        id: `cluster-pins-count-${id}`,
        type: 'symbol',
        source: `pins-source-${id}`,
        layout: {
          'icon-image': id,
          'icon-size': 0.25
        }
      });
  },

  _renderCircles: (mapObj, circlePoi) => {
    if (!circlePoi) return;
    const defaultConfig = mapHelper._defaultOptionsFor(
      CONST.REPRESENTATION.CIRCLE
    );

    circlePoi.forEach((thisPoi) => {
      const config = {
        ...defaultConfig,
        ...thisPoi.representation_config
      };
      if (isNaN(parseFloat(config.radius))) {
        console.warn(`DATA ERROR`);
        console.warn(thisPoi);
        config.radius = 1;
      }
      new MapboxCircle(
        thisPoi.coordinate,
        parseFloat(config?.radius > 0 ? config.radius : 1),
        {
          editable: false,
          refineStroke: false,
          strokeWeight: config.strokeWeight
            ? parseFloat(config.strokeWeight)
            : 0,
          strokeOpacity: config.strokeOpacity
            ? parseFloat(config.strokeOpacity)
            : 0,
          strokeColor: config.strokeColor,
          fillOpacity: config.fillOpacity ? parseFloat(config.fillOpacity) : 0,
          fillColor: config.fillColor,
          properties: { id: thisPoi.id }
        }
      ).addTo(mapObj);
    });
  },

  _renderImagePinSet: (
    mapObj,
    features,
    opt,
    requestImage,
    onTooltip,
    scalar = 0.25
  ) => {
    let id = opt.iconUrl.split('/');
    id = id[id.length - 1].replace(/\.[^/.]+$/, '');
    id = `${id}-${opt.width}-${opt.height}`;
    requestImage(id, opt);

    if (!mapObj.getStyle().sources[`pins-source-${id}`]) {
      mapObj.addSource(`pins-source-${id}`, {
        type: 'geojson',
        cluster: true,
        clusterRadius: 50,
        clusterMaxZoom: parseInt(mapObj.getMaxZoom()),
        data: {
          type: 'FeatureCollection',
          features
        }
      });
    }

    let foundLayer = mapObj.getStyle().layers.find((layer) => {
      return layer.id.includes(`pins-${id}`);
    });
    if (!foundLayer)
      mapObj.addLayer({
        id: `pins-${id}`,
        type: 'symbol',
        source: `pins-source-${id}`,
        layout: {
          'icon-image': id,
          'icon-size': scalar,
          'text-allow-overlap': true,
          'icon-allow-overlap': true
        },
        filter: ['all', ['!has', 'point_count']]
      });

    if (opt.iconUrl.includes('pin_80x109.png'))
      mapHelper._clusterPinInit(mapObj, id);

    mapObj.on('zoomstart', () => {
      mapObj.spiderifier.spidered = false;
      mapObj.spiderifier.unspiderfy();
    });

    mapObj.on('mousemove', (e) => {
      let event = new CustomEvent('coordinateUpdate');
      event.coordinates = e.lngLat;
      window.dispatchEvent(event);
      if (
        !mapObj
          .getStyle()
          .layers.find((layer) => layer.id === `cluster-pins-${id}`)
      )
        return;
      let features = mapObj.queryRenderedFeatures(e.point);
      mapObj.getCanvas().style.cursor = features.length ? 'pointer' : '';

      if (!mapObj.spiderifier.spidered)
        if (features.length !== 0) {
          let thisFeature = features[0];

          if (thisFeature.properties.name) {
            let screenCoords = {
              x: e.originalEvent.clientX,
              y: e.originalEvent.clientY
            };
            screenCoords = mapObj.project(
              JSON.parse(thisFeature.properties.lnglat)
            );
            let date = thisFeature.properties.date
              ? isIsoDate(thisFeature.properties.date)
                ? `${moment(thisFeature.properties.date).format('MM/DD/YYYY')}`
                : `${thisFeature.properties.date}`
              : '';

            onTooltip({
              isVisible: true,
              contentTitle: date,
              content: thisFeature.properties.name,
              top: screenCoords.y,
              left: screenCoords.x
            });
          }
        } else {
          if (onTooltip) onTooltip({ isVisible: false });
        }
    });

    mapObj.on('click', (e) => {
      if (
        !mapObj
          .getStyle()
          .layers.find((layer) => layer.id === `cluster-pins-${id}`)
      )
        return;
      let features = mapObj.queryRenderedFeatures(e.point, {
        layers: [`cluster-pins-${id}`]
      });
      mapObj.spiderifier.spidered = false;
      mapObj.spiderifier.unspiderfy();
      if (!features.length) {
        return;
      } else if (mapObj.getZoom() < SPIDERFY_FROM_ZOOM) {
        mapObj.easeTo({ center: e.lngLat, zoom: mapObj.getZoom() + 2 });
      } else {
        mapObj
          .getSource(`pins-source-${id}`)
          .getClusterLeaves(
            features[0].properties.cluster_id,
            100,
            0,
            (err, leafFeatures) => {
              if (err) {
                return console.error(
                  'error while getting leaves of a cluster',
                  err
                );
              }
              let markers = leafFeatures.map((lf) => {
                return lf.properties;
              });
              mapObj.spiderifier.spidered = true;
              mapObj.spiderifier.spiderfy(
                features[0].geometry.coordinates,
                markers
              );
            }
          );
      }
    });

    mapObj.on('zoomstart', () => {
      onTooltip({ isVisible: false });
      mapObj.spiderifier.spidered = false;
      mapObj.spiderifier.unspiderfy();
    });
  },

  _createGeoJSONCircle: (poi, radiusInKm) => {
    let points = 1000;

    let coords = {
      latitude: poi.coordinate.lat,
      longitude: poi.coordinate.lng
    };

    let ret = [];
    let distanceX =
      radiusInKm / (111.32 * Math.cos((coords.latitude * Math.PI) / 180));
    let distanceY = radiusInKm / 110.574;

    let theta, x, y;
    for (let i = 0; i < points; i++) {
      theta = (i / points) * (2 * Math.PI);
      x = distanceX * Math.cos(theta);
      y = distanceY * Math.sin(theta);

      ret.push([coords.longitude + x, coords.latitude + y]);
    }
    ret.push(ret[0]);

    return {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {
              representation_id: poi.representation_id,
              id: poi.id.toString(),
              name: poi.name,
              date: poi.date,
              lnglat: { lng: coords.longitude, lat: coords.latitude }
            },
            geometry: {
              type: 'Polygon',
              coordinates: [ret]
            }
          }
        ]
      }
    };
  },

  onSetInitialView: () => {
    const state = store.getState();
    let updates = {
      map_initial_position: state.app.map.getBounds().getCenter(),
      map_initial_bounds: state.app.map.getBounds(),
      map_zoom_initial: state.app.map.getZoom()
    };
    console.log(updates);
    store.dispatch(actions.updatePrefs(updates));
  },

  onHome: () => {
    const state = store.getState();
    state.app.map.jumpTo(
      { center: state.app.prefs.map_initial_position.value },
      state.app.prefs.map_zoom_initial.value
    );
  },

  onToggleLock: () => {
    const state = store.getState();
    if (state.user.isAuthenticated) {
      store.dispatch(actions.mapLock(!state.app.isLocked));
    }
  },

  onMapDidLoad: (mapObj) => {
    const state = store.getState();
    store.dispatch(actions.setMapObject(mapObj));
    mapObj.on('moveend', () => {
      if (state.ui.playback.isPlaying && !state.ui.playback.isPaused) {
        if (state.ui.playback.pointer < state.ui.playback.array.length - 1) {
          const newPointer = state.ui.playback.pointer + 1;
          store.dispatch(
            actions.uiUpdatePlayback({
              pointer: newPointer
            })
          );

          state.ui.playback.timer = setTimeout(() => {
            if (typeof state.ui.playback.array[newPointer] !== 'undefined') {
              mapHelper.centerOn(
                state.ui.playback.array[newPointer].coordinate
              );
            }
          }, 1000);
        } else {
          clearTimeout(state.ui.playback.timer);
          store.dispatch(actions.uiUpdatePlayback({ isPlaying: false }));
        }
      }
    });

    store.dispatch(
      actions.refreshPoi({
        map_id: state.app.id,
        token: state.user.token
      })
    );

    if (state.app.prefs.autoBounds.value) {
      setTimeout(() => {
        mapHelper.onAutoBounds();
      }, 250);
    }
  },

  onPlay: (search) => {
    const state = store.getState();
    store.dispatch(
      actions.uiUpdatePlayback({
        isPlaying: true,
        isPaused: false,
        pointer: 0,
        array: search.results
      })
    );
    state.app.map.stop();
    mapHelper.centerOn(search.results[0].coordinate);
  },

  onStop: () => {
    const state = store.getState();
    state.app.map.stop();
    clearTimeout(state.ui.playback.timer);
    store.dispatch(
      actions.uiUpdatePlayback({
        isPlaying: false,
        isPaused: false,
        pointer: 0,
        array: []
      })
    );
  },

  onPause: () => {
    const state = store.getState();
    state.app.map.stop();
    clearTimeout(state.ui.playback.timer);
    let currentlyPaused = state.ui.playback.isPaused;
    store.dispatch(
      actions.uiUpdatePlayback({
        isPaused: !currentlyPaused
      })
    );
    if (currentlyPaused) {
      if (state.app.prefs.useFly.value) {
        state.app.map.flyTo({
          center: state.ui.playback.array[state.ui.playback.pointer].coordinate,
          zoom: 15
        });
      } else {
        state.app.map.panTo({
          center: state.ui.playback.array[state.ui.playback.pointer].coordinate,
          zoom: 15
        });
      }
    }
  },

  onAutoBounds: () => {
    const state = store.getState();
    let bounds = new mapboxgl.LngLatBounds();
    let mapPoi = state.search.options.applyToMap
      ? state.search.results
      : state.poi.filtered?.length > 0
      ? state.poi.filtered
      : state.poi.all;

    mapPoi = filterPoiByTags(
      mapPoi,
      state.app.queryParams.bounds
        ? [state.app.queryParams.bounds]
        : state.app.queryParams.tags
    );

    mapPoi.forEach((poi) => {
      if (poi.coordinate) {
        bounds.extend(
          new mapboxgl.LngLat(poi.coordinate.lng, poi.coordinate.lat)
        );
      }
    });
    if (Object.keys(bounds).length > 0)
      state.app.map.fitBounds(bounds, {
        padding: 100
      });
  },

  onSetBoundsTo: (tag) => {
    const state = store.getState();
    let bounds = new mapboxgl.LngLatBounds();
    let mapPoi = state.search.options.applyToMap
      ? state.search.results
      : state.poi.filtered?.length > 0
      ? state.poi.filtered
      : state.poi.all;

    mapPoi = filterPoiByTags(mapPoi, [`${tag.trim()}`]);

    mapPoi.forEach((poi) => {
      if (poi.coordinate) {
        bounds.extend(
          new mapboxgl.LngLat(poi.coordinate.lng, poi.coordinate.lat)
        );
      }
    });
    if (Object.keys(bounds).length > 0)
      state.app.map.fitBounds(bounds, {
        padding: 100
      });
  },

  centerOn: (point) => {
    const state = store.getState();
    const zoom = state.app.prefs.map_zoom_max.value;
    if (state.app.prefs.useFly.value) {
      state.app.map.flyTo({
        center: point,
        zoom
      });
    } else {
      state.app.map.easeTo({
        center: point,
        zoom
      });
    }
  },

  onNavigate: (id) => {
    const state = store.getState();
    let poi = state.poi.all.find((poi) => poi.id === parseInt(id, 10));
    mapHelper.centerOn(poi?.coordinate);
    console.log(`${location.protocol}//${location.host}/?poi=${id}`);
  },

  onDelete: (id) => {
    const state = store.getState();
    let poi = state.poi.all.find((poi) => poi.id === parseInt(id, 10));
    if (poi)
      if (
        window.confirm(
          `Do you really want to delete ${
            poi.name ? `"${poi.name}"` : 'this marker'
          } and all related information? This cannot be undone.`
        )
      ) {
        store.dispatch(actions.deletePoi(id));
      }
  },

  onCreate: (opt) => {
    const state = store.getState();
    let repTemplate = state.app.representation_options.find(
      (item) => item.id === opt.representation_id
    );
    let presTemplate = state.app.presentation_options.find(
      (item) => item.id === opt.presentation_id
    );
    repTemplate = repTemplate?.template.options;
    presTemplate = presTemplate?.template.options;
    let config = {};
    if (repTemplate)
      Object.keys(repTemplate).forEach(
        (key) => (config[key] = repTemplate[key].default)
      );
    if (presTemplate)
      Object.keys(presTemplate).forEach(
        (key) => (config[key] = presTemplate[key].default)
      );
    let dataTemplate = state.app.poi_fields;
    let data = [];
    if (dataTemplate)
      dataTemplate.forEach((datum) => {
        data.push({
          is_published: datum.is_published_by_default,
          dataType: datum.id,
          dataId: -1,
          field: datum.name,
          value: ''
        });
      });

    if (state.user.isAuthenticated) {
      store.dispatch(
        actions.createPoi({
          ...opt,
          data,
          name: 'New',
          isNew: true,
          id: state.poi.nextID,
          presentation_id: opt.presentation_id ? opt.presentation_id : 1,
          representation_config: config
        })
      );
    }
  },

  onPreview: () => {
    this.props.dispatch(actions.setAllVisibilityOff());
    this.props.dispatch(actions.uiShow(CONST.UI.POI_PRESENTATION));
    this.props.dispatch(actions.setInspectorPreview(true));
  },

  onLink: () => {
    let url = window.location.href;
    let base = url.split('/')[2];
    const text = `http://${base}/${this.props.reduxState.poi.current.id}`;
    navigator.clipboard.writeText(text).then(
      () => {
        window.alertBar(
          `Copied shareable link to "${this.props.reduxState.poi.current.id}" to clipboard!`
        );
      },
      (err) => {
        console.error('Could not copy text: ', err);
      }
    );
  },

  onSetDefault: () => {
    this.props.dispatch(actions.setAllVisibilityOff());
    if (
      window.confirm(
        'Do you want to set the default style for POI of this type?'
      )
    ) {
      this.props.dispatch(
        actions.setStyleAsDefault(this.props.reduxState.poi.current.id)
      );
    }
  }
};
export default mapHelper;
