import * as actions from 'state/actions';
import { select, put, all } from 'redux-saga/effects';
import * as helper from './sagaHelper.js';

export const resequenceTrackItems = function* (action) {
  yield put({
    type: actions.API_PERFORM_CRUD,
    payload: {
      method: 'POST',
      endpoint: 'rpc/resequence_track_items',
      type: action.type,
      data: {
        payload: { data: action.payload }
      }
    }
  });
};

export const updateTrackGroup = function* (action) {
  const audiotourItems = yield select(
    (reduxState) => reduxState.audioTour.items
  );
  const itemsToUpdate = audiotourItems.filter(
    (item) =>
      item.poi_id === action.payload.poi_id &&
      item.track_id === action.payload.track_id &&
      item.proximity_id === action.payload.proximity_id
  );

  yield all(
    itemsToUpdate.map((item) => {
      return put({
        type: actions.AUDIOTOUR_UPDATE_ENQUEUE,
        payload: {
          ...item,
          displayname: action.payload.displayname,
          transition_options: action.payload.transition_options,
          only_once: action.payload.only_once,
          session_skip: action.payload.session_skip,
          transition_type_id: action.payload.transition_type_id
        }
      });
    })
  );

  yield put({
    type: actions.AUDIOTOUR_RECORD_CHANGES
  });
};

export const getTourConfig = function* (action) {
  try {
    const reduxState = yield select();
    if (!reduxState.app.prefs.hasAudioTour.value) return;
    let response = yield helper.signedAPI(
      'GET',
      `audiotour_config?map_id=eq.${reduxState.app.id}`,
      reduxState.user.token
    );
    let response_json = yield response.json();
    if (response.ok)
      yield put({
        type: actions.AUDIOTOUR_GET_CONFIG_RESPONSE,
        payload: { response: response_json, request: action.payload }
      });
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.payload ? action.payload.type : ''
      }
    });
  }
};

export const getTourItems = function* (action) {
  try {
    const reduxState = yield select();
    if (!reduxState.app.prefs.hasAudioTour.value) return;
    let response = yield helper.signedAPI(
      'GET',
      `audiotour?map_id=eq.${reduxState.app.id}`,
      reduxState.user.token
    );
    let response_json = yield response.json();
    if (response.ok)
      yield put({
        type: actions.AUDIOTOUR_GET_ITEMS_RESPONSE,
        payload: { response: response_json, request: action.payload }
      });
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.payload ? action.payload.type : ''
      }
    });
  }
};

export const updateWeather = function* (action) {
  if (!action.payload.poi.coordinate) return null;

  try {
    const url = `https://api.openweathermap.org/data/2.5/onecall?lat=${action.payload.poi.coordinate.lat}&lon=${action.payload.poi.coordinate.lng}&appid=${window._env_.REACT_APP_OPENWEATHER_API_KEY}`;
    let response = yield fetch(url, { method: 'GET' });
    let response_json = yield response.json();

    if (response.ok) {
      yield put({
        type: actions.AUDIOTOUR_UPDATE_CONFIG,
        payload: {
          e: { id: 'weather', value: response_json }
        }
      });
    } else {
      yield put({
        type: actions.APP_SAGA_ERROR_NONFATAL,
        payload: {
          error: response_json,
          source: 'Update Weather'
        }
      });
    }
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: action.payload ? action.payload.type : ''
      }
    });
  }
};

export const crud_response = function* () {
  const reduxState = yield select();
  yield put({
    type: actions.AUDIOTOUR_GET_ITEMS,
    payload: reduxState.app.id
  });
};

export const recordConfigChanges = function* (action) {
  const reduxState = yield select();
  const payload = { ...reduxState.audioTour.config, map_id: reduxState.app.id };
  const isInsert = payload.needsInsert;
  delete payload.needsInsert;
  yield put({
    type: actions.API_PERFORM_CRUD,
    payload: {
      method: isInsert ? 'POST' : 'PATCH',
      endpoint: isInsert
        ? `audiotour_config`
        : `audiotour_config?map_id=eq.${payload.map_id}`,
      data: {
        ...payload
      },
      type: action.type
    }
  });
};

export const recordChanges = function* (action) {
  const reduxState = yield select();
  yield all(
    reduxState.audioTour.needsInsert.map((tourItem) => {
      return put({
        type: actions.API_PERFORM_CRUD,
        payload: {
          isInsert: true,
          placeholderId: tourItem.id,
          method: 'POST',
          endpoint: `audiotour`,
          data: {
            displayname: tourItem.displayname,
            map_id: reduxState.app.id,
            track_id: tourItem.track_id,
            transition_type_id: tourItem.transition_type_id,
            transition_options: tourItem.transition_options,
            media_id: tourItem.media_id,
            does_loop: tourItem.does_loop,
            does_duck: tourItem.does_duck,
            on_weather: tourItem.on_weather,
            on_tod: tourItem.on_tod,
            fires_on_count: tourItem.fires_on_count,
            fires_on_count_global: tourItem.fires_on_count_global,
            sortorder: tourItem.sortorder,
            trigger_type_id: tourItem.trigger_type_id,
            poi_id: tourItem.poi_id,
            only_once: tourItem.only_once,
            session_skip: tourItem.session_skip,
            never_after: tourItem.never_after,
            only_after: tourItem.only_after,
            follow_at: tourItem.follow_at,
            proximity_id: tourItem.proximity_id
          },
          type: action.type
        }
      });
    })
  );
  if (reduxState.audioTour.needsInsert.length > 0)
    yield put({ type: actions.AUDIOTOUR_CLEAR_INSERT_QUEUE });

  yield all(
    reduxState.audioTour.needsRemoval.map((tourItem) => {
      return put({
        type: actions.API_PERFORM_CRUD,
        payload: {
          method: 'DELETE',
          endpoint: `audiotour?id=eq.${tourItem.id}`,
          data: {},
          type: action.type
        }
      });
    })
  );
  if (reduxState.audioTour.needsRemoval.length > 0)
    yield put({ type: actions.AUDIOTOUR_CLEAR_REMOVE_QUEUE });

  for (let idx = 0; idx < reduxState.audioTour.needsUpdate.length; idx++) {
    const tourItem = reduxState.audioTour.needsUpdate[idx];
    yield put({
      type: actions.API_PERFORM_CRUD,
      payload: {
        method: 'POST',
        endpoint: 'rpc/update_audiotouritem',
        type: action.type,
        data: {
          payload: {
            id: tourItem.id,
            displayname: tourItem.displayname,
            map_id: reduxState.app.id,
            track_id: tourItem.track_id,
            transition_type_id: tourItem.transition_type_id,
            transition_options: tourItem.transition_options,
            media_id: tourItem.media_id,
            does_loop: tourItem.does_loop,
            does_duck: tourItem.does_duck,
            on_weather: tourItem.on_weather,
            on_tod: tourItem.on_tod,
            fires_on_count: tourItem.fires_on_count,
            fires_on_count_global: tourItem.fires_on_count_global,
            sortorder: tourItem.sortorder,
            trigger_type_id: tourItem.trigger_type_id,
            poi_id: tourItem.poi_id,
            only_once: tourItem.only_once,
            session_skip: tourItem.session_skip,
            never_after: tourItem.never_after,
            only_after: tourItem.only_after,
            follow_at: tourItem.follow_at,
            proximity_id: tourItem.proximity_id
          }
        }
      }
    });
  }
  if (reduxState.audioTour.needsUpdate.length > 0)
    yield put({ type: actions.AUDIOTOUR_CLEAR_UPDATE_QUEUE });
};
