import React from 'react';
import PropTypes from 'prop-types';
import ReactSlider from 'react-slider';
import sliderStyles from './Slider.module.css';
import defaultStyle from './defaultStyle.js';

const Slider = (props) => {
  const isDisabled = props.isDisabled || props.isLocked;
  const styles = {
    ...defaultStyle,
    input: { ...defaultStyle.input, ...props.style?.input },
    label: { ...defaultStyle.label, ...props.style?.label }
  };
  const onAfterChange = (value) => {
    props.onChange({
      id: props.id,
      value,
      data: props.options?.data
    });
  };

  let defaultValue = props.options?.default
    ? parseInt(props.options.default, 10)
    : 0;
  defaultValue = isNaN(props.value) ? 0 : parseFloat(props.value, 10);
  const min = props.options?.min ? props.options.min : 0;
  const max = props.options?.max ? props.options.max : 1;
  const step = (max - min) / 100;
  return (
    <div style={{ height: '2.7rem', opacity: isDisabled ? 0.5 : 1.0 }}>
      <div style={props.isPublished ? null : styles.private}>
        <ReactSlider
          disabled={isDisabled}
          min={min}
          max={max}
          step={step}
          onAfterChange={onAfterChange}
          defaultValue={defaultValue}
          className={sliderStyles.sliderhorizontal}
          thumbClassName={sliderStyles.sliderThumb}
          trackClassName={sliderStyles.sliderTrack}
          renderThumb={(props, state) => (
            <div {...props}>{state.valueNow.toFixed(1).toString()}</div>
          )}
        />
      </div>
    </div>
  );
};

Slider.defaultProps = {
  onChange: () => {},
  isPublished: true
};

Slider.propTypes = {
  style: PropTypes.object,
  label: PropTypes.string,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  isPublished: PropTypes.bool,
  isLocked: PropTypes.bool,
  id: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.object
};

export default Slider;
