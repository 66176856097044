import {
  put,
  select,
  call,
  take,
  actionChannel,
  delay
} from 'redux-saga/effects';
import CONST from 'constants.js';
import * as actions from 'state/actions';
import * as helper from './sagaHelper.js';
import JSZip from 'jszip';

export const refreshMapIfNeeded = function* (action) {
  const reduxState = yield select();
  let prefs = { ...reduxState.app.prefs };
  let needsMapRefresh = false;
  Object.keys(action.payload.updates).forEach((updatedPref) => {
    if (
      prefs[updatedPref].value !== action.payload.updates[updatedPref] &&
      prefs[updatedPref].requiresMapRefresh
    )
      needsMapRefresh = true;
  });
  if (needsMapRefresh) {
    yield put({ type: actions.APP_FORCE_MAP_INIT });
  }
};

export const initCrudRequestQueue = function* () {
  const requestChan = yield actionChannel(actions.API_PERFORM_CRUD);
  while (true) {
    const { payload } = yield take(requestChan);
    yield call(_performCrud, payload);
    yield delay(250);
  }
};

const _performCrud = function* (payload) {
  if (CONST.APP_MODE.DEMO === window._env_.REACT_APP_MODE) {
    yield console.log(`DEMO MODE: Changes not persistant.`);
    return;
  }
  yield put({ type: actions.APP_SYNCH_BEGIN });
  try {
    const reduxState = yield select();
    const response = yield helper.signedAPI(
      payload.method,
      payload.endpoint,
      reduxState.user.token,
      payload.data
    );
    if (!response.ok) {
      let response_json = yield response.json();
      yield put({
        type: actions.APP_SAGA_ERROR,
        payload: {
          error: { response: response_json, payload },
          source: 'api._performCrud'
        }
      });
    } else {
      yield put({ type: actions.APP_SYNCH_END });
      if (payload.isInsert) {
        let response_json = yield response.json();
        let newId = response_json[0].id;
        yield put({
          type: payload.endpoint.includes('audiotour')
            ? actions.AUDIOTOUR_UPDATE_ITEM_ID
            : payload.endpoint.includes('poi')
            ? actions.POI_UPDATE_ID
            : actions.PROXIMITY_UPDATE_ID,
          payload: { newId: newId, oldId: payload.placeholderId }
        });
      } else if (payload.successAction) {
        yield put({
          type: payload.successAction,
          payload: {
            response: response
          }
        });
      }
    }
  } catch (e) {
    yield put({
      type: actions.APP_SAGA_ERROR,
      payload: {
        error: e,
        source: payload.type
      }
    });
  }
};

export const exportStandalone = function* () {
  const reduxState = yield select();
  const filename = `${reduxState.app.prefs.title.value
    .toLowerCase()
    .split(' ')
    .join('_')}_${Date.now()}`;
  let response;

  response = yield helper.anonymousAPI('GET', `config`);
  let configJson = yield response.json();

  response = yield helper.anonymousAPI('GET', `poi_with_data`);
  let poiJson = yield response.json();

  response = yield helper.anonymousAPI('GET', `media`);
  let mediaJson = yield response.json();

  response = yield helper.anonymousAPI('GET', `mediaconfig`);
  let mediaConfigJson = yield response.json();

  var zip = new JSZip();
  zip.file('config.json', JSON.stringify(configJson));
  zip.file('poi.json', JSON.stringify(poiJson));
  zip.file('media.json', JSON.stringify(mediaJson));
  zip.file('mediaconfig.json', JSON.stringify(mediaConfigJson));
  zip.generateAsync({ type: 'blob' }).then((content) => {
    const url = window.URL.createObjectURL(new Blob([content]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${filename}.zip`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  });
};
