import React from 'react';
import PropTypes from 'prop-types';
import colors from 'colors';
import defaultStyle from './defaultStyle.js';

const CheckboxIcon = (props) => {
  const isDisabled = props.isDisabled || props.isLocked;
  let color = props.options.invert
    ? props.value
      ? props.options.showBothActive
        ? props.options.activeColor
        : colors.uiBackground
      : props.options.activeColor
    : props.value
    ? props.options.activeColor
    : props.options.showBothActive
    ? props.options.activeColor
    : colors.uiBackground;
  let opacity = props.options.invert
    ? props.value
      ? props.options.showBothActive
        ? 1.0
        : 0.5
      : 1.0
    : props.value
    ? 1.0
    : props.options.showBothActive
    ? 1.0
    : 0.5;
  const altIcon = props.options.iconAlt
    ? props.options.iconAlt
    : props.options.icon;
  const icon = props.options.invert
    ? props.value
      ? altIcon
      : props.options.icon
    : props.value
    ? props.options.icon
    : altIcon;

  if (isDisabled) {
    color = 'grey';
    opacity = 0.5;
  }

  const styles = { ...defaultStyle, ...props.style };
  return (
    <div style={{ width: '2rem' }}>
      <div style={props.isPublished ? null : styles.private}>
        <div
          style={{ fontSize: '2rem', color, opacity }}
          className={`fas fa-${icon}`}
          checked={props.value ? true : false}
          data-id={props.id}
          onClick={(e) =>
            props.onChange({
              id: e.target.dataset.id,
              value: props.value ? false : true,
              data: props.options?.data
            })
          }
        />
      </div>
    </div>
  );
};

CheckboxIcon.defaultProps = {
  options: {
    activeColor: 'rgb(194, 255, 0)',
    showBothActive: false,
    invert: false
  },
  onChange: () => {},
  isPublished: true
};

CheckboxIcon.propTypes = {
  activeColor: PropTypes.string,
  id: PropTypes.any,
  invert: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  isPublished: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.object,
  showBothActive: PropTypes.bool,
  style: PropTypes.any,
  value: PropTypes.any
};

export default CheckboxIcon;
