import React, { useState } from 'react';
import PropTypes from 'prop-types';
import colors from 'colors';
import {
  IconButton,
  Weather,
  TimeOfDay,
  Button,
  Collapsable
} from 'components';
import moment from 'moment';
import * as actions from 'state/actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import FormInput, { FIELDTYPE } from 'components/FormInput';
import sun from 'utility/sun';
import { perceivedWeather } from 'components/Weather';
import CONST from 'constants.js';

const AssignBellwether = ({ onChange, poiId }) => {
  const availablePoi = useSelector((redux) => redux.poi.all);
  const selectionOptions = [{ name: 'NONE', id: -1 }, ...availablePoi];
  return (
    <div
      style={{
        margin: '0',
        padding: '1rem',
        backgroundColor: colors.tabBackground,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <div style={{ marginLeft: '1rem' }}>Bellwether:</div>
      <div style={{ marginLeft: '1rem', flexGrow: '1' }}>
        <FormInput
          id="bellwether_poi"
          value={poiId}
          style={{ input: { width: '6rem' } }}
          type={FIELDTYPE.SELECT}
          options={{
            selectionOptions
          }}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

AssignBellwether.propTypes = {
  onChange: PropTypes.any,
  poiId: PropTypes.any
};

const TourConfig = () => {
  const PERCEIVED_WEATHER = useSelector(
    (redux) => redux.audioTour.perceivedWeather
  );
  const audioTourConfig = useSelector((redux) => redux.audioTour.config);
  const mapId = useSelector((redux) => redux.app.id);
  const dispatch = useDispatch();
  const availablePoi = useSelector((redux) => redux.poi.all);
  const [date, setDate] = useState(Date.now());
  const dateFormat = 'MM/DD/YYYY';
  const poi = availablePoi.find(
    (poi) => poi.id === audioTourConfig.bellwether_poi
  );
  const tod = sun.info({ date: Date.now(), coordinate: poi?.coordinate });
  const audioTour = useSelector((redux) => redux.audioTour);
  const hasIdealPath = audioTour.config.ideal_path?.type === 'LineString';
  const onChange = (e) => {
    console.log(e);
    dispatch(actions.updateAudiotourConfig({ mapId, e }));
  };

  const lastWeatherUpdate = audioTourConfig.weather
    ? moment(new Date(audioTourConfig.weather.current.dt * 1000))
    : null;
  let perceived = audioTourConfig.weather?.current
    ? perceivedWeather(PERCEIVED_WEATHER, audioTourConfig.weather?.current)
    : null;

  return (
    <div style={styles.container}>
      <div style={styles.section}>
        <div style={styles.header}>
          <div style={{ marginRight: '1rem' }}>
            <span className="fas fa-map" />
          </div>
          <div style={{ flexGrow: '1' }}>Ideal Path</div>
          <div
            style={{
              flexGrow: '1',
              textAlign: 'right',
              color: 'black',
              flexDirection: 'row'
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <div>
                {hasIdealPath
                  ? '✅ Ideal Path is Set!'
                  : ' ⚠️ IDEAL PATH NOT CONFIGURED! '}
              </div>
              <div style={{ marginLeft: '1rem' }}>
                <Button
                  onClick={() =>
                    dispatch(actions.uiShow(CONST.UI.TOUR_PREVIEW))
                  }
                >
                  Modify
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AssignBellwether
        onChange={onChange}
        poiId={audioTourConfig.bellwether_poi}
      />
      <div style={styles.section}>
        <div style={styles.header}>
          <div style={{ marginRight: '1rem' }}>
            <span className="fas fa-cloud" />
          </div>
          <div>Weather</div>
          <div style={{ flexGrow: '1', textAlign: 'right' }}>
            {perceived ? perceived.name : '?'}
          </div>
        </div>
        <div>
          <IconButton
            style={{ color: colors.secondary, margin: 0 }}
            isBorderless={true}
            icon={'retweet'}
            onClick={() =>
              dispatch(actions.updateWeather({ mapId: mapId, poi }))
            }
          />
          {(audioTourConfig.weather && (
            <div>
              <div>
                Last Update: {lastWeatherUpdate.fromNow()}(
                {lastWeatherUpdate.format(dateFormat)})
              </div>
              <Weather data={audioTourConfig.weather} />
            </div>
          )) || (
            <div>
              {lastWeatherUpdate && !audioTourConfig.weather && poi?.coordinate
                ? 'Weather data is invalid, please refresh'
                : 'Assign a bellwether to see the weather data for that location'}
            </div>
          )}
        </div>
      </div>
      <div style={styles.section}>
        <div style={styles.header}>
          <div style={{ marginRight: '1rem' }}>
            <span className="fas fa-clock" />{' '}
          </div>
          <div>Time of Day </div>
          <div style={{ flexGrow: '1', textAlign: 'right' }}>
            {tod?.perceivedTimeOfDay()}
          </div>
        </div>
        <div>
          {(poi?.coordinate && (
            <div>
              <FormInput
                type={FIELDTYPE.DATE}
                onChange={(e) => {
                  setDate(e.value);
                }}
                value={moment(date).format(dateFormat)}
              />
              <TimeOfDay date={date} coordinate={poi?.coordinate} />
            </div>
          )) || (
            <div>
              Assign a bellwether to see the daylight chart for that location
            </div>
          )}
        </div>
      </div>
      <Collapsable
        title={
          <div style={styles.header}>
            <div style={{ marginRight: '1rem' }}>
              <span className="fas fa-file" />{' '}
            </div>
            <div>Metadata (Valid JSON Only)</div>
          </div>
        }
      >
        <div style={styles.section}>
          <div>
            <FormInput
              id="metadata"
              value={JSON.stringify(audioTourConfig.metadata)}
              type={FIELDTYPE.TEXTAREA}
              onChange={onChange}
              options={{ validation: 'json' }}
            />
          </div>
        </div>
      </Collapsable>
    </div>
  );
};

const styles = {
  container: { padding: '0' },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: '1.2rem',
    fontWeight: '900',
    margin: '0 0 .2rem 0',
    color: colors.tabBackground
  },
  section: { margin: '2rem' }
};

export default TourConfig;

TourConfig.defaultProps = {
  scrollTop: 0,
  showArrow: true,
  resetToTop: false
};

TourConfig.propTypes = {
  items: PropTypes.array
};
