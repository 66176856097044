import React from 'react';
import PropTypes from 'prop-types';

const kelvinToFarenheit = (kelvin) => {
  return kelvin * (9 / 5) - 459.67;
};

const kelvinToCelsius = (kelvin) => {
  return kelvin - 273.15;
};

export const perceivedWeather = (possibilities, { weather, temp }) => {
  if (!weather?.length > 0) return null;
  let found;
  possibilities.forEach((possibility) => {
    let matchedAll = false;
    if (!found) {
      if (possibility.parameters.tempRange.lower !== null)
        matchedAll = temp >= possibility.parameters.tempRange.lower;

      if (possibility.parameters.tempRange.upper !== null)
        matchedAll = temp <= possibility.parameters.tempRange.upper;

      if (possibility.parameters.conditionCodes.length > 0)
        matchedAll = possibility.parameters.conditionCodes.includes(
          weather[0].id
        );
      if (matchedAll) found = possibility;
    }
  });
  return found ? found : { name: 'UNKNOWN', desc: '?' };
};

const WeatherConfig = (props) => {
  if (!props.data?.current) return <div>No data</div>;
  const currentWeather = props.data.current.weather[0];
  return (
    <div style={{ width: '25rem' }}>
      <div>
        <div style={{ fontSize: '3rem' }}>
          {kelvinToFarenheit(props.data.current.temp).toFixed(2)}°F{' '}
        </div>
        {kelvinToCelsius(props.data.current.temp).toFixed(2)}°C /
        {props.data.current.temp.toFixed(2)}°K
      </div>
      <div
        style={{
          borderRadius: '5rem',
          border: '1px solid blue'
        }}
      >
        <img
          alt={currentWeather.icon}
          src={`http://openweathermap.org/img/wn/${currentWeather.icon}@2x.png`}
        />
        {currentWeather.description}
      </div>
    </div>
  );
};

WeatherConfig.defaultProps = {
  onChange: () => {}
};

WeatherConfig.propTypes = {
  data: PropTypes.object,
  id: PropTypes.any,
  isDisabled: PropTypes.bool,
  isLocked: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.object,
  value: PropTypes.any
};

export default WeatherConfig;
