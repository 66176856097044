import initialState from './init/audioTour';
import * as actions from 'state/actions';
import common from './common.js';

export default function app(state = initialState, action) {
  switch (action.type) {
    case actions.APP_VISIBILITY_AUDIOTOUR:
      return { ...state, isVisible: action.payload };

    case actions.AUDIOTOUR_GET_CONFIG_RESPONSE:
      return {
        ...state,
        config: action.payload.response[0] ? action.payload.response[0] : {}
      };

    case actions.AUDIOTOUR_UPDATE_CONFIG_ENQUEUE:
      return {
        ...state,
        config: {
          ...state.config,
          needsInsert: Object.keys(state.config).length === 0 ? true : false,
          [action.payload.e.id]: action.payload.e.value
        }
      };

    case actions.AUDIOTOUR_GET_ITEMS_RESPONSE: {
      let nextID = 0;
      action.payload.response.forEach((item) => {
        if (item.id > nextID) nextID = item.id;
      });
      nextID = nextID + 9000;
      return { ...state, nextID, items: action.payload.response };
    }

    case actions.AUDIOTOUR_CREATE_ENQUEUE: {
      const needsInsert = [...state.needsInsert];
      let nextID = state.nextID + 1;
      const newItem = { ...action.payload, id: nextID };
      needsInsert.push(newItem);
      return {
        ...state,
        items: common.insertIntoArray(state.items, newItem),
        needsInsert,
        nextID
      };
    }

    case actions.AUDIOTOUR_UPDATE_ENQUEUE: {
      const needsUpdate = [...state.needsUpdate];
      needsUpdate.push(action.payload);
      return {
        ...state,
        items: common.updateInArrayById(state.items, action.payload),
        needsUpdate
      };
    }

    case actions.AUDIOTOUR_DELETE_ENQUEUE: {
      const payload = JSON.parse(action.payload);
      const needsRemoval = [...state.needsRemoval];
      const itemToRemove = state.items.filter(
        (item) =>
          item.poi_id === payload.poi_id &&
          item.track_id === payload.track_id &&
          item.sortorder === payload.sortorder
      )[0];
      needsRemoval.push(itemToRemove);
      return {
        ...state,
        items: common.removeFromArrayByIdByCompare(state.items, itemToRemove),
        needsRemoval
      };
    }

    case actions.AUDIOTOUR_CLEAR_INSERT_QUEUE: {
      return {
        ...state,
        needsInsert: []
      };
    }

    case actions.AUDIOTOUR_CLEAR_REMOVE_QUEUE: {
      return {
        ...state,
        needsRemoval: []
      };
    }

    case actions.AUDIOTOUR_CLEAR_UPDATE_QUEUE: {
      return {
        ...state,
        needsUpdate: []
      };
    }

    case actions.AUDIOTOUR_UPDATE_ITEM_ID: {
      return {
        ...state,
        items: common.updateIdInArray(state.items, action.payload)
      };
    }

    case actions.AUDIOTOUR_RESEQUENCE: {
      let newAll = [...state.items];
      action.payload.forEach((change) => {
        let target = newAll.find(
          (tourItem) => tourItem.id === change.audiotour_id
        );
        target.sortorder = change.sortorder;
      });
      newAll.sort((a, b) => a.sortorder - b.sortorder);
      return { ...state, items: newAll };
    }

    default:
      return state;
  }
}
