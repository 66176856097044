import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Collapsable } from 'components';
import CONST from 'constants.js';
import ConfigureTrack from './ConfigureTrack.js';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from 'state/actions';
import colors from 'colors';
import { Sortable } from 'components/DragNDrop';
import { IconButton } from 'components';
import uuidv4 from 'uuid/v4';

const TriggerConfig = ({ triggerType, onDelete }) => {
  const dragGroupId = uuidv4();
  const dispatch = useDispatch();
  const tourSettings = useSelector((redux) => redux.audioTour);
  const availableTod = useSelector(
    (redux) => redux.audioTour.perceivedTimeOfDay
  );
  const availableWeather = useSelector(
    (redux) => redux.audioTour.perceivedWeather
  );
  const availablePoi = useSelector((redux) => redux.poi.all);
  const availableProximity = useSelector((redux) => redux.proximity.all);
  const availableTriggers =
    triggerType === CONST.TRIGGER.PROXIMITY ? availableProximity : availablePoi;
  const numberOfTracks = useSelector(
    (redux) => redux.app.prefs.numberOfTracks.value
  );
  const [sortableItems, setSortableItems] = useState(availableProximity);
  useEffect(() => {
    setSortableItems(availableTriggers);
  }, [availableTriggers]);

  const onChange = (e) => {
    let payload = {
      ...e.data.trackItem,
      [e.id]: e.value
    };
    if (e.data.trackItem.id === null) {
      dispatch(actions.createAudiotourItem(payload));
    }
    dispatch(actions.updateAudiotourItem(payload));
  };

  const onChangeGroup = (e) => {
    let payload =
      e.id === 'transition_options'
        ? {
            ...e.data.trackItem,
            transition_options: {
              ...e.data.trackItem.transition_options,
              [e.data.option]: e.value
            }
          }
        : {
            ...e.data.trackItem,
            [e.id]: e.value
          };
    if (payload.id === null) {
      dispatch(actions.createAudiotourItem(payload));
    } else {
      dispatch(actions.updateAudiotourItemGroup(payload));
    }
  };

  let form = [];
  if (!sortableItems) return null;
  sortableItems.forEach((sortable, idx) => {
    const item = availableTriggers?.find((item) => item.id === sortable.id);
    if (!item) return null;
    if (isNaN(item.sortorder) || item.sortorder === null) item.sortorder = idx;

    let subForm = [];
    for (let trackId = 0; trackId < numberOfTracks; trackId++) {
      const poiId = triggerType === CONST.TRIGGER.LOCATION ? item.id : null;
      const proximityId =
        triggerType === CONST.TRIGGER.PROXIMITY ? item.id : null;
      const trackItems = Object.values(tourSettings.items)
        .filter(
          (track) =>
            track.trigger_type_id === triggerType &&
            track.track_id === trackId &&
            track.poi_id === poiId &&
            track.proximity_id === proximityId
        )
        .sort((a, b) => a.sortorder - b.sortorder);

      subForm.push(
        <ConfigureTrack
          key={`${item.id}_${trackId}`}
          poiId={poiId}
          proximityId={proximityId}
          trackId={trackId}
          trackItems={trackItems}
          triggerType={triggerType}
          availablePoi={availablePoi}
          availableTod={availableTod}
          availableWeather={availableWeather}
          onChange={onChange}
          onChangeGroup={onChangeGroup}
          onAddFollow={(e) =>
            dispatch(
              actions.createAudiotourItem(
                JSON.parse(e.currentTarget.dataset.meta)
              )
            )
          }
          onRemoveFollow={(e) =>
            dispatch(actions.deleteAudiotourItem(e.currentTarget.dataset.meta))
          }
        />
      );
    }
    form.push(
      <Sortable
        accepts={dragGroupId}
        key={item.id}
        index={idx}
        id={item.id}
        sortableItems={sortableItems}
        setSortableItems={setSortableItems}
        onChange={(arr) => {
          if (triggerType === CONST.TRIGGER.PROXIMITY) {
            dispatch(
              actions.resequenceProximity(
                arr.map((item, idx) => ({
                  proximity_id: item.id,
                  sortorder: idx
                }))
              )
            );
          } else {
            dispatch(
              actions.resequencePoi(
                arr.map((item, idx) => ({ poi_id: item.id, sortorder: idx }))
              )
            );
          }
        }}
      >
        <div>
          <Collapsable
            style={{ backgroundColor: colors.primary, border: '0px' }}
            key={`trigger_${item.id}`}
            title={
              <div style={styles.poiBar}>
                <div style={styles.poiName}>
                  [{item.sortorder + 1}] {item.name}
                </div>
                {onDelete && (
                  <div style={styles.poiIcons}>
                    <IconButton
                      isBorderless={true}
                      icon={'minus'}
                      onClick={() => onDelete(item.id)}
                    />
                  </div>
                )}
                {triggerType === CONST.TRIGGER.LOCATION && (
                  <div style={styles.poiIcons}>
                    {item.sortorder === 0 ? 'START 🏁' : ''}
                    {item.sortorder + 1 === sortableItems.length
                      ? 'END 🔚'
                      : ''}
                  </div>
                )}
              </div>
            }
            isOpen={idx === 0}
          >
            {subForm}
          </Collapsable>
        </div>
      </Sortable>
    );
  });
  return <div style={styles.container}>{form}</div>;
};

const styles = {
  container: { padding: '1rem' },
  poiBar: {
    padding: '0.5rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%'
  },
  poiName: { fontSize: '1.2rem', flexGrow: 1 },
  poiIcons: { width: '5rem', textAlign: 'right' }
};

export default TriggerConfig;

TriggerConfig.defaultProps = {
  scrollTop: 0,
  showArrow: true,
  resetToTop: false,
  onChange: () => {}
};

TriggerConfig.propTypes = {
  items: PropTypes.array,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  resetToTop: PropTypes.bool,
  scrollTop: PropTypes.number,
  showArrow: PropTypes.bool,
  triggerType: PropTypes.number
};
