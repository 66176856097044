import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Progress.module.css';

class Progress extends Component {
  render() {
    if (this.props.percentage === 0) {
      return null;
    }

    return (
      <div
        className={
          this.props.inline ? styles.container_inline : styles.container
        }
        style={{
          backgroundColor: this.props.colorBackground,
          height: this.props.height
        }}
      >
        <div
          className={styles.colorbar}
          style={{
            backgroundColor: this.props.colorProgress,
            width: `${this.props.percentage}%`
          }}
        />
      </div>
    );
  }
}
export default Progress;

Progress.defaultProps = {
  height: '.5rem',
  percentage: 0,
  colorProgress: 'red',
  colorBackground: 'grey',
  inline: false
};
Progress.propTypes = {
  percentage: PropTypes.number,
  inline: PropTypes.bool,
  colorBackground: PropTypes.string,
  height: PropTypes.string,
  colorProgress: PropTypes.string
};
