import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas';
import rootReducer from './reducers';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

const sagaMiddleware = createSagaMiddleware();
const configureStore = () => {
  let composeEnhancers = composeWithDevTools({
    stateSanitizer: (state) =>
      state.app.map
        ? { ...state, app: { ...state.app, map: 'Hidden by Sanitizer!' } }
        : state
  });
  let store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );
  sagaMiddleware.run(rootSaga);
  return store;
};
export default configureStore;
