import React from 'react';
import PropTypes from 'prop-types';
import colors from 'colors';
import { Button, ProgressBar } from 'components';
import moment from 'moment';
import * as actions from 'state/actions';
import { useDispatch } from 'react-redux';
import { readableFileSize } from 'utility';
import { useSelector } from 'react-redux';

const Publishing = () => {
  const title = useSelector((redux) => redux.app.prefs.title.value);
  const uuid = useSelector((redux) => redux.app.prefs.uuid.value);
  const packager = useSelector((redux) => redux.packager);
  const dateFormat = useSelector((redux) => redux.app.dateFormat);
  const hasUuid = uuid?.length > 0;
  const dispatch = useDispatch();
  const onPublishPackage = (e) => {
    if (
      window.confirm(
        'Do you really want to publish now? This will make this tour public, will replace any existing package and any apps using this tour will be prompted to update.'
      )
    ) {
      dispatch(actions.packageCreate(e.target.dataset.id));
    }
  };

  const onDeletePackage = (e) => {
    if (
      window.confirm(
        'Do you really want to delete this package? This will make the tour unavailable for future downloads. Any existing apps with this tour installed will still be able to use it.'
      )
    ) {
      dispatch(actions.packageDelete(e.target.dataset.id));
    }
  };

  const thisStats = packager.manifest.filter((entry) => entry.uuid === uuid)[0];

  const packageDate = thisStats ? (
    <div>
      Published {moment(thisStats.package_date).fromNow()}{' '}
      <span style={styles.highlight}>
        ({moment(thisStats.package_date).format(dateFormat.longtime)})
      </span>
    </div>
  ) : (
    'Unpublished'
  );
  return (
    <div style={{ padding: '0 1rem 1rem 1rem' }}>
      <div style={styles.title}>{title}</div>
      <div style={styles.flexRow}>
        <div>
          <div style={{ textAlign: 'left' }}>
            {(packager.status.isInProgress && (
              <div className="rc-progress">
                <ProgressBar
                  progress={parseInt(packager.status.progressPercent, 10)}
                />
              </div>
            )) || <div>{packageDate}</div>}
          </div>
          <div>
            {(!hasUuid && (
              <div>
                <span
                  style={{ marginRight: '.3rem', color: 'orange' }}
                  title="Not Validated"
                  className="fas fa-exclamation-circle"
                />
                Assign a unique Package ID in preferences
              </div>
            )) || <div style={styles.highlight}>{uuid}</div>}
          </div>
        </div>
        <div
          style={{ flexGrow: '1', textAlign: 'right', margin: '0 0 0 1rem' }}
        >
          <Button
            isDisabled={packager.status.isInProgress || !hasUuid}
            id={uuid}
            onClick={onPublishPackage}
          >
            Publish
          </Button>
        </div>
      </div>
      <div style={{ marginBottom: '1rem', fontSize: '1.5rem' }}>
        <hr />({packager.manifest.length}) Published Packages
      </div>
      <div style={styles.manifestList}>
        {packager.manifest.map((item) => (
          <div style={styles.manifestItem} key={item.uuid}>
            <div style={{ width: '20rem' }}>
              <div>{item.title}</div>
              <div>{moment(item.package_date).fromNow()}</div>
              <div style={styles.highlight}>{item.uuid}</div>
              <div style={styles.highlight}>
                {moment(item.package_date).format(dateFormat.longtime)}
              </div>
            </div>
            <div style={{ flexGrow: '1', textAlign: 'center' }}>
              {item.count} files | {readableFileSize(item.size)} |{' '}
              {readableFileSize(item.archiveSize)} compressed
            </div>
            <div style={{ flexGrow: '1', textAlign: 'right' }}>
              <Button id={item.uuid} onClick={onDeletePackage}>
                Unpublish
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  highlight: {
    color: colors.primary
  },
  flexRow: {
    flexGrow: 1,
    marginLeft: '1rem',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  title: { fontSize: '1.5rem' },
  container: {
    fontFamily: colors.fontFamily,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left'
  },
  manifestList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  manifestItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '1rem',
    width: '100%'
  }
};

export default Publishing;

Publishing.defaultProps = {
  scrollTop: 0,
  showArrow: true,
  resetToTop: false
};

Publishing.propTypes = {
  items: PropTypes.array,
  resetToTop: PropTypes.bool,
  scrollTop: PropTypes.number,
  showArrow: PropTypes.bool
};
